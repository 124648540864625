import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import styles from './styles.scss';

const Body = inject()(
  observer(({ productMap, optionMap, ingredientMap }) => {
    const renderItem = data => {
      return (
        <ol className={styles.liWrapper}>
          {data.map(item => {
            return (
              <li key={item.length} className={styles.lili}>
                <span>{item}</span>
              </li>
            );
          })}
        </ol>
      );
    };
    return (
      <div className={styles.popupWrapper}>
        <div className={styles.swipeContent}>
          <div className={styles.content}>
            {productMap.length > 0 && (
              <div>
                <div className={styles.headerWrapper}>
                  <FormattedMessage
                    id="storage.table.popup.products.title"
                    defaultMessage="Ingredient in products:"
                  />
                </div>
                {renderItem(productMap)}
              </div>
            )}

            {optionMap.length > 0 && (
              <div>
                <div className={styles.headerWrapper}>
                  <FormattedMessage
                    id="storage.table.popup.options.title"
                    defaultMessage="Ingredient in options:"
                  />
                </div>
                {renderItem(optionMap)}
              </div>
            )}
            {ingredientMap.length > 0 && (
              <div>
                <div className={styles.headerWrapper}>
                  <FormattedMessage
                    id="storage.table.popup.cast.title"
                    defaultMessage="Ingredient in pre-cook:"
                  />
                </div>
                {renderItem(ingredientMap)}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }),
);

export default withRouter(Body);
