import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/storage';

class StorageApi {
  static list = (page = 0, size = 20) => Request.GET(`${ENDPOINT}?page=${page}&size=${size}`);

  static allList = () => Request.GET(`${ENDPOINT}`);

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static update = (id, storageData) => Request.PUT(`${ENDPOINT}`, storageData);

  static create = storageData => Request.POST(ENDPOINT, storageData);

  static fetchAllIngredients = filters => {
    return Request.POST(`/supply/ingredient-stock`, filters);
  };

  static fetchIngredients = (id, page = 0, size = 20, name = '') => {
    const queryFilters = extractFiltersQuery({ name });
    return Request.GET(`${ENDPOINT}/${id}/ingredient?page=${page}&size=${size}${queryFilters}`);
  };

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default StorageApi;
