import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  margin-bottom: 20px;
`;

export const Mark = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #db2828;
  margin: 0 4px;
`;

export const TagsList = styled.div`
  margin-top: 14px;
`;

export const Carret = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${props => props.color};

  transform: rotate(${props => (props.opened ? 0 : 0.5)}turn);
  transition: transform 300ms;
`;
