export const isKybio = true;

export const brandName = 'KYBIO';
export const hostname = true;

export const domainName = `${window.DOMAIN}`;

export const appName = 'me.kybio';

export const logoLong = isKybio
  ? 'https://orty-public.sgp1.digitaloceanspaces.com/kybio/HORIZONTALKYBIODOSBLACK.svg'
  : require('images/logo-long.svg');

export const logoLongWhite = isKybio
  ? 'https://orty-public.sgp1.digitaloceanspaces.com/kybio/logo-long-white.svg'
  : require('images/logo-long-white.svg');
