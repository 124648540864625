import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import styles from './styles.scss';

const Toggle = ({ title, onChange, checked, description }) => {
  return (
    <div className={styles.checkboxWrapper}>
      <Checkbox toggle label="" name="lpPaymentsEnabled" checked={checked} onChange={onChange} />
      <p className={styles.text}>
        {title && <p className={styles.notificationTitle}>{title}</p>}
        {description && description}
      </p>
    </div>
  );
};

Toggle.propTypes = {
  title: PropTypes.element,
  description: PropTypes.element,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Toggle;
