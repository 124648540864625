import styled from 'styled-components';

export const Option = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #525252;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${props => (props.selected ? '#fff' : 'transparent')};
  @media screen and (max-width: 520px) {
    font-size: 12px;
  }
`;

export const General = styled.div`
  height: 38px;
  border-radius: 5px;
  background-color: #e3e3e3;
  padding: 4px;
  width: 100%;
  display: flex;

  ${props =>
    props.disabled
      ? `
    pointer-events: none;
    opacity: 0.8;
  `
      : ''}
`;
