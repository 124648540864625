import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { logoLongWhite } from 'utils/env';
import MenuButton from './MenuButton';
import { Text, Container, Logo, Box, LogoutButton, Divider } from './style';

const Header = inject('User')(
  observer(({ User }) => {
    return (
      <Container>
        <Box>
          <MenuButton />
          <Box>
            <Link to="/" className="logo-link">
              <Logo src={logoLongWhite} alt="" />
            </Link>
          </Box>
        </Box>
        <Box>
          <Text>
            {User.info.firstName} {User.info.lastName}
          </Text>
          <Divider />
          <LogoutButton
            onClick={User.logout}
            title={<FormattedMessage id="distributor.logout" defaultMessag="Log Out" />}
          />
        </Box>
      </Container>
    );
  }),
);

export default Header;
