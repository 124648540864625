import React from 'react';
import { inject, observer } from 'mobx-react';
import DownloadCSVButton from 'components/common/dowload-csv-button';
import styles from './styles.scss';

const DownloadSVG = inject('InventoryEditor')(
  observer(({ InventoryEditor, data }) => {
    const { headerForCSV, inventory } = InventoryEditor;

    return (
      <div className={styles.wrapper}>
        {inventory.stockTakeItems && inventory.stockTakeItems.length > 0 && (
          <DownloadCSVButton
            data={data}
            headers={headerForCSV}
            style={{ display: 'flex', alignItems: 'center', paddingTop: 8 }}
          />
        )}
      </div>
    );
  }),
);

export default DownloadSVG;
