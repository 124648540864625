import { action, observable, runInAction } from 'mobx';
import IngredientAPI from 'api/ingredient';
import history from 'routes';
import { INGREDIENT, HALF_STUFF } from 'utils/constants';
import { getLocalizedString } from 'i18n/utils';
import { toFixedTwo } from 'utils/numbers';
import validate from 'validate.js';
import get from 'lodash/get';
import { getFullCookingPercent } from 'utils/get-full-cooking-persent';
import { getStructuredIngredientOptions } from 'utils/get-structured-ingredient-options';
import { PRODUCT_TYPES } from './constants';

const initIngredientRecipeDto = {
  id: 0,
  name: '',
  ingredientId: '',
  cookingTimeMs: 0,
  createdTimestamp: 0,
  merchantGroupId: 0,
  updatedTimestamp: 0,
  ingredientRecipeItems: [],
};

const initIngredient = {
  id: 0,
  stock: 0,
  name: '',
  unit: '',
  ingredientCategoryId: '',
  ingredientCategoryName: '',
  ingredientRecipeDto: initIngredientRecipeDto,
  stockThreshold: 0,
  unitWeight: null,
  approxPrice: null,
  wasteRatio: '',
  cookingRatio: '',
  fryingRatio: '',
  stewingRatio: '',
  roastingRatio: '',
  prepack: true,
};

class Editor {
  constructor(ingredientStore) {
    this.ingredientStore = ingredientStore;
  }
  @observable ingredient = initIngredient;
  @observable productType = INGREDIENT;
  @observable productTypes = PRODUCT_TYPES;
  @observable ingredientsData = [];
  @observable searchIngredientsData = [];
  @observable ingredientsUnitsOptions = [];
  @observable validationErrors = {};
  @observable ingredientRecipeAudit = [];
  @observable totalPrice = 0;

  @action
  changeIngredientRecipe = value => {
    this.clearValidationErrors();
    runInAction(() => {
      this.ingredient.ingredientRecipeDto.ingredientRecipeItems = value;
    });
  };

  @action
  changeTotalPrice = value => {
    runInAction(() => {
      this.totalPrice = value;
    });
  };

  @action
  clearValidationErrors = () => {
    if (Object.keys(this.validationErrors).length !== 0) {
      runInAction(() => {
        this.validationErrors = {};
      });
    }
  };

  @action
  getValid = () => {
    let validationErrors = {};

    const { name, unit } = this.ingredient;

    if (name === '') {
      validationErrors = { ...validationErrors, [`name`]: true };
    }
    if (unit === '') {
      validationErrors = { ...validationErrors, [`unit`]: true };
    }

    if (this.productType === HALF_STUFF) {
      if (this.ingredient.ingredientRecipeDto.ingredientRecipeItems === 0) {
        throw new Error(getLocalizedString('products.editor.stock.add-field.error'));
      }

      const valueIsNaN = v => v !== v;
      this.ingredient.ingredientRecipeDto.ingredientRecipeItems.map((item, index) => {
        if (validate(item, { ingredientId: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientId.${index}`]: true };
        }
        if (validate(item, { ingredientUnit: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientUnit.${index}`]: true };
        }
        if (validate(item, { ingredientAmount: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        } else if (valueIsNaN(item.ingredientAmount)) {
          validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
        }
      });
    }
    runInAction(() => {
      this.validationErrors = validationErrors;
    });
    const isValid = Object.keys(validationErrors).length === 0;

    return { isValid, validationErrors };
  };

  @action
  loadIngredientOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await IngredientAPI.list({ page, size: 20 });
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list({
        page: 0,
        size: 20,
        name: search,
      });
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.ingredientsData = page === 0 ? [...newItems] : [...this.ingredientsData, ...newItems];
      } else {
        this.searchIngredientsData = filteredOptions;
      }
    });

    const slicedOptions = getStructuredIngredientOptions(filteredOptions);
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  create = path => {
    history.push(path);
  };

  @action
  changeProductType = value => {
    this.clearValidationErrors();
    runInAction(() => {
      this.productType = value;
    });
  };

  @action
  changeIngredient = (key, value) => {
    this.clearValidationErrors();
    runInAction(() => {
      this.ingredient[key] = value;
    });
  };

  @action
  structuredIngredientRecipeItems = ingredientRecipeItems => {
    return ingredientRecipeItems.map(item => {
      const unitWeight =
        item.ingredientUnit === 'KILOGRAM'
          ? 1000
          : (get(item, 'ingredientDto.unitWeight') || get(item, 'ingredient.unitWeight') || 0) *
            1000;
      const ingredientAmount =
        item.ingredientUnit !== 'PCS'
          ? toFixedTwo(item.ingredientAmount * 1000)
          : toFixedTwo(item.ingredientAmount);
      const approxPrice =
        get(item, 'ingredientDto.approxPrice') || get(item, 'ingredient.approxPrice') || 0;
      const oneUnitWeight = unitWeight / 1000;
      const totalUnitWeight = ingredientAmount * oneUnitWeight;
      const ingredientBrutto =
        item.ingredientUnit !== 'PCS' ? totalUnitWeight : unitWeight * ingredientAmount;
      const allRatio = {
        wasteRatio: item.wasteRatio !== 0 ? true : false,
        cookingRatio: item.cookingRatio !== 0 ? true : false,
        fryingRatio: item.fryingRatio !== 0 ? true : false,
        stewingRatio: item.stewingRatio !== 0 ? true : false,
        roastingRatio: item.roastingRatio !== 0 ? true : false,
        wasteRatioPersent:
          get(item, 'ingredientDto.wasteRatio') || get(item, 'ingredient.wasteRatio') || 0,
        cookingRatioPersent:
          get(item, 'ingredientDto.cookingRatio') || get(item, 'ingredient.cookingRatio') || 0,
        fryingRatioPersent:
          get(item, 'ingredientDto.fryingRatio') || get(item, 'ingredient.fryingRatio') || 0,
        stewingRatioPersent:
          get(item, 'ingredientDto.stewingRatio') || get(item, 'ingredient.stewingRatio') || 0,
        roastingRatioPersent:
          get(item, 'ingredientDto.roastingRatio') || get(item, 'ingredient.roastingRatio') || 0,
      };
      const fullCookingPersent = getFullCookingPercent(allRatio);
      const totalApproxPrice =
        item.ingredientUnit !== 'PCS'
          ? (ingredientAmount / 1000) * approxPrice
          : ingredientAmount * approxPrice;
      return {
        ...item,
        ingredientAmount,
        approxPrice: approxPrice,
        ingredientBrutto: ingredientBrutto.toFixed(2),
        ingredientNetto: (
          ingredientBrutto - (ingredientBrutto / 100) * fullCookingPersent || 0
        ).toFixed(2),
        totalApproxPrice: (totalApproxPrice || 0).toFixed(2),
        unitWeight,
        ...allRatio,
      };
    });
  };

  @action
  getIngredientRecipeAudit = async recipeId => {
    try {
      const data = await IngredientAPI.getIngredientRecipeAudit(recipeId);
      runInAction(() => {
        this.ingredientRecipeAudit = data;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  edit = async id => {
    const item = this.ingredientStore.data.find(item => item.id == id);
    runInAction(() => {
      if (item) {
        this.ingredient = {
          ...item,
          wasteRatio: item.wasteRatio * 100,
          cookingRatio: item.cookingRatio * 100,
          fryingRatio: item.fryingRatio * 100,
          stewingRatio: item.stewingRatio * 100,
          roastingRatio: item.roastingRatio * 100,
          ingredientRecipeDto:
            item.ingredientRecipeDto === null
              ? initIngredientRecipeDto
              : {
                  ...item.ingredientRecipeDto,
                  ingredientRecipeItems: this.structuredIngredientRecipeItems(
                    item.ingredientRecipeDto.ingredientRecipeItems,
                  ),
                },
        };
        this.productType = item.ingredientRecipeDto === null ? INGREDIENT : HALF_STUFF;
      }
    });

    const data = await IngredientAPI.get(id);
    const ingredientRecipeId = item.ingredientRecipeDto.id || data.ingredientRecipeDto.id;

    if (ingredientRecipeId) {
      await this.getIngredientRecipeAudit(ingredientRecipeId);
    }

    runInAction(() => {
      this.ingredient = {
        ...data,
        wasteRatio: data.wasteRatio * 100,
        cookingRatio: data.cookingRatio * 100,
        fryingRatio: data.fryingRatio * 100,
        stewingRatio: data.stewingRatio * 100,
        roastingRatio: data.roastingRatio * 100,
        ingredientRecipeDto:
          data.ingredientRecipeDto === null
            ? initIngredientRecipeDto
            : {
                ...data.ingredientRecipeDto,
                ingredientRecipeItems: this.structuredIngredientRecipeItems(
                  data.ingredientRecipeDto.ingredientRecipeItems,
                ),
              },
      };
      this.productType = data.ingredientRecipeDto === null ? INGREDIENT : HALF_STUFF;
    });
  };

  @action
  onSubmit = async (ingredient, { isEdit }) => {
    if (isEdit) {
      await IngredientAPI.update(ingredient.id, ingredient);
    } else {
      await IngredientAPI.create(ingredient);
    }
  };

  @action
  save = async ({ isEdit }) => {
    const newIngredient = {
      ...this.ingredient,
      stockThreshold: this.ingredient.stockThreshold === '' ? '0' : this.ingredient.stockThreshold,
      wasteRatio: !this.ingredient.wasteRatio ? 0 : this.ingredient.wasteRatio / 100,
      cookingRatio: !this.ingredient.cookingRatio ? 0 : this.ingredient.cookingRatio / 100,
      fryingRatio: !this.ingredient.fryingRatio ? 0 : this.ingredient.fryingRatio / 100,
      stewingRatio: !this.ingredient.stewingRatio ? 0 : this.ingredient.stewingRatio / 100,
      roastingRatio: !this.ingredient.roastingRatio ? 0 : this.ingredient.roastingRatio / 100,
      approxPrice: !this.ingredient.approxPrice ? this.totalPrice : this.ingredient.approxPrice,
      unitWeight: !this.ingredient.unitWeight ? 0 : this.ingredient.unitWeight,
      ingredientRecipeDto:
        this.productType === INGREDIENT
          ? null
          : {
              ...this.ingredient.ingredientRecipeDto,
              name: this.ingredient.name,
              ingredientId: this.ingredient.id,
              ingredientRecipeItems: [
                ...this.ingredient.ingredientRecipeDto.ingredientRecipeItems.map(item => {
                  return {
                    ...item,
                    createdTimestamp:
                      item.createdTimestamp > 0 ? item.createdTimestamp : Date.parse(new Date()),
                    ingredientAmount:
                      item.ingredientUnit !== 'PCS'
                        ? item.ingredientAmount / 1000
                        : item.ingredientAmount,
                    ingredientUnit: item.ingredientUnit,
                    wasteRatio: item.wasteRatio ? item.wasteRatioPersent : 0,
                    cookingRatio: item.cookingRatio ? item.cookingRatioPersent : 0,
                    fryingRatio: item.fryingRatio ? item.fryingRatioPersent : 0,
                    stewingRatio: item.stewingRatio ? item.stewingRatioPersent : 0,
                    roastingRatio: item.roastingRatio ? item.roastingRatioPersent : 0,
                  };
                }),
              ],
            },
    };
    const ingredient = await this.onSubmit(newIngredient, { isEdit });
    if (ingredient && ingredient.id > 0) {
      runInAction(() => {
        this.ingredient = ingredient;
      });
    }
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.ingredient = initIngredient;
    this.conditionSelections = [];
    this.productType = INGREDIENT;
  };
}

export default Editor;
