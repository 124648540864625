import React from 'react';
import PropTypes from 'prop-types';
import { Table, Popup, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { PopupWrapper } from '../style';

const HeadingPopup = ({ id, defaultMessage, tooltipMessage }) => {
  return (
    <Table.HeaderCell key={id}>
      <PopupWrapper>
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
        <Popup
          position="bottom right"
          content={
            <FormattedMessage
              id={tooltipMessage.id}
              defaultMessage={tooltipMessage.defaultMessage}
            />
          }
          trigger={<Icon name="info circle" />}
        />
      </PopupWrapper>
    </Table.HeaderCell>
  );
};

HeadingPopup.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.object,
};

export default HeadingPopup;
