import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;

class DictionaryAPI {
  static listUnits = () => Request.GET(`/supply/unit`);
  static listCurrency = () => Request.GET(`/merchant/currency`);
  static listAccountTypes = () => Request.GET(`/supply/account-type`);
  static listSupplyRecordTypes = () => Request.GET(`/supply/supply-record-type`);
}

export default DictionaryAPI;
