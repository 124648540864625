export const toFixedTwo = value => Number(parseFloat(value).toFixed(2));
export const toFixedFive = value => Number(parseFloat(value).toFixed(5));

export const toFixedFloat = (value, numberOfDigits) => parseFloat(value).toFixed(numberOfDigits);

export const deductTax = (value, tax) => {
  return Number(((parseFloat(value) * 1000) / (parseFloat(tax) + 1) / 1000).toFixed(14));
};

export const convertTaxToPrice = (value, tax) => {
  return Number(((parseFloat(value) * 1000 * (parseFloat(tax) + 1)) / 1000).toFixed(14));
};

export const toFixedTwoPercents = value => {
  return toFixedTwo(parseFloat((parseFloat(value) * 100).toPrecision(4)));
};
