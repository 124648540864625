import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { routes } from 'routes';
import TableWrapper from 'components/common/TableWrapper';
import { getLocalizedString } from 'i18n/utils';
import DownloadCsvBySales from '../download-csv-by-sales';

const AnalyticsTable = inject('Analytics')(
  observer(({ Analytics, tableFormat, history }) => {
    const { list, loading, paginateData, allFilters, paginateOptions } = Analytics;
    const { startTimeUtc, endTimeUtc, storageFilters } = allFilters;

    const castedAmountNull =
      list.reduce((acc, item) => (acc += Number(item.castedAmount) || 0), 0) === 0;
    let newTableFormat = [...tableFormat];
    if (storageFilters === 'all' || storageFilters === '') {
      newTableFormat = newTableFormat.filter(i => i.property !== 'moveAmount');
    }
    if (castedAmountNull) {
      newTableFormat = newTableFormat.filter(i => i.property !== 'castedAmount');
    }
    const processAmount = (amount, ingredientId, type) => {
      const newAmount = Number(amount).toFixed(ingredientId === 0 ? 2 : 4);
      let onClick = () => {};
      if (type === 'DISPOSAL' || type === 'SUPPLY' || type === 'MOVE') {
        const allQueryFilters = JSON.stringify({
          start: startTimeUtc,
          end: endTimeUtc,
          ingredientId,
          types: type === 'MOVE' ? [type, 'CAST'] : [type],
          storageId: storageFilters,
        });
        onClick = () => {
          history.push(`/?allQueryFilters=${allQueryFilters}`);
        };
      }

      if (type === 'INVENTORY' && Number(amount) > 0) {
        const allQueryFilters = JSON.stringify({
          start: startTimeUtc,
          end: endTimeUtc,
          ingredientId,
          storageId: storageFilters,
        });
        onClick = () => {
          history.push(`${routes.inventory}?allQueryFilters=${allQueryFilters}`);
        };
      }
      if (
        (type === 'INVENTORY' || type === 'DISPOSAL' || type === 'SUPPLY' || type === 'MOVE') &&
        Number(amount) > 0
      ) {
        return (
          <div
            style={{ color: '#8f8f8f', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={onClick}
          >
            {newAmount}
          </div>
        );
      } else {
        return <div style={{ color: '#8f8f8f' }}>{newAmount}</div>;
      }
    };

    const drawMinusTotalAmount = (totalAmount, unit) => {
      const newTotalAmount = totalAmount.replace('-', '');
      return <span style={{ color: 'red' }}>{`-${newTotalAmount} ${unit}`}</span>;
    };

    const processTableData = data => {
      return data.map(item => {
        const totalAmount = Number(item.totalAmount).toFixed(3);
        const unit =
          getLocalizedString(`ingredient.unit.${(item.unit || '').toLowerCase()}`) || item.unit;
        const newTotalAmount =
          totalAmount.indexOf('-') !== -1
            ? drawMinusTotalAmount(totalAmount, unit)
            : totalAmount === '0.00'
            ? `${totalAmount} ${unit}`
            : `+${totalAmount} ${unit}`;

        const ingredientName = (
          <div>
            <div>{item.ingredientName}</div>
            <div style={{ fontSize: 12 }}>{item.categoryName}</div>
          </div>
        );
        return {
          ...item,
          onRowClick: () => {},
          ingredientName: ingredientName,
          totalAmount: newTotalAmount,
          takeAmount: processAmount(
            item.takeAmount,
            { value: item.ingredientId, label: item.ingredientName },
            'INVENTORY',
          ),
          disposeAmount: processAmount(
            item.disposeAmount,
            { value: item.ingredientId, label: item.ingredientName },
            'DISPOSAL',
          ),
          moveAmount: processAmount(
            item.moveAmount,
            { value: item.ingredientId, label: item.ingredientName },
            'MOVE',
          ),
          sellAmount: <DownloadCsvBySales item={item} />,
          castedAmount: processAmount(item.castedAmount),
          startAmount: processAmount(item.startAmount),
          startAvgPrice: processAmount(item.startAvgPrice, 0),
          endAvgPrice: processAmount(item.endAvgPrice, 0),
          endAmount: processAmount(item.endAmount),
          supplyAmount: processAmount(
            item.supplyAmount,
            { value: item.ingredientId, label: item.ingredientName },
            'SUPPLY',
          ),
        };
      });
    };

    return (
      <div>
        <TableWrapper
          key={list.length}
          data={processTableData(list)}
          headers={newTableFormat}
          sizeable
          loading={loading}
          showPagination
          paginateOptions={paginateOptions}
          paginateData={paginateData}
        />
      </div>
    );
  }),
);

AnalyticsTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  deleteModifier: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  admin: PropTypes.bool,
  merchatSettings: PropTypes.object,
  groupSettings: PropTypes.object,
};

export default withRouter(AnalyticsTable);
