import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FormSection from 'components/common/form-section';

const General = inject('SupplierEditor')(
  observer(({ SupplierEditor, isEdit }) => {
    const { supplier, changeSupplier } = SupplierEditor;

    const { name, taxId, taxPct, mobile, email, contactPerson } = supplier;

    const change = (_event, { name, value }) => {
      if (name === 'taxPct') {
        changeSupplier(name, Number(value));
      } else {
        changeSupplier(name, value);
      }
    };

    return (
      <FormSection>
        <Form.Group widths="2">
          <Form.Field required>
            <label>
              <FormattedMessage id="supplier.data.name" defaultMessage="Name" />
            </label>
            <FormattedMessage id="supplier.data.name.placeholder" defaultMessage="Enter the name">
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="name"
                  placeholder={placeholder}
                  maxLength={35}
                  value={name}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field required>
            <label>
              <FormattedMessage id="supplier.data.mobile" defaultMessage="Mobile" />
            </label>
            <FormattedMessage
              id="supplier.data.mobile.placeholder"
              defaultMessage="Enter the mobile"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="mobile"
                  placeholder={placeholder}
                  maxLength={35}
                  value={mobile}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field>
            <label>
              <FormattedMessage id="supplier.data.contact-person" defaultMessage="Contact person" />
            </label>
            <FormattedMessage
              id="supplier.data.contact-person.placeholder"
              defaultMessage="Enter the contact person"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="contactPerson"
                  placeholder={placeholder}
                  maxLength={35}
                  value={contactPerson}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="supplier.data.email" defaultMessage="Email" />
            </label>
            <FormattedMessage id="supplier.data.email.placeholder" defaultMessage="Enter the email">
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="email"
                  placeholder={placeholder}
                  maxLength={35}
                  value={email}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field>
            <label>
              <FormattedMessage id="supplier.data.tax-id" defaultMessage="Tax id" />
            </label>
            <FormattedMessage
              id="supplier.data.tax-id.placeholder"
              defaultMessage="Enter the tax id"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="taxId"
                  placeholder={placeholder}
                  maxLength={35}
                  value={taxId}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="supplier.data.tax-rate" defaultMessage="Tax rate" />
            </label>
            <FormattedMessage
              id="supplier.data.tax-rate.placeholder"
              defaultMessage="Enter tax rate, %"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="taxPct"
                  placeholder={placeholder}
                  maxLength={35}
                  value={taxPct}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default General;
