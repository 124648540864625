import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import Spinner from 'components/common/Spinner';

import { ShadowBox, ButtonWrapper } from './style';

const SaveButton = ({ onClick, disabled, isLoading }) => (
  <ButtonWrapper>
    <ShadowBox>
      <Button
        type="button"
        icon
        labelPosition="right"
        primary
        disabled={disabled}
        onClick={onClick}
      >
        <Icon name="save" />
        <FormattedMessage id="buttons.save" defaultMessage="Save" />
        <Spinner active={isLoading} />
      </Button>
    </ShadowBox>
  </ButtonWrapper>
);

SaveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SaveButton.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default SaveButton;
