import React, { useState } from 'react';
import { Tab, Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { routes } from 'routes';
import Ingredient from 'components/ingredient';
import IngredientCategory from 'components/ingredient-category';
import StorageDictionary from 'components/storage-dictionary';
import Account from 'components/account';
import Supplier from 'components/supplier';
import Packing from 'components/packing';
import { BottomWrapper, DashboardWrapper } from '../style';

const tabRoutes = {
  '/ingredient': 0,
  '/ingredient-category': 1,
  '/storage-dictionary': 2,
  '/account': 3,
  '/supplier': 4,
  '/packing': 5,
};

const IngredientsPage = ({ history }) => {
  const [activeIndex, setActiveIndex] = useState(tabRoutes[history.location.pathname]);

  const panes = [
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.ingredient)} key="ingredient">
          <FormattedMessage id="ingredient.name" defaultMessage="Ingredient" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Ingredient />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item
          onClick={() => history.push(routes.ingredientCategory)}
          key="ingredient-categories"
        >
          <FormattedMessage id="ingredient-category.name" defaultMessage="Ingredient categories" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <IngredientCategory />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.storageDictionary)} key="storage">
          <FormattedMessage id="storage.name" defaultMessage="Storage" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <StorageDictionary />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.account)} key="account">
          <FormattedMessage id="account.name" defaultMessage="Account" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Account />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.supplier)} key="supplier">
          <FormattedMessage id="supplier.name" defaultMessage="Supplier" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Supplier />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.packing)} key="packing">
          <FormattedMessage id="packing.name" defaultMessage="Packing" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Packing />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <DashboardWrapper>
      <BottomWrapper />
      <Tab
        panes={panes}
        menu={{ secondary: true, pointing: true }}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
      />
    </DashboardWrapper>
  );
};

export default IngredientsPage;
