import IngredientAPI from 'api/ingredient';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import StorageAPI from 'api/storage';
import { getLocalizedString } from 'i18n/utils';
import IngredientCategoryAPI from '../../api/ingredient-category';

const NAME = 'name';
const UNIT = 'unit';
const INGREDIENT_CATEGORY = 'ingredientCategoryName';

export const TABLE_FORMAT = [
  {
    name: getLocalizedString('ingredient.data.name'),
    property: NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('ingredient.data.unit'),
    property: UNIT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('ingredient.data.ingredient-category'),
    property: INGREDIENT_CATEGORY,
    variant: ['left'],
  },
];

class IngredientStore {
  @observable newTableFormat = TABLE_FORMAT;
  @observable data = [];
  @observable paginateOptions = {};
  @observable stockInfo = [];
  @observable storageList = [];
  @observable allIngredientsOptions = [];
  @observable loading = true;
  @observable allIngredientsData = [];
  @observable periodStartUtc = 0;
  @observable ingredientsAudit = {};
  @observable productsList = [];
  @observable paginateData = { page: 0, size: 20 };
  @observable fileLoading = false;
  @observable allFilters = {
    name: '',
    categoryFilters: [],
  };

  @action
  changeAllFilters = (key, value) => {
    const allFilters = { ...this.allFilters };
    if (key === 'categoryFilters') {
      if (value[0] && value[0].value === 'all') {
        allFilters[key] = value.filter(i => i.value !== 'all');
        runInAction(() => {
          this.allFilters = allFilters;
        });
      } else if (value[0] && value.some(i => i.value === 'all')) {
        allFilters[key] = [];
        runInAction(() => {
          this.allFilters = allFilters;
        });
      } else {
        allFilters[key] = value;
        runInAction(() => {
          this.allFilters = allFilters;
        });
      }
    } else {
      runInAction(() => {
        allFilters[key] = value;
        this.allFilters = allFilters;
      });
    }
  };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  getAudit = async ingredientId => {
    const data = await IngredientAPI.getAudit(ingredientId);
    runInAction(() => {
      this.ingredientsAudit = { ...this.ingredientsAudit, [ingredientId]: [...data.data] };
      this.periodStartUtc = data.periodStartUtc;
    });
    return data;
  };

  @action
  fetchData = async () => {
    const { page, size } = this.paginateData;
    const { name, categoryFilters } = this.allFilters;
    const newCategoryFilters = categoryFilters.map(i => i.value).join(', ');

    const { items, last, totalPages, totalElements } = await IngredientAPI.list({
      page,
      size,
      name,
      type: '',
      catIds: newCategoryFilters,
    });
    runInAction(() => {
      this.data = items;
      this.loading = false;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };
      this.fetchStockInfo();
      this.getIngredientsInProducts(items.map(i => i.id));
    });
  };

  @action
  loadStorages = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storageList = items;
    });
  };

  @action
  loadCategoryOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientCategoryAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientCategoryAPI.list(0, 20, search);
      filteredOptions = [...items];
    }

    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  getIngredientsInProducts = async ingredientIds => {
    const { optionMap, productMap, ingredientMap } = await IngredientAPI.getIngredientsInProducts({
      ids: ingredientIds,
    });
    runInAction(() => {
      this.productsList = { optionMap, productMap, ingredientMap };
    });
  };

  fetchStockInfo = async id => {
    const ids = id
      ? { ids: [+id] }
      : { ids: this.data.map(i => i.id).filter(i => i !== undefined) };

    const data = await IngredientAPI.fetchStockInfo(ids);

    runInAction(() => {
      this.stockInfo = data.stockMap;
    });
  };

  @action
  deleteIngredient = async id => {
    try {
      await IngredientAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
    runInAction(() => {
      this.data = this.data.filter(i => i.id !== id);
    });
  };

  @action
  uploadFile = async file => {
    try {
      runInAction(() => {
        this.fileLoading = true;
      });
      await IngredientAPI.uploadFile(file);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      runInAction(() => {
        this.fileLoading = false;
      });
    }
  };
}

export default IngredientStore;
