import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from 'semantic-ui-react';

import {ButtonWrapper} from './style';

const BasicButton = ({text, icon, onClick}) => (
  <ButtonWrapper>
    <Button icon labelPosition='right' onClick={onClick}>
      {icon && (<Icon name={icon} />)}

      {text}
    </Button>
  </ButtonWrapper>
);

BasicButton.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

BasicButton.defaultProps = {
  icon: '',
};

export default BasicButton;