import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import uuidv4 from 'uuid/v4';

import Popover from 'react-popover';

import { Icon, Menu } from 'semantic-ui-react';

import { AVAILABLE_PAGINATE_SIZES } from 'utils/constants';

import { Input, PaginateWrapper } from './style';

const PaginationSize = ({ changePaginationSize, currentSize }) => {
  const [popoverOpened, setPopover] = useState(false);

  const sizeAction = (event, size) => {
    event.stopPropagation();
    changePaginationSize(size);
    setPopover(false);
  };

  const togglePopover = () => setPopover(!popoverOpened);

  const renderTarget = () => (
    <PaginateWrapper>
      <FormattedMessage id="pagination.per.size" defaultProps="Rows per page:" />
      <Input onClick={togglePopover}>
        {currentSize}
        <Icon name={`angle ${popoverOpened ? 'up' : 'down'}`} />
      </Input>
    </PaginateWrapper>
  );

  return (
    <Popover
      className="paginate-size"
      isOpen={popoverOpened}
      place="above"
      body={
        <Menu vertical borderless>
          {AVAILABLE_PAGINATE_SIZES.map(size => (
            <Menu.Item key={uuidv4()} onClick={event => sizeAction(event, size)}>
              {size}
            </Menu.Item>
          ))}
        </Menu>
      }
      target={renderTarget()}
      tipSize={0.01}
      onOuterAction={() => setPopover(false)}
    >
      {renderTarget()}
    </Popover>
  );
};

PaginationSize.propTypes = {
  currentSize: PropTypes.number.isRequired,
  changePaginationSize: PropTypes.func.isRequired,
};

export default PaginationSize;
