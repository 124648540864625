import styled from 'styled-components';

const sizes = {
  m: 17,
  l: 20,
};

const iconSize = {
  m: 14,
  l: 16,
};

export const CheckboxContainer = styled.div`
  position: relative;
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  min-width: ${props => sizes[props.size] + 'px !important'};
  min-height: ${props => sizes[props.size] + 'px !important'};

  input ~ .box:before,
  input ~ label:before {
    width: ${props => sizes[props.size] + 'px !important'};
    height: ${props => sizes[props.size] + 'px !important'};
    border-radius: 2px !important;
    background-color: ${props => (props.collision ? 'var(--color-primary) !important' : 'auto')};
    border-color: ${props => (props.collision ? 'transparent !important' : 'auto')};
  }

  .ui.checkbox input ~ .box:after,
  .ui.checkbox input ~ label:after {
    color: #fff;
    width: ${props => sizes[props.size] + 'px !important'};
    height: ${props => sizes[props.size] + 'px !important'};
    font-size: ${props => iconSize[props.size] + 'px !important'};
    top: ${props => (props.size === 'l' ? '1px !important' : '0')};
  }

  input:checked ~ .box:before,
  input:checked ~ label:before {
    background-color: var(--color-primary) !important;
    border-color: transparent !important;
  }

  .ui.checkbox input:checked ~ .box:after,
  .ui.checkbox input:checked ~ label:after {
    color: #fff;
  }
`;
