import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FormSection from 'components/common/form-section';
import Switcher from 'components/common/switcher';

const General = inject('AccountEditor')(
  observer(({ AccountEditor, isEdit }) => {
    const {
      account,
      changeAccount,
      loadCurrency,
      loadTypes,
      currencyList,
      typeList,
    } = AccountEditor;

    const { name, type, accountNumber, currency } = account;

    const change = (_event, { name, value }) => {
      changeAccount(name, value);
    };

    const onButtonClick = type => {
      changeAccount('type', type);
    };

    useEffect(() => {
      loadCurrency();
      loadTypes();
    }, []);

    return (
      <FormSection>
        <Form.Group widths="2">
          <Form.Field required>
            <label>
              <FormattedMessage id="account.data.name" defaultMessage="Name" />
            </label>
            <FormattedMessage id="account.data.name.placeholder" defaultMessage="Enter the name">
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="name"
                  placeholder={placeholder}
                  maxLength={35}
                  value={name}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="account.data.accountNumber" defaultMessage="Account number" />
            </label>
            <FormattedMessage
              id="account.data.accountNumber.placeholder"
              defaultMessage="Enter the account number"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="accountNumber"
                  placeholder={placeholder}
                  maxLength={35}
                  value={accountNumber}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field>
            <label>
              <FormattedMessage id="account.data.type" defaultMessage="Type" />
            </label>
            <Form.Field>
              <Switcher onChange={onButtonClick} options={typeList} value={type} />
            </Form.Field>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="account.data.currency" defaultMessage="Currency" />
            </label>
            <FormattedMessage
              id="account.data.currency.placeholder"
              defaultMessage="Select currency"
            >
              {placeholder => (
                <Dropdown
                  placeholder={placeholder}
                  fluid
                  search
                  selection
                  options={currencyList}
                  name="currency"
                  value={currency}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default General;
