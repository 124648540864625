import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { AddButton } from 'components/common/buttons';
import { routes } from 'routes';
import { processTableData } from 'utils/process-table-data';
import styles from './styles.scss';

const Storages = inject(
  'StorageDictionary',
  'Modals',
)(
  observer(({ StorageDictionary, history, Modals }) => {
    const {
      tableFormat,
      data,
      fetchData,
      deleteStorage,
      loading,
      paginateOptions,
      paginateData,
    } = StorageDictionary;

    const addStorage = () => history.push(`${routes.storageDictionary}/new`);

    const editStorage = storageId => history.push(`${routes.storageDictionary}/${storageId}`);

    const deleteItem = (id, item) => {
      Modals.show(undefined, { subject: item.name }, () => deleteStorage(id));
    };

    useEffect(() => {
      fetchData();
    }, [paginateData]);

    const menu = [
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          onRowClick: () => editStorage(item.id),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
        };
      });
    };

    return (
      <div>
        <div className={styles.buttonWrapper}>
          <AddButton
            text={<FormattedMessage id="storage.add.storage" defaultMessage="Add storage" />}
            onClick={addStorage}
          />
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateOptions={paginateOptions}
            paginateData={paginateData}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Storages);
