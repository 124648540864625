import APIConnector from './utils/APIConnector';
import { UNAUTHORIZED } from './utils/constants';

const Request = APIConnector.instance;
const ENDPOINT = '/user';

class AuthAPI {
  static setToken = token => {
    Request.token = token;
  };

  static login = async credentials => {
    const { token, refreshToken } = await Request.POST(`/supply/login`, credentials);

    Request.updateTokens(token, refreshToken);
  };

  static getUserInfo = async () => {
    if (Request.authorizationToken) {
      try {
        const userInfo = await Request.GET(`${ENDPOINT}/info`);
        return userInfo;
      } catch (error) {
        if (error.message === UNAUTHORIZED) {
          return AuthAPI.refreshToken();
        } else {
          throw error;
        }
      }
    }
  };

  static checkCredentials = async credentials => {
    return await Request.POST(`${ENDPOINT}/check/mobileCredentials`, credentials, {
      headers: { phonenumber: credentials.mobile },
    });
  };

  static confirmRegistration = async confirmationCode => {
    await Request.POST(`${ENDPOINT}/confirm`, { key: confirmationCode });
  };

  static refreshTokenOnly = async () => {
    const { token, refreshToken } = await Request.POST(`${ENDPOINT}/refresh-token`, {
      token: Request.authorizationToken,
      refreshToken: Request.refreshToken,
    });

    Request.updateTokens(token, refreshToken);
  };

  static refreshToken = async () => {
    const { token, refreshToken } = await Request.POST(`${ENDPOINT}/refresh-token`, {
      token: Request.authorizationToken,
      refreshToken: Request.refreshToken,
    });

    Request.updateTokens(token, refreshToken);

    return AuthAPI.getUserInfo();
  };

  static resendConfirmationCode = async mobile =>
    await Request.POST(`${ENDPOINT}/resend/phoneNumber`, {}, { headers: { phonenumber: mobile } });

  static logout = () => {
    Request.token = null;
  };

  static resetPassword = mobile =>
    Request.POST(
      `${ENDPOINT}/mobilePassword/reset`,
      { mobile },
      {
        headers: { phonenumber: mobile },
      },
    );

  static createNewPassword = async body => {
    const { token: authToken, refreshToken } = await Request.POST(
      `${ENDPOINT}/mobilePassword/new`,
      body,
    );

    Request.updateTokens(authToken, refreshToken);
  };
}

export default AuthAPI;
