import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FormSection from 'components/common/form-section';

const General = inject('IngredientCategoryEditor')(
  observer(({ IngredientCategoryEditor, isEdit }) => {
    const { ingredientCategory, changeIngredientCategory } = IngredientCategoryEditor;

    const { name } = ingredientCategory;

    const change = (_event, { name, value }) => {
      changeIngredientCategory(name, value);
    };

    return (
      <FormSection>
        <Form.Group widths="2">
          <Form.Field required>
            <label>
              <FormattedMessage id="ingredient-category.data.name" defaultMessage="Name" />
            </label>
            <FormattedMessage
              id="ingredient-category.data.name.placeholder"
              defaultMessage="Enter the name"
            >
              {placeholder => (
                <Input
                  fluid
                  autoFocus
                  type="text"
                  name="name"
                  placeholder={placeholder}
                  maxLength={35}
                  value={name}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default General;
