import React from 'react';
import { useTransition, animated } from 'react-spring';
import { Modal, Button, Icon } from 'semantic-ui-react';
import ErrorBoundary from 'components/common/error-boundary';
import animations from 'utils/animations';
import { FormattedMessage } from 'react-intl';
import { CSVLink } from 'react-csv';
import closeIcon from 'images/auth/close-icon.svg';
import { toJS } from 'mobx';
import cn from 'classnames';
import styles from './styles.scss';

const DownloadCSVModal = ({ open = true, closeModal, dataByIngredients, dataByLots, headers }) => {
  const { from, enter, leave } = animations.fadeIn;
  const transitions = useTransition(open, null, {
    from,
    enter,
    leave,
  });

  return transitions.map(({ item, key, props }) => {
    return (
      item && (
        <animated.div key={key} style={props}>
          <ErrorBoundary>
            <Modal open={open} onClose={closeModal} className="popupModal" size="tiny">
              <Modal.Content>
                <div className={styles.content}>
                  <img
                    src={closeIcon}
                    alt="eye-icon"
                    className={styles.closeIcon}
                    onClick={closeModal}
                  />
                  <div className={styles.byIngredientsBtn}>
                    <CSVLink
                      data={toJS(dataByIngredients)}
                      filename="my-file.csv"
                      className={cn('btn btn-primary')}
                      target="_blank"
                      headers={[...headers]}
                    >
                      <Button
                        style={{ width: '100%' }}
                        content={
                          <FormattedMessage
                            id="inventory.switcher.byIngredient"
                            defaultMessage="By ingredients"
                          />
                        }
                        basic
                        icon="download"
                        labelPosition="right"
                      />
                    </CSVLink>
                  </div>
                  <div className={styles.byLotsBtn}>
                    <CSVLink
                      data={toJS(dataByLots)}
                      filename="my-file.csv"
                      className={cn('btn btn-primary')}
                      target="_blank"
                      headers={[...headers]}
                    >
                      <Button
                        style={{ width: '100%' }}
                        content={
                          <FormattedMessage
                            id="inventory.switcher.byBatch"
                            defaultMessage="By batch"
                          />
                        }
                        basic
                        icon="download"
                        labelPosition="right"
                      />
                    </CSVLink>
                  </div>
                </div>
              </Modal.Content>
            </Modal>
          </ErrorBoundary>
        </animated.div>
      )
    );
  });
};

export default DownloadCSVModal;
