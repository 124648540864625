import styled from 'styled-components';

import Calendar from 'images/calendar.svg';

import { MOBILE_BREAKPOINT } from 'utils/constants';

export const CalendarWrapper = styled('div')`
  margin-right: 1rem;
  position: relative;

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-right: 0;

    & > div {
      width: 100%;
      flex-basis: 100%;
    }
  }

  label {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 2.14;
    color: rgb(63, 63, 75);
    display: inline-block;
    margin-top: -10px;
  }

  & div::after,
  & div::before {
    border: none !important;
    border: none !important;
  }

  input {
    width: 100%;
    height: 34px;
    padding: 0 0 0 12px !important;
    border-radius: 4px;
    border: solid 1px #dededf;
    background-image: url(${Calendar});
    background-size: 20px 18px;
    background-repeat: no-repeat;
    background-position: right 8px center;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: #3f3f4b;
    cursor: pointer;

    @media screen and (max-width: 579px) {
      padding-left: 4px;
      background-size: 16px 14px;
      background-position: right 4px center;
      font-size: 12px;
      line-height: 1;
    }
  }

  ${({ fluid }) =>
    fluid &&
    `
   margin-right: 0;

    & > div {
      width: 100%;
    }
   `};
`;
