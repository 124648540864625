import { createBrowserHistory } from 'history';

export const routes = {
  auth: '/auth',
  login: '/auth/login',
  signup: '/auth/signup',
  start: '/',
  ingredient: '/ingredient',
  ingredientCategory: '/ingredient-category',
  storage: '/storage',
  storageDictionary: '/storage-dictionary',
  analytics: '/analytics',
  supplier: '/supplier',
  account: '/account',
  supplyRecord: '/supply-record',
  inventory: '/inventory',
  packing: '/packing',
};

const history = createBrowserHistory();

export default history;
