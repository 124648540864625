import React from 'react';
import { Popup } from 'semantic-ui-react';
import styles from './styles.scss';

import Body from './body';

const InfoPopup = ({ name, optionMap, productMap, position, ingredientMap }) => {
  return (
    <div className={styles.statusList}>
      <React.Fragment>
        <Popup
          on="click"
          eventsEnabled={false}
          hideOnScroll={false}
          pinned
          trigger={<div className={styles.ingredientName}>{name}</div>}
          position={position}
          className={styles.popup}
        >
          <Body optionMap={optionMap} productMap={productMap} ingredientMap={ingredientMap} />
        </Popup>
      </React.Fragment>
    </div>
  );
};

export default InfoPopup;
