import styled from 'styled-components';
import logout from 'images/logout.svg';

import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from 'utils/constants';

export const HEADER_HEIGHT = '48px';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: #2a282a;
`;

export const Logo = styled.img`
  height: 60px;

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 32px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .base {
      font-size: 12px;
    }
  }
`;

export const LogoutButton = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${logout});
  cursor: pointer;
`;

export const MobileMenuButton = styled.div`
  cursor: pointer;
  display: none;
  width: 22px;
  height: 24px;
  margin-right: 16px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
  }
`;

export const Divider = styled.div`
  &::after {
    content: '';
    display: inline-block;
    height: 36px;
    width: 2px;
    background: rgba(256, 256, 256, 0.5);
    margin: 0 23px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  color: #fff;
  font-weight: bold;

  @media (max-width: 991px) {
    display: none;
  }
`;
