import InventoryAPI from 'api/inventory';
import IngredientAPI from 'api/ingredient';
import StorageAPI from 'api/storage';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import { getUTCOffset } from 'utils/time';

const STORAGE_NAME = 'storageName';
const COMMENT = 'comment';
const BUSINESS_TIMESTAMP = 'businessTimestamp';
const ENDOFDAY_STOCK = 'endOfDayStockText';
const STATUS = 'statusText';
const ORDER_NUMBER = 'orderNumber';

export const ACCOUNTS_TABLE_FORMAT = [
  {
    name: '№',
    property: ORDER_NUMBER,
    variant: ['left'],
  },
  {
    name: getLocalizedString('inventory.stock'),
    property: STORAGE_NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('inventory.business-date'),
    property: BUSINESS_TIMESTAMP,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.time'),
    property: ENDOFDAY_STOCK,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.comment'),
    property: COMMENT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.status'),
    property: STATUS,
    variant: ['left'],
  },
];

class InventoryStore {
  constructor(inventoryEditor) {
    this.inventoryEditor = inventoryEditor;
  }
  tableFormat = ACCOUNTS_TABLE_FORMAT;
  @observable data = [];
  @observable listDrafts = [];
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };
  @observable allFilters = {
    start: null,
    end: null,
    ingredientId: { value: 'all', label: getLocalizedString('filters.all') },
    storageId: 'all',
  };

  @action
  changeAllFilters = value => {
    runInAction(() => {
      this.allFilters = { ...value };
    });
    this.changePaginateData({ ...this.paginateData, page: 0 });
    this.fetchData();
  };

  @action
  changePaginateData = ({ page, size, fetchData }) => {
    runInAction(() => {
      this.paginateData = { page, size };
    });
    if (fetchData) {
      this.fetchData();
    }
  };

  @action
  loadIngredientOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientAPI.list({ page, size: 20 });
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list({ page: 0, size: 20, name: search });
      filteredOptions = [...items];
    }
    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadStoragesOptions = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storagesOptions = [
        { value: 'all', text: getLocalizedString('filters.all') },
        ...items.map(item => ({
          value: item.id,
          text: item.name,
          key: item.id,
        })),
      ];
    });
  };

  @action
  deleteItem = async id => {
    try {
      await InventoryAPI.delete(id);
      await this.changePaginateData({ ...this.paginateData, page: 0 });
      await this.fetchData();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  fetchData = async () => {
    const { ingredientId, storageId } = this.allFilters;
    const startDate = moment(this.allFilters.start)
      .startOf('day')
      .valueOf();
    const endDate = moment(this.allFilters.end)
      .endOf('day')
      .valueOf();
    const newAllFilters = {
      ...this.allFilters,
      ingredientId: ingredientId.value === 'all' ? '' : ingredientId.value,
      storageId: storageId === 'all' ? '' : storageId,
      start: this.allFilters.start ? startDate + getUTCOffset(startDate) : '',
      end: this.allFilters.end ? endDate + getUTCOffset(endDate) : '',
    };
    try {
      const { page, size } = this.paginateData;
      const { items, last, totalPages, totalElements } = await InventoryAPI.list(
        page,
        size,
        newAllFilters,
      );

      const draftData = await InventoryAPI.getAllDrafts();

      const allItems = [...draftData.items, ...items];

      const newItems = allItems.map(item => ({
        ...item,
        businessTimestamp: moment(item.businessTimestamp).format('DD MMM YYYY'),
        createdTimestamp: moment(item.createdTimestamp).format('DD MMM YYYY'),
        statusText:
          item.status === 'ACTIVE'
            ? getLocalizedString('supply-record.data.status.active')
            : getLocalizedString('supply-record.data.status.draft'),
        endOfDayStockText: !item.endOfDayStock
          ? getLocalizedString('inventory.switcher.stockTime.start')
          : getLocalizedString('inventory.switcher.stockTime.end'),
      }));

      runInAction(() => {
        this.data = newItems;
        this.paginateOptions = {
          last,
          totalElements,
          totalPages,
          onPageChange: (page, size) => {
            this.changePaginateData({ page, size, fetchData: true });
          },
        };
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getListDrafts = async () => {
    try {
      const { items } = await InventoryAPI.getAllDrafts();
      runInAction(() => {
        this.listDrafts = items;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };
}

export default InventoryStore;
