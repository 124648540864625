import React from 'react';
import { Popup } from 'semantic-ui-react';
import styles from './styles.scss';

import Body from './body';

const InfoPopup = ({ date, data, structuredData, currency }) => {
  return (
    <div className={styles.statusList}>
      <React.Fragment>
        <Popup
          on="click"
          eventsEnabled={false}
          hideOnScroll={false}
          pinned
          trigger={<div style={{ cursor: 'pointer', textDecoration: 'underline' }}>{date}</div>}
          position="left center"
          className={styles.popup}
        >
          <Body items={data} structuredData={structuredData} currency={currency} />
        </Popup>
      </React.Fragment>
    </div>
  );
};

export default InfoPopup;
