import { action, observable, runInAction } from 'mobx';
import InventoryAPI from 'api/inventory';
import IngredientAPI from 'api/ingredient';
import history, { routes } from 'routes';
import StorageAPI from 'api/storage';
import IngredientCategoryAPI from 'api/ingredient-category';
import moment from 'moment';
import get from 'lodash/get';
import validate from 'validate.js';
import { getLocalizedString } from 'i18n/utils';
import {
  initInventory,
  initAllFilters,
  VALIDATION_SCHEMA,
  MODAL_TABLE_FORMAT,
  TABLE_FORMAT,
  CSVHeader,
  STOCK_TIME_TYPE_LIST,
} from './constants';

class InventoryEditor {
  constructor(inventoryStore, AccountEditor) {
    this.inventoryStore = inventoryStore;
    this.accountEditor = AccountEditor;
  }
  @observable inventory = initInventory;
  @observable storageList = [];
  @observable allFilters = initAllFilters;
  @observable ingredientsOptions = [];
  @observable validationErrors = {};
  @observable loading = false;
  @observable fullTableFormat = TABLE_FORMAT;
  @observable modalTableFormat = MODAL_TABLE_FORMAT;
  @observable stockTakeItemsClone = [];
  @observable storageList = [];
  @observable inventoryDraft = { id: 0, updatedTimestamp: 0 };
  @observable ingredientAudit = { data: [], periodStartUtc: 0 };
  @observable headerForCSV = CSVHeader;
  @observable stockTimeTypeList = STOCK_TIME_TYPE_LIST;

  @action
  setInventory = data => {
    runInAction(() => {
      this.inventory = { ...data };
      this.changeStockTakeItemsClone(data.stockTakeItems);
    });
  };

  @action
  getDraft = async () => {
    try {
      const data = await InventoryAPI.getDraft();
      if (data.storageId > 0) {
        this.changeAllFilters('storageFilters', [data.storageId]);
        this.changeAllFilters('endOfDayStock', data.endOfDayStock);
        this.changeAllFilters('businessTimestamp', data.businessTimestamp);
        const { storageStockList: items } = await StorageAPI.fetchAllIngredients(this.allFilters);
        const inventoryData = {
          ...data,
          stockTakeItems: this.getStockTakeItems(data.stockTakeItems, items),
          businessTimestamp:
            data.businessTimestamp === 0 ? new Date() : moment(data.businessTimestamp).valueOf(),
        };
        runInAction(() => {
          this.inventoryDraft = inventoryData;
        });
      } else {
        runInAction(() => {
          this.inventoryDraft = { ...data };
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getEditDraft = async draftId => {
    try {
      const data = await InventoryAPI.getItem(draftId);
      this.changeAllFilters('storageFilters', [data.storageId]);
      this.changeAllFilters('endOfDayStock', data.endOfDayStock);
      this.changeAllFilters('businessTimestamp', data.businessTimestamp);
      const { storageStockList: items } = await StorageAPI.fetchAllIngredients(this.allFilters);
      const inventoryData = {
        ...data,
        stockTakeItems: this.getStockTakeItems(data.stockTakeItems, items),
        businessTimestamp:
          data.businessTimestamp === 0 ? new Date() : moment(data.businessTimestamp).valueOf(),
      };
      runInAction(() => {
        this.inventoryDraft = inventoryData;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getStockTakeItems = (draftStockTakeItems, stockTakeItems) => {
    let newStockTakeItemsKey = {};
    draftStockTakeItems.map(item => {
      const key = item.ingredientId;
      newStockTakeItemsKey[key] = item.ingredientTakeAmount || '';
    });

    let newStockTakeItems = stockTakeItems.map(item => {
      const key = item.ingredientId;
      if (newStockTakeItemsKey[key]) {
        const ingredientTakeAmount = newStockTakeItemsKey[key];
        delete newStockTakeItemsKey[key];
        return {
          ...item,
          ingredientAmount: item.stock,
          ingredientTakeAmount,
        };
      } else {
        return {
          ...item,
          ingredientAmount: item.stock,
          ingredientTakeAmount: item.ingredientTakeAmount ? item.ingredientTakeAmount : '',
        };
      }
    });
    draftStockTakeItems.map(item => {
      const key = item.ingredientId;
      if (newStockTakeItemsKey[key]) {
        delete newStockTakeItemsKey[key];
        newStockTakeItems.push({
          ...item,
        });
      }
    });
    return newStockTakeItems;
  };

  @action
  getItem = async id => {
    try {
      this.loading = true;
      const data = await InventoryAPI.getItem(id);
      runInAction(() => {
        this.inventory = data;
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  getAudit = async ({ ingredientId, expTime, price }) => {
    let data = [];
    try {
      if (expTime !== undefined && price !== undefined) {
        data = await IngredientAPI.getAuditByParty(ingredientId, expTime, price);
      } else {
        data = await IngredientAPI.getAudit(ingredientId);
      }

      runInAction(() => {
        this.ingredientAudit = data;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeAllFilters = (key, value) => {
    if (key === 'categoryFilters') {
      if (value[0] && value[0].value === 'all') {
        runInAction(() => {
          this.allFilters[key] = value.filter(i => i.value !== 'all');
        });
      } else if (value[0] && value.some(i => i.value === 'all')) {
        this.allFilters[key] = [];
      } else {
        this.allFilters[key] = value;
      }
    } else {
      runInAction(() => {
        this.allFilters[key] = value;
      });
    }
  };

  @action
  loadCategoryOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientCategoryAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientCategoryAPI.list(0, 20, search);
      filteredOptions = [...items];
    }

    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  fetchIngredients = async () => {
    const newCategoryFilters = this.allFilters.categoryFilters.map(i => i.value);
    const newFilters = {
      ...this.allFilters,
      categoryFilters: newCategoryFilters,
    };
    try {
      this.loading = true;
      const { storageStockList: items } = await StorageAPI.fetchAllIngredients(newFilters);
      runInAction(() => {
        const lastStockTakeItems = [...this.inventory.stockTakeItems];
        this.inventory.stockTakeItems = items.map(i => ({
          ...i,
          createdTimestamp: 0,
          id: 0,
          ingredientAmount: i.stock,
          merchantGroupId: 0,
          status: '',
          stockTakeRecordId: 0,
          updatedTimestamp: 0,
          ingredientTakeAmount:
            lastStockTakeItems.find(elem => elem.ingredientId === i.ingredientId)
              ?.ingredientTakeAmount ?? '',
        }));

        this.stockTakeItemsClone = items.map(i => ({
          ...i,
          createdTimestamp: 0,
          id: 0,
          ingredientAmount: i.stock,
          merchantGroupId: 0,
          status: '',
          stockTakeRecordId: 0,
          updatedTimestamp: 0,
          ingredientTakeAmount:
            lastStockTakeItems.find(elem => elem.ingredientId === i.ingredientId)
              ?.ingredientTakeAmount ?? '',
        }));

        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeStockTakeItemsClone = value => {
    runInAction(() => {
      this.stockTakeItemsClone = [
        ...(value || []).map(i => ({
          ...i,
        })),
      ];
    });
  };

  @action
  changeInventory = (key, value) => {
    if (Object.keys(this.validationErrors).length) {
      this.validationErrors = {};
    }
    runInAction(() => {
      if (key === 'storageId') {
        const { text } = this.storageList.find(i => i.value === value);
        this.inventory.storageId = value;
        this.inventory.storageName = text;
      } else {
        this.inventory[key] = value;
      }
    });
  };

  @action
  changeStockTakeItems = (uniqueKey, value) => {
    runInAction(() => {
      this.inventory.stockTakeItems = [
        ...this.inventory.stockTakeItems.map(item => {
          if (Number(uniqueKey) === Number(item.ingredientId)) {
            return { ...item, ingredientTakeAmount: value };
          } else {
            return item;
          }
        }),
      ];
    });
  };

  @action
  removeStockTakeItems = () => {
    runInAction(() => {
      this.inventory.stockTakeItems = [];
    });
  };

  @action
  loadStorages = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storageList = items.map(item => ({
        value: item.id,
        text: item.name,
        key: item.id,
      }));
    });
  };

  @action
  haveChanges = (ary1, ary2) => {
    if (ary1[0]) {
      const objectKeys = Object.keys(ary1[0]);

      const notHaveChange = (item, index) => {
        const array = objectKeys.map(key => get(item, `${[key]}`) === get(ary2, `${[index][key]}`));
        return array.filter(i => i === false).length === 0;
      };

      return !(
        ary1.map((item, index) => notHaveChange(item, index)).filter(i => i === false).length === 0
      );
    } else {
      return false;
    }
  };

  @action
  pickupDraft = async id => {
    try {
      await InventoryAPI.pickupDraft(id);
      const data = await InventoryAPI.getDraft();
      runInAction(() => {
        this.inventory = {
          ...data,
          stockTakeItems: this.getStockTakeItems(
            data.stockTakeItems,
            this.inventory.stockTakeItems,
          ),
        };
        this.inventoryDraft = {
          ...data,
          stockTakeItems: this.getStockTakeItems(
            data.stockTakeItems,
            this.inventory.stockTakeItems,
          ),
          id: data.id,
          updatedTimestamp: data.updatedTimestamp,
          businessTimestamp:
            data.businessTimestamp === 0 ? new Date() : moment(data.businessTimestamp).valueOf(),
        };
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  onSaveChangedInventory = async status => {
    const newInventory = { ...this.inventory };
    newInventory.businessTimestamp =
      this.inventory.businessTimestamp === 0
        ? moment(new Date()).valueOf()
        : moment(this.inventory.businessTimestamp).valueOf();
    const inventoryStockTakeItems = newInventory.stockTakeItems.filter(
      i => i.ingredientTakeAmount !== '',
    );

    try {
      await InventoryAPI.changeInventory({
        ...newInventory,
        stockTakeItems: inventoryStockTakeItems,
        status,
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  onSubmit = async status => {
    const newInventory = { ...this.inventory };
    if (status === 'DRAFT') {
      this.changeStockTakeItemsClone(this.inventory.stockTakeItems);
    }
    newInventory.businessTimestamp =
      this.inventory.businessTimestamp === 0
        ? moment(new Date())
            .utc()
            .startOf('day')
            .valueOf()
        : moment(this.inventory.businessTimestamp).valueOf();
    const inventoryStockTakeItems = newInventory.stockTakeItems.filter(
      i => i.ingredientTakeAmount !== '',
    );

    this.validationErrors = validate(newInventory, VALIDATION_SCHEMA) || {};
    if (Object.keys(this.validationErrors).length === 0) {
      let data = {};
      if (status === 'DRAFT') {
        if (this.inventoryDraft.id === 0) {
          data = await InventoryAPI.createDraft({
            ...newInventory,
            stockTakeItems: inventoryStockTakeItems,
            status,
          });
        } else {
          data = await InventoryAPI.updateDraft({
            ...newInventory,
            stockTakeItems: inventoryStockTakeItems,
            status,
          });
        }
      } else {
        data = await InventoryAPI.submitDraft({
          ...newInventory,
          stockTakeItems: inventoryStockTakeItems,
          status,
        });
      }

      if (status === 'DRAFT' && data) {
        runInAction(() => {
          this.inventory = {
            ...data,
            stockTakeItems: this.getStockTakeItems(
              data.stockTakeItems,
              this.inventory.stockTakeItems,
            ),
          };
          this.inventoryDraft = {
            ...data,
            stockTakeItems: this.getStockTakeItems(
              data.stockTakeItems,
              this.inventory.stockTakeItems,
            ),
            id: data.id,
            updatedTimestamp: data.updatedTimestamp,
            businessTimestamp:
              data.businessTimestamp === 0 ? new Date() : moment(data.businessTimestamp).valueOf(),
          };
        });
      }

      if (status === 'ACTIVE') {
        history.push(routes.inventory);
      }
    }
  };

  @action
  clearValidation = () => {
    this.inventory = initInventory;
    this.validationErrors = {};
    this.allFilters = initAllFilters;
    this.loading = false;
    this.inventoryDraft = { id: 0, updatedTimestamp: 0 };
  };
}

export default InventoryEditor;
