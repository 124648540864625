import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { CURRENCY_CODE_TO_SIGN, SUPPLY, DISPOSAL, MOVE, CAST } from 'utils/constants';
import { Form, Dropdown } from 'semantic-ui-react';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { AddButton } from 'components/common/buttons';

import { getLocalizedString } from 'i18n/utils';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { routes } from 'routes';
import queryString from 'query-string';
import { processTableData } from 'utils/process-table-data';
import DateRangePicker from 'components/common/DateRangePicker';

import styles from './styles.scss';

const SupplyRecords = inject(
  'SupplyRecord',
  'SupplyRecordEditor',
  'User',
  'Notification',
  'MerchantsGroup',
)(
  observer(({ SupplyRecord, SupplyRecordEditor, history, User, Notification, MerchantsGroup }) => {
    const {
      tableFormat,
      data,
      totalAmount,
      fetchData,
      loading,
      deleteSupplyRecord,
      allFilters,
      changeAllFilters,
      loadIngredientOptions,
      loadStoragesOptions,
      storagesOptions,
      changePaginateData,
      paginateData,
    } = SupplyRecord;

    const { loadSuppliersOptions } = SupplyRecordEditor;

    const { start, end, ingredientId, types, storageId, supplierId } = allFilters;

    const fetch = async () => {
      await loadStoragesOptions();
    };

    useEffect(() => {
      fetch();
    }, []);

    const [allQueryFilters, setAllQueryFilters] = useState(
      history.location.search
        ? JSON.parse(queryString.parse(history.location.search).allQueryFilters)
        : '',
    );

    useEffect(() => {
      if (history.location.search) {
        setAllQueryFilters(JSON.parse(queryString.parse(history.location.search).allQueryFilters));
      }
    }, [history]);

    useEffect(() => {
      if (allQueryFilters) {
        changeAllFilters(allQueryFilters);
      }
    }, [allQueryFilters]);

    useEffect(() => {
      fetchData();
    }, [allFilters, paginateData]);

    const addSupplyRecord = value => {
      history.push(`${routes.supplyRecord}/new`);
      SupplyRecordEditor.changeInitSupplyRecord('storageRecordType', value);
    };

    const deleteItem = async id => {
      try {
        await deleteSupplyRecord(id);
        fetchData();
      } catch (error) {
        Notification.showError(error.message);
      }
    };

    const editSupplyRecord = (supplyRecordId, allowEditing) =>
      history.push(`${routes.supplyRecord}/${supplyRecordId}?queryAllowEditing=${allowEditing}`);

    const cloneSupplyRecord = supplyRecordId =>
      history.push(`${routes.supplyRecord}/new?duplicate=${supplyRecordId}`);

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          color: item.status === 'DELETED' ? true : false,
          orderNumber: item.orderNumber === 0 ? '-' : item.orderNumber,
          storageRecordType: (
            <FormattedMessage
              id={`supply-record.data.${item.storageRecordType}`}
              defaultMessage={item.storageRecordType}
            />
          ),
          onRowClick: () => editSupplyRecord(item.id, false),
          menu: <EntryMenu entry={item} menu={() => getItemMenu(item)} tableCell />,
          totalAmount: item.totalAmount !== '0.00' ? Number(item.totalAmount).toFixed(2) : '-',
        };
      });
    };

    const getItemMenu = item => {
      const edit = {
        name: <FormattedMessage id="menu.edit" defaultMessage="Edit" />,
        action: () => editSupplyRecord(item.id, true),
      };
      const duplicate = {
        name: <FormattedMessage id="menu.clone" defaultMessage="Duplicate" />,
        action: () => cloneSupplyRecord(item.id),
      };
      const toggleAction = {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      };

      if (item.status === 'DELETED') {
        return [];
      }
      if (item.storageRecordType === 'SUPPLY') {
        return [edit, duplicate, toggleAction];
      }
      return [edit, toggleAction];
    };

    const changeDate = dates => {
      if (dates) {
        changeAllFilters({ ...allFilters, start: dates[0], end: dates[1] });
      }
    };

    const typeOptions = [
      { text: getLocalizedString('filters.all'), value: 'all' },
      ...['SUPPLY', 'DISPOSAL', 'MOVE', 'CAST'].map(i => ({
        text: getLocalizedString(`supply-record.data.button.${i}`),
        value: i,
      })),
    ];
    const newIngredientIdFilters = !ingredientId.value
      ? { value: 'all', label: getLocalizedString('filters.all') }
      : ingredientId;

    const paginateOptions = {
      ...SupplyRecord.paginateOptions,
      onPageChange: (page, size) => {
        changePaginateData({ page, size });
      },
    };

    const changeTypes = (event, { name, value }) => {
      let newValue = [];
      if (value[0] === 'all') {
        newValue = value.filter(i => i !== 'all');
      } else if (value.some(i => i === 'all')) {
        newValue = [];
      } else {
        newValue = value;
      }
      changeAllFilters({ ...allFilters, [name]: newValue });
    };

    const newTypes = types.length === 0 ? ['all'] : types;
    const currency = CURRENCY_CODE_TO_SIGN[MerchantsGroup.info.currency];

    const newTableFormat = tableFormat.map(i => {
      if (i.property === 'totalAmount') {
        return {
          ...i,
          name: (
            <div className={styles.totalAmount}>
              {i.name}, {currency} <span>{totalAmount}</span>
            </div>
          ),
        };
      } else {
        return i;
      }
    });

    const onLoadSuppliersOptions = useCallback(async (search, prevOptions, page) => {
      const { hasMore, options } = await loadSuppliersOptions(search, prevOptions, page);

      return {
        hasMore,
        options: [{ value: 'all', label: getLocalizedString('filters.all') }, ...options],
      };
    }, []);

    return (
      <div style={{ marginTop: 16 }}>
        <form className="ui form">
          <Form.Group widths="5">
            <Form.Field>
              <FormattedMessage id="dateFilter.rangeCalendarFilter" defaultMessage="Date range">
                {label => (
                  <DateRangePicker
                    label={label}
                    date={!start && !end ? [] : [start, end]}
                    selectDate={changeDate}
                    onClean={() => changeDate([undefined, undefined])}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage
                  id="supply-record.dropdown.operationType"
                  defaultMessage="Operation type"
                />
              </label>
              <FormattedMessage
                id="supply-record.dropdown.operationType"
                defaultMessage="Operation type"
              >
                {placeholder => (
                  <Dropdown
                    placeholder={placeholder}
                    fluid
                    selection
                    multiple
                    options={typeOptions}
                    name="types"
                    value={newTypes}
                    onChange={changeTypes}
                  />
                )}
              </FormattedMessage>
            </Form.Field>

            <Form.Field>
              <label>
                <FormattedMessage id="analytics.dropdown.storage" defaultMessage="Storage" />
              </label>
              <FormattedMessage
                id="analytics.dropdown.storage.placeholder"
                defaultMessage="Select storage"
              >
                {placeholder => (
                  <Dropdown
                    placeholder={placeholder}
                    fluid
                    search
                    selection
                    options={storagesOptions}
                    name="storageId"
                    value={storageId}
                    onChange={(event, { name, value }) =>
                      changeAllFilters({ ...allFilters, [name]: value })
                    }
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage
                  id="supply-record.data.supplier"
                  defaultMessage="Select supplier"
                />
              </label>
              <FormattedMessage
                id="supply-record.data.supplier.placeholder"
                defaultMessage="Select supplier"
              >
                {placeholder => (
                  <AsyncDropdown
                    loadOptions={(search, prevOptions, page) =>
                      onLoadSuppliersOptions(search, prevOptions, page)
                    }
                    placeholder={placeholder}
                    value={supplierId}
                    onChange={value => changeAllFilters({ ...allFilters, supplierId: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage
                  id="supply-record.dropdown.ingredient"
                  defaultMessage="Ingredient"
                />
              </label>
              <FormattedMessage id="supply-record.dropdown.ingredient" defaultMessage="Ingredient">
                {placeholder => (
                  <AsyncDropdown
                    loadOptions={loadIngredientOptions}
                    placeholder={placeholder}
                    value={newIngredientIdFilters}
                    onChange={value => {
                      changeAllFilters({ ...allFilters, ingredientId: value });
                    }}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </Form.Group>
        </form>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <AddButton
            text={
              <FormattedMessage id="supply-record.data.button.SUPPLY" defaultMessage="Supply" />
            }
            onClick={() => addSupplyRecord(SUPPLY)}
          />

          {SupplyRecordEditor.storageList.length !== 1 && (
            <AddButton
              text={<FormattedMessage id="supply-record.data.button.MOVE" defaultMessage="Move" />}
              onClick={() => addSupplyRecord(MOVE)}
            />
          )}
          <AddButton
            text={
              <FormattedMessage id="supply-record.data.button.DISPOSAL" defaultMessage="Disposal" />
            }
            onClick={() => addSupplyRecord(DISPOSAL)}
          />
          <AddButton
            text={<FormattedMessage id="supply-record.data.button.CAST" defaultMessage="CAST" />}
            onClick={() => addSupplyRecord(CAST)}
          />
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...newTableFormat,
              User.info.canManageSupply && {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateData={paginateData}
            paginateOptions={paginateOptions}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(SupplyRecords);
