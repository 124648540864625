import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import SubHeader from 'components/common/sub-header';
import { INVENTORY_ACCESS_LEVEL } from 'utils/constants';
import { routes } from 'routes';
import get from 'lodash.get';
import { withRouter, useLocation } from 'react-router-dom';
import Modal from 'components/common/modal';
import ShowDate from 'components/inventory/editor/show-date';
import Inputs from './inputs';
import Table from './table';
import styles from './styles.scss';

const InventoryEditor = inject(
  'InventoryEditor',
  'Notification',
  'Inventory',
  'User',
  'Modals',
)(
  observer(({ InventoryEditor, match, Notification, Inventory, history, User, Modals }) => {
    const {
      onSubmit,
      clearValidation,
      getItem,
      inventory,
      stockTakeItemsClone,
      setInventory,
      inventoryDraft,
      onSaveChangedInventory,
      pickupDraft,
    } = InventoryEditor;

    const { pathname } = useLocation();
    const isEdit = pathname.includes('/new') ? false : match.params.id;
    const editDraftId = pathname.includes('/new') ? Number(match.params.id) : null;
    const { stockTakeItems } = inventory;
    const canManageSupply = get(User, 'info.inventoryAccessLevel') === INVENTORY_ACCESS_LEVEL.OWNER;
    const [disabled, setDisabled] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const { id, status, updatedTimestamp } = inventoryDraft;

    const stockTakeCompleted = () => {
      setVisibleModal(true);
    };

    const onPickupDraft = useCallback(
      async onClose => {
        try {
          await pickupDraft(id);
          onClose();
        } catch (e) {
          Notification.showError(e.message);
        }
      },
      [Notification, inventoryDraft],
    );

    const pickupDraftModal = useCallback(
      userName => {
        Modals.show(Modal, {
          buttonOnClick: onClose => onPickupDraft(onClose),
          buttonText: (
            <FormattedMessage
              id="inventory.modal.interceptDraft.button"
              defaultMessage="Intercept draft"
            />
          ),
          open: true,
          children: (
            <div className={styles.modalTitle}>
              <FormattedMessage
                id="inventory.modal.draft.edited"
                defaultMessage={`Draft is being edited ${userName}`}
                values={{ userName }}
              />
            </div>
          ),
        });
      },
      [inventoryDraft, onPickupDraft],
    );

    useEffect(() => {
      if (editDraftId) {
        InventoryEditor.getEditDraft(editDraftId);
      }
    }, [editDraftId]);

    useEffect(() => {
      if (!editDraftId && !isEdit) {
        Inventory.getListDrafts();
      }
    }, [editDraftId, isEdit]);

    useEffect(() => {
      if (!isEdit && id > 0 && inventoryDraft.userId !== get(User, 'info.id')) {
        pickupDraftModal(inventoryDraft.userName);
      }
    }, [isEdit, inventoryDraft, User.info]);

    useEffect(() => {
      if (!isEdit) {
        if (id > 0) {
          setInventory(inventoryDraft);
        }
      }
    }, [id, isEdit]);

    useEffect(() => {
      if (isEdit) {
        getItem(match.params.id);
      }
      return () => {
        clearValidation();
      };
    }, []);

    const submitForm = useCallback(async () => {
      setDisabled(true);
      const haveChanges = InventoryEditor.haveChanges(stockTakeItems, stockTakeItemsClone);
      try {
        if (haveChanges) {
          await onSubmit('ACTIVE');
        } else {
          Notification.showError(
            <FormattedMessage
              id="inventory.message.error"
              defaultMessage="Unable to save inventory, you haven't made any changes."
            />,
          );
        }
      } catch (error) {
        Notification.showError(error.message);
      }
      setDisabled(false);
    }, [stockTakeItems, stockTakeItemsClone, canManageSupply, Notification]);

    const isMobile = window.innerWidth < 1136;
    const mobileView = window.innerWidth < 767;
    const deleteItem = async () => {
      try {
        await Inventory.deleteItem(inventory.id);
        history.push(routes.inventory);
      } catch (error) {
        Notification.showError(error.message);
      }
    };

    const saveChangedInventory = useCallback(async () => {
      try {
        await onSaveChangedInventory('ACTIVE');
        history.push(routes.inventory);
      } catch (error) {
        Notification.showError(error.message);
      }
    }, [Notification, history]);

    const saveDraft = async (storageId, buttonId) => {
      if (storageId > 0) {
        try {
          if (storageId !== '') {
            await onSubmit('DRAFT');
            if (buttonId === 'save-draft-button') {
              history.push(routes.inventory);
            }
          }
        } catch (error) {
          if (error.message === 'error.inventory.stock.take.completed') {
            stockTakeCompleted();
          } else {
            if (String(error).match('is working on it.')) {
              const userName = String(error)
                .replace('Error: ', '')
                .replace('is working on it.', '');
              pickupDraftModal(userName);
            }
            Notification.showError(error.message);
          }
        }
      }
    };

    const RenderHeaderButtons = () => {
      return isEdit ? (
        <Button
          type="button"
          primary
          disabled={disabled}
          onClick={saveChangedInventory}
          style={{ minWidth: isMobile ? 0 : 180 }}
        >
          <FormattedMessage id="buttons.save" defaultMessage="Save" />
        </Button>
      ) : (
        status === 'DRAFT' && (
          <div className={styles.buttonBlock}>
            <div className={styles.buttonWrapper}>
              <div className={styles.deleteButton}>
                <Button
                  type="button"
                  color="red"
                  onClick={deleteItem}
                  style={{ minWidth: isMobile ? 0 : 180 }}
                >
                  {!mobileView && (
                    <FormattedMessage id="buttons.deleteDraft" defaultMessage="Delete draft" />
                  )}

                  {mobileView && (
                    <Icon name="trash alternate" color="white" className={styles.icon} />
                  )}
                </Button>
              </div>
              <Button
                type="button"
                primary
                onClick={evt => saveDraft(id, evt.currentTarget.id)}
                style={{ minWidth: isMobile ? 0 : 180 }}
                id="save-draft-button"
              >
                <FormattedMessage id="buttons.save" defaultMessage="Save" />
              </Button>
              <Button
                type="button"
                primary
                disabled={disabled}
                onClick={submitForm}
                style={{ minWidth: isMobile ? 0 : 180 }}
              >
                <FormattedMessage id="buttons.submit" defaultMessage="Submit" />
              </Button>
            </div>
            {!isEdit && updatedTimestamp !== 0 && (
              <ShowDate timestamp={updatedTimestamp} className={styles.showDate} />
            )}
          </div>
        )
      );
    };

    return (
      <form className="ui form">
        <SubHeader
          title={<FormattedMessage id="inventory.name" defaultMessage="Inventory" />}
          sticky={false}
          isEdit={isEdit}
          button={RenderHeaderButtons()}
        />
        <ErrorBoundary>
          <Inputs isEdit={isEdit} saveDraft={saveDraft} />
          <Table
            isEdit={isEdit}
            canManageSupply={canManageSupply}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
            saveDraft={saveDraft}
          />
        </ErrorBoundary>
      </form>
    );
  }),
);

export default withRouter(InventoryEditor);
