import React from 'react';
import Analytics from 'components/analytics';
import { BottomWrapper, DashboardWrapper } from '../style';

const AnalyticsPage = ({ history }) => {
  return (
    <DashboardWrapper>
      <BottomWrapper />
      <Analytics history={history} />
    </DashboardWrapper>
  );
};

export default AnalyticsPage;
