import React from 'react';
import { Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { inject, observer } from 'mobx-react';
import loadable from '@loadable/component';

import { routes } from 'routes';

const Authentication = loadable(() => import('components/authentication'));

const AuthenticationScene = inject('User')(
  observer(({ User }) => {
    return User.isAuthorized ? <Redirect to={routes.supplyRecord} /> : <Authentication />;
  }),
);

export default hot(AuthenticationScene);
