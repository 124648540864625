import React, { useState } from 'react';
// import { Tab, Menu } from 'semantic-ui-react';
// import { FormattedMessage } from 'react-intl';
// import { routes } from 'routes';
import SupplyRecord from 'components/supply-record';
import { BottomWrapper, DashboardWrapper } from '../style';

// const tabRoutes = {
//   '/supply-record': 0,
// };

const StoragePage = ({ history }) => {
  // const [activeIndex, setActiveIndex] = useState(tabRoutes[history.location.pathname]);

  // const panes = [
  //   {
  //     menuItem: (
  //       <Menu.Item onClick={() => history.push(routes.supplyRecord)} key="supply-record">
  //         <FormattedMessage id="supply-record.name" defaultMessage="Supply records" />
  //       </Menu.Item>
  //     ),
  //     render: () => (
  //       <Tab.Pane>
  //         <SupplyRecord />
  //       </Tab.Pane>
  //     ),
  //   },
  // ];

  // const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <DashboardWrapper>
      <BottomWrapper />
      <SupplyRecord />
    </DashboardWrapper>
  );
};

export default StoragePage;

// <Tab
//   panes={panes}
//   menu={{ secondary: true, pointing: true }}
//   activeIndex={activeIndex}
//   onTabChange={handleTabChange}
// />
