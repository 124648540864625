import styled from 'styled-components';

export const Box = styled.img`
  width: 36px;
  height: 36px;
  position: absolute;
  right: 45px;
  cursor: pointer;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 27px;
  z-index: 3;
`;
export const TabsWrapper = styled.div`
  ${'' /* padding-top: 40px; */}
  position: relative;
`;

export const DashboardWrapper = styled.div`
  position: relative;
  ${'' /* min-height: 100vh; */}
  ${'' /* padding-top: ${props => (props.isMobile ? '0' : '40px')}; */}
`;

export const MobileRouteWrapper = styled.div`
  padding-left: 16px;
`;

export const CurrnetMobilePageWrapper = styled.div`
  display: flex;
`;
export const CurrnetMobilePageImg = styled.img`
  height: 20px;
  width: 20px;
`;
export const CurrnetMobilePageTitle = styled.div`
  display: flex;
`;
