import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import General from './general';

const InventoryEditor = inject(
  'Storage',
  'Notification',
)(
  observer(({ Storage, match }) => {
    const isEdit = match.params.id;
    return (
      <form className="ui form">
        <ErrorBoundary>
          <General isEdit={isEdit} />
        </ErrorBoundary>
      </form>
    );
  }),
);

export default withRouter(InventoryEditor);
