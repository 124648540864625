import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AddButton } from 'components/common/buttons';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { routes } from 'routes';
import { processTableData } from 'utils/process-table-data';
import { Form } from 'semantic-ui-react';
import throttle from 'lodash/throttle';
import SearchResetInput from '../common/inputs/search-reset';
import FormSection from '../common/form-section';

const Packing = inject('Packing')(
  observer(({ Packing, history }) => {
    const {
      tableFormat,
      data,
      fetchData,
      deletePacking,
      loading,
      paginateOptions,
      paginateData,
      allFilters,
      changePaginateData,
      changeAllFilters,
    } = Packing;

    const [name, setName] = useState('');

    useEffect(() => {
      fetchData();
    }, [paginateData]);

    const addSupplier = () => history.push(`${routes.packing}/new`);

    const editPacking = id => history.push(`${routes.packing}/${id}`);

    const deleteItem = id => {
      deletePacking(id);
    };

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          onRowClick: () => editPacking(item.id),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
          unit: (
            <FormattedMessage
              id={`ingredient.unit.${(item.unit || '').toLowerCase()}`}
              defaultMessage={item.unit}
            />
          ),
        };
      });
    };

    const menu = [
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    const applyFilter = (name, value) => {
      if (value.length > 2) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters(name, value);
      } else if (value.length === 0) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters(name, value);
      }
    };

    const delayedCallback = useCallback(
      throttle((name, value) => applyFilter(name, value), 2000),
      [paginateData, allFilters],
    );

    const onChange = (name, value) => {
      setName(value);
      delayedCallback(name, value);
    };

    return (
      <div>
        <form className="ui form">
          <Form.Group widths="3">
            <Form.Field>
              <AddButton
                text={<FormattedMessage id="packing.add" defaultMessage="Create packing" />}
                onClick={addSupplier}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="packing.name" defaultMessage="Packing" />
              </label>
              <FormattedMessage
                id="packing.search.name.placeholder"
                defaultMessage="Enter packing name"
              >
                {placeholder => (
                  <SearchResetInput
                    value={name}
                    name="name"
                    placeholder={placeholder}
                    onChange={onChange}
                    style={{ width: '100%' }}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </Form.Group>
        </form>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateOptions={paginateOptions}
            paginateData={paginateData}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Packing);
