import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/pack';

class PackingAPI {
  static list = (page = 0, size = 20, rest) => {
    const queryParams = extractFiltersQuery(rest);
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${queryParams}`);
  };

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static update = (id, packingData) => Request.PUT(`${ENDPOINT}`, packingData);

  static create = packingData => Request.POST(ENDPOINT, packingData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default PackingAPI;
