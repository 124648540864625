import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';

import { CheckboxContainer } from './style';

const Checkbox = ({
  name,
  label,
  onChange,
  checked,
  collision,
  children,
  size,
  inline,
  styles,
}) => {
  return (
    <CheckboxContainer inline={inline} collision={collision} size={size} style={styles}>
      <SemanticCheckbox label={label} name={name} checked={checked} onChange={onChange} />
      {children}
    </CheckboxContainer>
  );
};

Checkbox.defaultProps = {
  label: '',
  collision: false,
  size: 'm',
  inline: false,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  collision: PropTypes.bool,
  size: PropTypes.string,
  styles: PropTypes.object,
};

export default Checkbox;
