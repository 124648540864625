import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { inject, PropTypes as MobXPropTypes } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import Popover from 'react-popover';
import { Menu, Icon, Table } from 'semantic-ui-react';
import cn from 'classnames';
import styles from './styles.scss';

@inject('Notification')
class EntryMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: false,
    };
  }

  toggleMenu = event => {
    event.stopPropagation();

    this.setState(prevState => ({ menuOpened: !prevState.menuOpened }));
  };

  closeMenu = () => this.setState({ menuOpened: false });

  menuAction = async (event, action) => {
    event.stopPropagation();

    const { entry, Notification } = this.props;

    this.closeMenu();

    try {
      await action(entry.id, entry);
    } catch (error) {
      Notification.showError(error.message);
    }
  };

  render() {
    const { menuOpened } = this.state;
    const { menu, entry, tableCell } = this.props;
    const items = Array.isArray(menu) ? menu : menu(entry);
    const menuStyle = {
      width: 40,
      opacity: 1,
      cursor: 'pointer',
      pointerEvents: 'all',
      height: '100%',
    };

    return (
      <ErrorBoundary>
        <div className={cn(styles.popoverWrapper, { [styles.menuWrapper]: items.length < 1 })}>
          <Popover
            isOpen={menuOpened}
            body={
              items.length > 0 ? (
                <Menu vertical borderless>
                  {items.map(({ name, action, icon }) => (
                    <Menu.Item key={uuidv4()} onClick={event => this.menuAction(event, action)}>
                      {name}
                      {icon && <Icon name={icon} />}
                    </Menu.Item>
                  ))}
                </Menu>
              ) : (
                <div />
              )
            }
            place="below"
            tipSize={0.01}
            onOuterAction={this.closeMenu}
          >
            {tableCell ? (
              <Table.Cell style={menuStyle} collapsing textAlign="center" onClick={this.toggleMenu}>
                <Icon color="black" name="ellipsis horizontal" className="menu-icon" />
              </Table.Cell>
            ) : (
              <Icon name="ellipsis horizontal" onClick={this.toggleMenu} />
            )}
          </Popover>
        </div>
      </ErrorBoundary>
    );
  }
}

EntryMenu.wrappedComponent.propTypes = {
  entry: PropTypes.object.isRequired,
  menu: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  tableCell: PropTypes.bool,
  Notification: MobXPropTypes.observableObject.isRequired,
};

EntryMenu.defaultProps = {
  tableCell: false,
};

export default EntryMenu;
