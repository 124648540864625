import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TableWrapper from 'components/common/TableWrapper';
import { getLocalizedString } from 'i18n/utils';
import styles from './styles.scss';

const tableHeaderOptions = [
  {
    name: <FormattedMessage id="supply-record.data.ingredient" defaultMessage="Ingredient" />,
    property: 'ingredientName',
    variant: ['nowrap'],
  },
  {
    name: <FormattedMessage id="ingredient.editor.cookingMethod" defaultMessage="Cooking method" />,
    property: 'cookingMethods',
    variant: ['nowrap'],
  },
  {
    name: <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount" />,
    property: 'ingredientAmount',
    variant: ['nowrap'],
  },
  {
    name: <FormattedMessage id="ingredient.editor.ingredientBruto" defaultMessage="Bruto" />,
    property: 'ingredientBrutto',
    variant: ['nowrap'],
  },
  {
    name: <FormattedMessage id="ingredient.editor.ingredientNetto" defaultMessage="Netto" />,
    property: 'ingredientNetto',
    variant: ['nowrap'],
  },
];

const Body = ({ items, structuredData, currency }) => {
  const processTableData = data => {
    return data.map(item => {
      const ingredientUnit =
        getLocalizedString(`ingredientCard.unit.${(item.ingredientUnit || '').toLowerCase()}`) ||
        '';
      const cookingMethods = [
        'wasteRatio',
        'cookingRatio',
        'fryingRatio',
        'stewingRatio',
        'roastingRatio',
      ];
      const newCookingMethods = cookingMethods
        .map(i => {
          if (item[i]) {
            return getLocalizedString(`products.editor.${i}`);
          } else {
            return null;
          }
        })
        .filter(i => i)
        .join(', ');

      const ingredientGram = getLocalizedString('ingredientCard.unit.kilogram');

      return {
        ...item,
        ingredientName: item.ingredient.name,
        ingredientAmount: `${item.ingredientAmount} ${ingredientUnit}`,
        ingredientBrutto: `${item.ingredientBrutto} ${ingredientGram}`,
        ingredientNetto: `${item.ingredientNetto} ${ingredientGram}`,
        totalApproxPrice: `${currency} ${item.totalApproxPrice}`,
        cookingMethods: newCookingMethods,
      };
    });
  };

  const newItems = structuredData(items);

  const totalNetto = newItems.reduce((acc, item) => {
    return (acc += Number(item.ingredientNetto) || 0);
  }, 0);

  return (
    <div className={styles.popupWrapper}>
      <div className={styles.headerWrapper} />
      <div className={styles.swipeContent}>
        <div className={styles.content}>
          <TableWrapper
            sizeable={false}
            showPagination={false}
            headers={tableHeaderOptions}
            data={processTableData(newItems)}
            tableInPopup
          />
          <div className={styles.totalCountWrapper}>
            <span className={styles.totalNetto}>
              <FormattedMessage id="ingredient.editor.output" defaultMessage="Output:" />
              <span style={{ paddingLeft: 8, paddingRight: 2 }}>{` ${totalNetto.toFixed(2)}`}</span>
              <FormattedMessage id="ingredientCard.unit.kilogram" defaultMessage="g" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Body);
