import { getLocalizedString } from 'i18n/utils';

const INGREDIENT_NAME = 'ingredientName';
const STOCK = 'stock';
const INGREDIENT_CATEGORY_NAME = 'ingredientCategoryName';
const PRICE = 'ingredientUnitPrice';
const STORAGE_NAME = 'storageName';
const STOCK_LEFTOVER_VALUATION = 'stockLeftoverValuation';

export const TABLE_FORMAT = [
  {
    name: getLocalizedString('storage.table.data.name'),
    property: INGREDIENT_NAME,
    variant: ['left'],
    width: '25%',
  },
  {
    name: getLocalizedString('storage.table.data.ingredient-category'),
    property: INGREDIENT_CATEGORY_NAME,
    variant: ['left'],
    width: '10%',
  },
  // {
  //   name: getLocalizedString('storage.table.data.expireTimestamp'),
  //   property: EXPIRE_TIMESTAMP,
  //   variant: ['left'],
  //   width: '15%',
  // },
  {
    name: getLocalizedString('storage.dropdown.storage'),
    property: STORAGE_NAME,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('storage.table.data.unitPrice'),
    property: PRICE,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('storage.table.data.stock'),
    property: STOCK,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('supply-record.data.total'),
    property: STOCK_LEFTOVER_VALUATION,
    variant: ['left'],
  },
];

export const CSVHeader = [
  {
    label: getLocalizedString('storage.table.data.name'),
    key: 'newIngredientName',
  },
  {
    label: getLocalizedString('storage.table.data.ingredient-category'),
    key: INGREDIENT_CATEGORY_NAME,
  },
  // {
  //   label: getLocalizedString('storage.table.data.expireTimestamp'),
  //   key: EXPIRE_TIMESTAMP,
  // },
  {
    label: getLocalizedString('storage.dropdown.storage'),
    key: STORAGE_NAME,
  },
  {
    label: getLocalizedString('storage.table.data.unitPrice'),
    key: PRICE,
  },
  {
    label: getLocalizedString('storage.table.data.stock'),
    key: 'newStock',
  },
  {
    label: getLocalizedString('supply-record.data.total'),
    key: 'stockLeftoverValuation',
  },
];
