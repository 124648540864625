import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import ErrorBoundary from 'components/common/error-boundary';
import { CalendarWrapper } from './style';
import styles from './styles.scss';

const MaterialDatePicker = ({
  label,
  date,
  fluid,
  selectDate,
  disableBefore,
  disableAfter,
  disabled,
  format,
}) => {
  return (
    <ErrorBoundary>
      <CalendarWrapper fluid={fluid}>
        <DatePicker
          label={label}
          id="date-picker-inline"
          format={format}
          disabled={disabled}
          value={new Date(date)}
          onChange={selectDate}
          minDate={new Date(disableBefore)}
          maxDate={new Date(disableAfter)}
        />
        <span
          className={styles.calendar}
          onClick={() => document.getElementById('date-picker-inline').click()}
        />
      </CalendarWrapper>
    </ErrorBoundary>
  );
};

MaterialDatePicker.propTypes = {
  date: PropTypes.number,
  selectDate: PropTypes.func.isRequired,
  label: PropTypes.node,
  disableBefore: PropTypes.any,
  disableAfter: PropTypes.any,
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  format: PropTypes.string,
};

MaterialDatePicker.defaultProps = {
  label: '',
  date: new Date(),
  disabled: false,
  disableBefore: moment().subtract(100, 'years'),
  disableAfter: moment().add(100, 'years'),
  fluid: false,
  format: 'DD-MM-YYYY',
};

export default MaterialDatePicker;
