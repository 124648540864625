import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/ingredient';

class IngredientAPI {
  static list = ({ page = 0, size = 20, name = '', type = '', catIds }) => {
    const newName = name.length ? `&name=${name}` : '';
    const newType = type === '' ? '' : `&${[type]}=true`;
    const newCatIds = !catIds ? '' : `&catIds=${catIds}`;
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${newName}${newType}${newCatIds}`);
  };

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static getAudit = id => Request.GET(`${ENDPOINT}/${id}/audit`);

  static getAuditByParty = (id, expTime, price) =>
    Request.GET(`${ENDPOINT}/${id}/audit?expTime=${expTime}&price=${price}`);

  static update = (id, ingredientData) => Request.PUT(`${ENDPOINT}`, ingredientData);

  static create = ingredientData => Request.POST(ENDPOINT, ingredientData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);

  static fetchStockInfo = ids => Request.POST(`${ENDPOINT}/stock`, ids);

  static getIngredientRecipeAudit = recipeId => Request.GET(`${ENDPOINT}-recipe-audit/${recipeId}`);

  static getIngredientsInProducts = ingredientIds =>
    Request.POST(`${ENDPOINT}/product`, ingredientIds);

  static uploadFile = file => {
    const data = new FormData();
    data.append('file', file);

    return Request.POST_FILE(`/supply/import/file`, data);
  };
}

export default IngredientAPI;
