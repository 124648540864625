import styled from 'styled-components';

import Calendar from 'images/calendar.svg';

const greyColor = '#3f3f4b';

export const WrapperRangePicker = styled('div')`
  display: flex;
  flex-direction: column;

  .rs-picker-toggle-clean {
    top: 7px !important;
  }

  .rs-picker-toggle-placeholder {
    cursor: pointer;
    width: 100%;
    height: 34px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    color: ${greyColor};
    cursor: pointer;
  }

  .rs-picker-toggle {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .rs-picker-daterange .rs-picker-toggle-caret::before {
    content: '' !important;
    background-image: url(${Calendar});
    background-size: 20px 18px;
    display: block;
    width: 20px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .rs-picker-toggle-caret {
    top: calc(50% + -1px) !important;
    transform: translateY(-50%);
    right: 5px;
  }

  .rs-picker-toggle {
    transition: 0.2s border-color ease;
  }

  .rs-picker-toggle:hover {
    transition: 0.2s border-color ease;
  }

  .rs-picker-menu,
  .rs-calendar,
  .rs-calendar-table-header-row,
  .rs-calendar-table-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
`;

export const Label = styled('label')`
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
`;
