import React from 'react';
import { bool, func } from 'prop-types';
import { Modal } from 'semantic-ui-react';
import ErrorBoundary from 'components/common/error-boundary';

import styles from './styles.scss';

export const PreviewImageModal = ({ open, closeModal, image }) => {
  return (
    <ErrorBoundary>
      <Modal open={open} onClose={closeModal} className="popupModal" size="small" closeIcon>
        <Modal.Content>
          <Modal.Description>
            <div className={styles.content}>
              <img src={image} alt="logo" width="100%" />
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </ErrorBoundary>
  );
};

PreviewImageModal.defaultProps = {};

PreviewImageModal.propTypes = {
  open: bool.isRequired,
  closeModal: func.isRequired,
};
