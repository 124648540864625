import React, { useEffect, useState } from 'react';
import { Accordion, Icon, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const AccordionWrapper = ({ children, title, active }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <Accordion as={Menu} vertical className={styles.accordion}>
      <Menu.Item>
        <Accordion.Title
          className={styles.accordionTitle}
          active={isActive}
          onClick={() => setIsActive(!isActive)}
        >
          <Icon name="dropdown" />
          {!isActive ? title : ''}
        </Accordion.Title>
        <Accordion.Content active={isActive}>{children}</Accordion.Content>
      </Menu.Item>
    </Accordion>
  );
};

AccordionWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

AccordionWrapper.defaultProps = {
  active: false,
};

export default AccordionWrapper;
