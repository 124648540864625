import React from 'react';
import { Modal as SemanticModal, Button } from 'semantic-ui-react';
import closeIcon from 'images/close-dark.svg';
import PropTypes from 'prop-types';
import {
  ButtonWrapper,
  CloseIcon,
  DropdownWrapper,
  HeaderTitle,
  HeaderWrapper,
  ModalWrapper,
} from './styles';

const Modal = ({
  headerTitle,
  onClose,
  buttonOnClick = onClose,
  buttonText = 'OK',
  children,
  width,
  open,
  size = 'tiny',
  minHeight,
}) => {
  const styles = {
    minHeight: minHeight ? minHeight : '270',
    borderRadius: 12,
    width: width ? width : null,
  };
  return (
    <SemanticModal
      open={open}
      style={styles}
      size={size}
      closeOnDimmerClick={false}
      onClose={onClose}
    >
      <ModalWrapper>
        <div>
          <HeaderWrapper>
            <HeaderTitle>{headerTitle}</HeaderTitle>
            <CloseIcon src={closeIcon} alt="close" onClick={onClose} />
          </HeaderWrapper>
          <DropdownWrapper>{children}</DropdownWrapper>
        </div>
        <ButtonWrapper>
          <Button
            size="big"
            primary
            onClick={() => buttonOnClick(() => onClose())}
            circular={false}
            style={{ padding: 16, height: 48, fontSize: 16, fontWeight: 500 }}
          >
            {buttonText}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </SemanticModal>
  );
};

Modal.propTypes = {
  headerTitle: PropTypes.string,
  onClose: PropTypes.func,
  buttonOnClick: PropTypes.func,
  buttonText: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default Modal;
