import AccountAPI from 'api/account';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import { getLocalizedString } from 'i18n/utils';

const NAME = 'name';
const TYPE = 'type';
const ACCOUNT_NUMBER = 'accountNumber';
const CURRENCY = 'currency';

export const ACCOUNTS_TABLE_FORMAT = [
  {
    name: getLocalizedString('account.data.name'),
    property: NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('account.data.type'),
    property: TYPE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('account.data.accountNumber'),
    property: ACCOUNT_NUMBER,
    variant: ['left'],
  },
  {
    name: getLocalizedString('account.data.currency'),
    property: CURRENCY,
    variant: ['left'],
  },
];

class AccountStore {
  tableFormat = ACCOUNTS_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  fetchData = async () => {
    const { page, size } = this.paginateData;
    const { items, last, totalPages, totalElements } = await AccountAPI.list(page, size);

    runInAction(() => {
      this.data = items;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };
      this.loading = false;
    });
  };

  @action
  deleteAccount = async id => {
    try {
      await AccountAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
    runInAction(() => {
      this.data = this.data.filter(i => i.id !== id);
    });
  };
}

export default AccountStore;
