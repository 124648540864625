import styled from 'styled-components';

/* eslint-disable */
export const RangeWrapper = styled.div`
  display: flex;
  margin-bottom: 1em;
  margin-right: 16px;
`;

export const RangeFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 12px 20px 0;
  margin-top: 20px;
`;

export const DownloadButtonWrapper = styled.div``;

export const Label = styled.label`
  font-family: Roboto, Helvetica, san-serif;
  font-size: 14px;
  font-weight: bold;
  color: #3f3f4b;
  display: inline-block;
  line-height: 2.14;
`;

export const Input = styled.div`
  cursor: pointer;
  text-align: right;
  padding-left: 8px;
  padding-right: 24px;
  text-align-last: right;
`;

export const PaginateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: -14px;
  background: #fff;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 99999;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.05);
`;
