import { action, observable, runInAction } from 'mobx';
import PackingAPI from 'api/packing';
import SupplyRecordAPI from 'api/supply-record';
import history, { routes } from 'routes';
import DictionaryAPI from 'api/dictionary';
import SupplierAPI from 'api/supplier';
import IngredientAPI from 'api/ingredient';
import AccountAPI from 'api/account';
import StorageAPI from 'api/storage';
import ImagesAPI from 'api/images';
import filter from 'lodash/filter';
import moment from 'moment';
import { toFixedFive, toFixedTwo } from 'utils/numbers';
import { SUPPLY, MOVE, CURRENCY_CODE_TO_SIGN, CAST, DISPOSAL } from 'utils/constants';
import { getLocalizedString } from 'i18n/utils';
import validate from 'validate.js';
import { CSVHeaders, returnDataForCSV } from './structured-csv-data';
import options from './reason-options';

const INIT_SUPPLY_ITEMS = [];

let initSupplyRecord = {
  id: 0,
  accountId: '',
  accountName: '',
  supplierId: '',
  supplierName: '',
  fromStorageId: '',
  fromStorageName: '',
  toStorageId: '',
  toStorageName: '',
  comment: '',
  reason: '',
  attachment:
    'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png',
  businessTimestamp: 1,
  storageRecordType: 'SUPPLY',
  supplyItems: INIT_SUPPLY_ITEMS,
  taxPct: 0,
  startOfDaySupply: false,
};

const VALIDATION_SCHEMA_SUPPLY = {
  toStorageId: { numericality: true },
};

const VALIDATION_SCHEMA_MOVE = {
  fromStorageId: { numericality: true },
  toStorageId: { numericality: true },
};

const VALIDATION_SCHEMA_DISPOSAL = {
  fromStorageId: { numericality: true },
  reason: { presence: { allowEmpty: false } },
};

const VALIDATION_SCHEMA_CAST = {
  toStorageId: { numericality: true },
};

const START_DAY = true;
const END_DAY = false;

const SUPPLY_TIME_TYPE_LIST = [
  {
    name: START_DAY,
    label: getLocalizedString('supply-record.data.time.start'),
  },
  {
    name: END_DAY,
    label: getLocalizedString('supply-record.data.time.end'),
  },
];

class Editor {
  constructor(supplierRecordStore, MerchantsGroup) {
    this.merchantsGroup = MerchantsGroup;
    this.supplierRecordStore = supplierRecordStore;
  }
  @observable supplyRecord = initSupplyRecord;
  @observable recordTypeList = [];
  @observable accountList = [];
  @observable ingredientsOptions = [];
  @observable supplierList = [];
  @observable storageList = [];
  @observable attachment = '';
  @observable reasonOptions = options;
  @observable validationErrors = {};
  @observable validationSupplyErrors = {};
  @observable ingredientsAllData = [];
  @observable searchIngredientsAllData = [];
  @observable ingredientsData = [];
  @observable searchIngredientsData = [];
  @observable imageURL = '';
  @observable currency = CURRENCY_CODE_TO_SIGN[this.merchantsGroup.info.currency];
  @observable totalValues = CURRENCY_CODE_TO_SIGN[this.merchantsGroup.info.currency];
  @observable headersForCSV = CSVHeaders;
  @observable supplyItemsTotalValue = 0;
  @observable packOptions = [];
  @observable supplyTimeTypeList = SUPPLY_TIME_TYPE_LIST;

  @action
  create = path => {
    history.push(path);
  };

  @action
  getDataForCSV = () => {
    return returnDataForCSV(this.supplyRecord);
  };

  @action
  loadSuppliers = async () => {
    const { items } = await SupplierAPI.list();
    runInAction(() => {
      this.supplierList = [
        { value: 0, text: getLocalizedString('supply-record.data.without-supplier'), key: 0 },
        ...items.map(item => ({
          value: item.id,
          text: item.name,
          key: item.id,
        })),
      ];
    });
  };

  @action
  loadAccounts = async () => {
    const { items } = await AccountAPI.list();
    runInAction(() => {
      this.accountList = items.map(item => ({
        value: item.id,
        text: item.name,
        key: item.id,
      }));
    });
  };

  @action
  loadSuppliersOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await SupplierAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
      runInAction(() => {
        this.supplierList = page === 0 ? items : [...this.supplierList, ...items];
      });
    } else {
      const items = filter(this.supplierList, item => {
        return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
      filteredOptions = [...items];
    }

    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadAccountsOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await AccountAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
      runInAction(() => {
        this.accountList = page === 0 ? items : [...this.accountList, ...items];
      });
    } else {
      const items = filter(this.accountList, item => {
        return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
      filteredOptions = [...items];
    }

    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadOptionsAllIngredients = async ({ search, prevOptions, page = 0, type }) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (!search) {
      const { items, last } = await IngredientAPI.list({ page, size: 20, type });
      filteredOptions = items;
      newItems = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list({
        page: 0,
        size: 20,
        name: search,
        type,
      });
      filteredOptions = [...items];
    }
    runInAction(() => {
      if (!search) {
        this.ingredientsAllData =
          page === 0 ? [...newItems] : [...this.ingredientsAllData, ...newItems];
      } else {
        this.searchIngredientsAllData = filteredOptions;
      }
    });
    const slicedOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadPackOptions = async () => {
    try {
      const { items } = await PackingAPI.list(0, 1000);
      runInAction(() => {
        this.packOptions = items.map(i => ({
          text: `${i.name}(${i.amount}${getLocalizedString(
            `ingredient.unit.${(i.unit || '').toLowerCase()}`,
          )})`,
          value: i.id,
          key: i.id,
          amount: i.amount,
        }));
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  processData = data => {
    return data.map((i, index) => {
      const stock = `(${i.stock ? toFixedTwo(i.stock) : '0'}${' '}${getLocalizedString(
        `ingredient.unit.${i.ingredientUnit.toLowerCase()}`,
      )})${' '}`;
      const price = `(${toFixedTwo(i.ingredientUnitPrice)}${this.currency})${' '}`;
      const expireDate = `до ${moment(i.ingredientExpireTimestamp).format('HH:mm DD MMM YYYY')}`;

      return {
        ...i,
        label: `${i.ingredientName}${' '}${stock}${i.ingredientUnitPrice !== '0.00' ? price : ''}${
          i.ingredientExpireTimestamp < 1 ? '' : expireDate
        }`,
        value: `${i.ingredientId}_${i.stock}_${i.ingredientExpireTimestamp || 0}_${
          i.ingredientUnitPrice
        }`,
        index: index,
        key: i.ingredientId,
        defaultStock: i.stock ? toFixedTwo(i.stock) : '0',
      };
    });
  };

  @action
  loadOptions = async (search, prevOptions, page = 0, storageId) => {
    let filteredOptions = [];
    let hasMore = false;
    let newItems = [];
    if (storageId) {
      if (!search) {
        const { items, last } = await StorageAPI.fetchIngredients(storageId, page, 20, '');
        filteredOptions = items;
        newItems = items;
        hasMore = !last;
      } else {
        const { items } = await StorageAPI.fetchIngredients(storageId, page, 20, search);
        filteredOptions = [...items];
      }
      runInAction(() => {
        if (!search) {
          this.ingredientsData =
            page === 0
              ? [...this.processData(newItems)]
              : [...this.ingredientsData, ...this.processData(newItems)];
        } else {
          this.searchIngredientsData = this.processData(filteredOptions);
        }
      });
    }
    const slicedOptions = this.processData(filteredOptions);

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  setEditIngredientsOptions = async arr => {
    runInAction(() => {
      this.ingredientsOptions = arr.map((i, index) => {
        const price = `(${toFixedTwo(i.ingredientUnitPrice)}${this.currency})${' '}`;
        const expireDate = `до ${moment(i.ingredientExpireTimestamp).format('HH:mm DD MMM YYYY')}`;

        return {
          ...i,
          text: `${i.ingredientName}${' '}${i.ingredientUnitPrice !== '0.00' ? price : ''}${
            i.ingredientExpireTimestamp === 0 ? '' : expireDate
          }`,

          expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
          value: `${i.ingredientId}_${index}`,
          index: index,
          key: i.ingredientId,
          defaultStock: i.stock ? toFixedTwo(i.stock) : '0',
        };
      });
    });
  };

  @action
  changeSupplyRecord = (key, value) => {
    if (Object.values(this.validationSupplyErrors).length > 0) {
      this.validationSupplyErrors = {};
    }
    runInAction(() => {
      this.supplyRecord[key] = value;
      if (key === 'supplyItems') {
        this.supplyItemsTotalValue = value.reduce((acc, item) => {
          return (acc += Number(item.total) || 0);
        }, 0);
      }
    });
  };

  @action
  changeInitSupplyRecord = (key, value) => {
    runInAction(() => {
      this.supplyRecord[key] = value;
    });
  };

  @action
  loadTypes = async () => {
    const { items } = await DictionaryAPI.listSupplyRecordTypes();
    runInAction(() => {
      this.recordTypeList = items.map(item => ({
        name: item.name,
        label: getLocalizedString(`supply-record.data.${item.name}`),
      }));
    });
  };

  @action
  validationSupplyItems = items => {
    if (items.length === 0) {
      throw new Error(getLocalizedString('supply-record.data.add-field.error-message'));
    }
    let validationErrors = {};
    items.map((item, index) => {
      if (validate(item, { ingredientId: { presence: { allowEmpty: false } } })) {
        validationErrors = { ...validationErrors, [`ingredientId.${index}`]: true };
      }
      if (
        validate(item, { ingredientAmount: { presence: { allowEmpty: false } } }) &&
        !item.ingredientPackSupplyAmount
      ) {
        validationErrors = { ...validationErrors, [`ingredientAmount.${index}`]: true };
      }
      if (!item.ingredientUnit) {
        validationErrors = { ...validationErrors, [`ingredientUnit.${index}`]: true };
      }

      if (
        this.supplyRecord.storageRecordType !== MOVE &&
        this.supplyRecord.storageRecordType !== CAST
      ) {
        if (validate(item, { ingredientUnitPrice: { presence: { allowEmpty: false } } })) {
          validationErrors = { ...validationErrors, [`ingredientUnitPrice.${index}`]: true };
        }
      }
    });
    runInAction(() => {
      this.validationSupplyErrors = validationErrors;
    });
  };

  @action
  loadStorages = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storageList = items.map(item => ({
        value: item.id,
        text: item.name,
        key: item.id,
      }));
    });
  };

  @action
  edit = async (id, edit) => {
    if (edit) {
      const data = await SupplyRecordAPI.get(id);

      runInAction(() => {
        this.supplyItemsTotalValue = data.totalAmount || 0;
        if (data.storageRecordType === CAST) {
          this.supplyRecord = {
            ...data,
            businessTimestamp: moment(data.businessTimestamp),
            supplyItems: data.supplyItems.map(i => ({
              ...i,
              total: i.ingredientUnitPrice * i.ingredientAmount || 0,
              expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
            })),
          };
        } else if (data.storageRecordType !== SUPPLY) {
          this.supplyRecord = {
            ...data,
            businessTimestamp: moment(data.businessTimestamp),
            supplyItems: data.supplyItems.map((i, index) => ({
              ...i,
              ingredientId: `${i.ingredientId}_${index}`,
              total: i.ingredientUnitPrice * i.ingredientAmount || 0,
              expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
            })),
          };
          this.setEditIngredientsOptions(data.supplyItems);
        } else {
          this.supplyRecord = {
            ...data,
            businessTimestamp: moment(data.businessTimestamp),
            supplyItems: data.supplyItems.map(i => {
              const newIngredientUnitPrice = data.taxPct
                ? Number(i.ingredientUnitPrice / (data.taxPct / 100 + 1))
                : i.ingredientUnitPrice;
              return {
                ...i,
                ingredientUnitPrice: toFixedFive(newIngredientUnitPrice),
                total: Number(i.ingredientAmount) * Number(i.ingredientUnitPrice),
                expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
              };
            }),
          };
        }
      });
    } else {
      runInAction(() => {
        const item = this.supplierRecordStore.data.find(item => item.id == id);
        if (item) {
          this.supplyRecord = {
            ...item,
            supplyItems: item.supplyItems.map(i => ({
              expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
            })),
          };
        }
      });
    }
  };

  @action
  onDuplicate = async id => {
    const data = await SupplyRecordAPI.get(id);
    runInAction(() => {
      this.supplyItemsTotalValue = data.totalAmount || 0;
      this.supplyRecord = {
        ...data,
        businessTimestamp: new Date(),
        attachment: '',
        supplyItems: data.supplyItems.map(i => {
          const newIngredientUnitPrice = data.taxPct
            ? Number(i.ingredientUnitPrice / (data.taxPct / 100 + 1))
            : i.ingredientUnitPrice;
          return {
            ...i,
            ingredientUnitPrice: toFixedFive(newIngredientUnitPrice),
            total: Number(i.ingredientAmount) * Number(i.ingredientUnitPrice),
            expireTimestamp: i.ingredientExpireTimestamp || i.expireTimestamp,
          };
        }),
      };
    });
  };

  @action
  onSubmit = async (supplyRecord, { isEdit }) => {
    if (isEdit) {
      await SupplyRecordAPI.update(supplyRecord);
    } else {
      await SupplyRecordAPI.create(supplyRecord);
    }
  };

  @action
  updateImage = ({ image }) => {
    runInAction(() => {
      this.attachment = image;
    });
  };

  @action
  uploadImage = async () => {
    try {
      const img = await ImagesAPI.upload(this.attachment);
      runInAction(() => {
        this.imageURL = img.url;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  save = async ({ admin, isEdit }) => {
    const newSupplyRecord = {
      ...this.supplyRecord,
      businessTimestamp:
        this.supplyRecord.businessTimestamp === 1
          ? moment(new Date()).valueOf()
          : moment(this.supplyRecord.businessTimestamp).valueOf(),
      supplyItems: this.supplyRecord.supplyItems.map(i => {
        return {
          ...i,
          expireTimestamp: i.expireTimestamp ? moment(i.expireTimestamp).valueOf() : 0,
          ingredientId:
            typeof i.ingredientId === 'string' ? i.ingredientId.split('_')[0] : i.ingredientId,
        };
      }),
    };
    let supply = {};
    if (this.attachment) {
      await this.uploadImage();
    }

    if (newSupplyRecord.storageRecordType === SUPPLY) {
      runInAction(() => {
        this.validationErrors = validate(newSupplyRecord, VALIDATION_SCHEMA_SUPPLY) || {};
      });
      if (newSupplyRecord.supplyItems.length > 0) {
        this.validationSupplyItems(newSupplyRecord.supplyItems);
      }

      supply = {
        ...newSupplyRecord,
        attachment: this.imageURL,
        supplyItems: newSupplyRecord.supplyItems.map(i => {
          const newIngredientUnitPrice = newSupplyRecord.taxPct
            ? Number(i.ingredientUnitPrice * (newSupplyRecord.taxPct / 100 + 1))
            : Number(i.ingredientUnitPrice);

          let newItem = {
            ...i,
            ingredientAmount: i.ingredientPackId
              ? i.ingredientPackSupplyAmount * i.ingredientPackAmount
              : i.ingredientAmount,
            ingredientUnitPrice: i.ingredientPackId
              ? Number(newIngredientUnitPrice) / Number(i.ingredientPackAmount)
              : newIngredientUnitPrice,
          };
          if (!i.ingredientPackId) {
            delete newItem.ingredientPackAmount;
            delete newItem.ingredientPackId;
            delete newItem.ingredientPackName;
            delete newItem.ingredientPackSupplyAmount;
          }
          return newItem;
        }),
      };
    } else if (newSupplyRecord.storageRecordType === MOVE) {
      supply = {
        ...newSupplyRecord,
        attachment: this.imageURL,
      };

      runInAction(() => {
        this.validationErrors = validate(supply, VALIDATION_SCHEMA_MOVE) || {};
      });
      this.validationSupplyItems(supply.supplyItems);
    } else if (newSupplyRecord.storageRecordType === DISPOSAL) {
      supply = {
        ...newSupplyRecord,
        attachment: this.imageURL,
      };
      runInAction(() => {
        this.validationErrors = validate(supply, VALIDATION_SCHEMA_DISPOSAL) || {};
      });

      this.validationSupplyItems(supply.supplyItems);
    } else {
      supply = {
        ...newSupplyRecord,
        attachment: this.imageURL,
        supplyItems: newSupplyRecord.supplyItems.map(i => ({
          ...i,
          createdTimestamp: i.createdTimestamp > 0 ? i.createdTimestamp : Date.parse(new Date()),
          ingredientUnitPrice: i.ingredientUnitPrice === '' ? 0 : i.ingredientUnitPrice,
        })),
      };
      runInAction(() => {
        this.validationErrors = validate(supply, VALIDATION_SCHEMA_CAST) || {};
      });

      this.validationSupplyItems(supply.supplyItems);
    }

    if (
      Object.keys(this.validationErrors).length === 0 &&
      Object.keys(this.validationSupplyErrors).length === 0
    ) {
      await this.onSubmit({ ...supply }, { isEdit }).then(() => {
        history.push(routes.supplyRecord);
      });
    }
  };

  @action
  clearSupplyRecordValidation = () => {
    this.validationErrors = {};
    this.validationSupplyErrors = {};
    this.supplyRecord = {
      ...initSupplyRecord,
      storageRecordType: this.supplyRecord.storageRecordType,
    };
    this.attachment = '';
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.validationSupplyErrors = {};
    this.supplyRecord = initSupplyRecord;
    this.attachment = '';
    this.supplyItemsTotalValue = 0;
  };
}

export default Editor;
