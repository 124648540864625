export const processTableData = data => {
  return data.map(item => {
    let obj = {};
    for (let i in item) {
      if (item[i] === '' && item[i] !== 0) {
        obj[i] = '-';
      } else {
        obj[i] = item[i];
      }
    }
    return obj;
  });
};
