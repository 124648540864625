import moment from 'moment';
import { getLocalizedString } from '../../../i18n/utils';
import { toFixedFive } from '../../../utils/numbers';

export const CSVHeaders = [
  { label: getLocalizedString('supply-record.data.ingredient'), key: 'ingredientName' },
  { label: getLocalizedString('supply-record.data.amount'), key: 'ingredientAmount' },
  { label: getLocalizedString('ingredient.data.unit'), key: 'ingredientUnit' },
  { label: getLocalizedString('ingredient.data.expireTimestamp'), key: 'expireTimestamp' },
  { label: getLocalizedString('ingredient.data.unitPrice'), key: 'total' },
  { label: getLocalizedString('supply-record.data.reason'), key: 'reason' },
  { label: getLocalizedString('inventory.modal.table.fromStorage'), key: 'fromStorageName' },
  {
    label: getLocalizedString('supply-record.data.business-timestamp'),
    key: 'businessTimestamp',
  },
  {
    label: getLocalizedString('supply-record.data.comment'),
    key: 'comment',
  },
  {
    label: getLocalizedString('supply-record.data.image'),
    key: 'attachment',
  },
  {
    label: getLocalizedString('supply-record.data.total'),
    key: 'supplyItemsTotalValue',
  },
];

export const returnDataForCSV = supplyRecord => {
  const obj = { ...supplyRecord };
  return [
    ...supplyRecord.supplyItems.map(item => {
      return {
        ingredientName: item.ingredientName,
        ingredientAmount: item.ingredientAmount,
        ingredientUnit: getLocalizedString(
          `ingredient.unit.${item.ingredientUnit || ''.toLowerCase()}`,
        ),
        expireTimestamp: item.expireTimestamp
          ? moment(item.expireTimestamp).format('hh:mm DD MMM YYYY')
          : '',
        total: toFixedFive(item.total),
      };
    }),
    {
      reason: obj.reason,
      fromStorageName: obj.fromStorageName,
      businessTimestamp: obj.businessTimestamp
        ? moment(obj.businessTimestamp).format('hh:mm DD MMM YYYY')
        : '',
      comment: obj.comment,
      attachment: obj.attachment,
      supplyItemsTotalValue: obj.totalAmount,
    },
  ];
};
