import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #000;
  line-height: 1.67;
  margin-bottom: 10px;
`;

export const General = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid #e7e7e7;

  @media (min-width: 1136px) {
    padding: 16px 8px;
  }
`;
