import { action, observable, runInAction } from 'mobx';
import SupplierAPI from 'api/supplier';
import history, { routes } from 'routes';

const initSupplier = {
  id: 0,
  name: '',
  taxId: '',
  taxPct: 0,
  mobile: '',
  email: '',
  contactPerson: '',
};

class Editor {
  constructor(supplierStore) {
    this.supplierStore = supplierStore;
  }
  @observable supplier = initSupplier;

  @action
  create = path => {
    history.push(path);
  };

  @action
  changeSupplier = (key, value) => {
    this.supplier[key] = value;
  };

  @action
  edit = async id => {
    runInAction(() => {
      const item = this.supplierStore.data.find(item => item.id == id);
      if (item) {
        this.supplier = { ...item };
      }
    });

    const data = await SupplierAPI.get(id);

    runInAction(() => {
      this.supplier = data;
    });
  };

  @action
  onSubmit = async (supplier, { isEdit }) => {
    if (isEdit) {
      await SupplierAPI.update(supplier.id, supplier);
    } else {
      await SupplierAPI.create(supplier);
    }
  };

  @action
  save = async ({ admin, isEdit, merchantGroupId }) => {
    await this.onSubmit({ ...this.supplier, merchantGroupId }, { isEdit });
    history.push(routes.supplier);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.supplier = initSupplier;
    this.conditionSelections = [];
  };
}

export default Editor;
