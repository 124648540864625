import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { inject, observer } from 'mobx-react';
import MomentUtils from '@date-io/moment';
import LoadingBar from 'components/common/loading-bar';
import { routes } from 'routes';
import Auth from 'scenes/Authentication';
import Notification from 'components/common/notification';
import Dashboard from 'scenes/Dashboard';
import ModalProvider from 'components/modal-provider';

const App = withRouter(
  inject('User')(
    observer(({ User }) => {
      return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <LoadingBar />
          <Notification />

          <Switch>
            <Route path={routes.auth} component={Auth} />
            <Route component={Dashboard} />
          </Switch>
          <ModalProvider />
        </MuiPickersUtilsProvider>
      );
    }),
  ),
);

export default App;
