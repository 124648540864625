import { getLocalizedString } from 'i18n/utils';

const reasonOptions = [
  'supply-record.data.reason.not-reason',
  'supply-record.data.reason.expired',
  'supply-record.data.reason.wasted',
  'supply-record.data.reason.supplementary-nutrition',
  'supply-record.data.reason.product-fight',
  'supply-record.data.reason.return-supplier',
  'supply-record.data.reason.menu-elaboration',
  'supply-record.data.reason.chef-compliment',
].map((reason, index) => ({
  value: getLocalizedString(reason),
  text: getLocalizedString(reason),
  key: index,
}));

export default reasonOptions;
