import StorageAPI from 'api/storage';
import { action, observable, runInAction } from 'mobx';
import InventoryAPI from 'api/inventory';
import IngredientCategoryAPI from 'api/ingredient-category';
import { getLocalizedString } from 'i18n/utils';
import { TABLE_FORMAT, CSVHeader } from './constants';

const initAllFilters = {
  storageFilters: [],
  categoryFilters: [],
  ingredientNameFilter: '',
};

class StorageStore {
  constructor(inventoryStore, AccountEditor) {
    this.inventoryStore = inventoryStore;
    this.accountEditor = AccountEditor;
  }
  @observable allFilters = initAllFilters;
  @observable ingredientsOptions = [];
  @observable loading = false;
  @observable fullTableFormat = TABLE_FORMAT;
  @observable headerForCSV = CSVHeader;
  @observable storageList = [];
  @observable storageItems = [];
  @observable totalStockLeftoverValuation = '';
  @observable ingredientAudit = { data: [], periodStartUtc: 0 };

  @action
  getItem = async id => {
    try {
      this.loading = true;
      const data = await InventoryAPI.getItem(id);
      runInAction(() => {
        this.inventory = data;
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  fetchIngredients = async () => {
    const newCategoryFilters = this.allFilters.categoryFilters.map(i => i.value);
    const newFilters = {
      ...this.allFilters,
      categoryFilters: newCategoryFilters,
    };
    try {
      this.loading = true;
      const {
        storageStockList,
        totalStockLeftoverValuation,
      } = await StorageAPI.fetchAllIngredients(newFilters);
      runInAction(() => {
        this.totalStockLeftoverValuation = totalStockLeftoverValuation;
        this.storageItems = storageStockList;
        this.loading = false;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  changeAllFilters = (key, value) => {
    if (key === 'storageFilters') {
      if (value[0] === 'all') {
        runInAction(() => {
          this.allFilters[key] = value.filter(i => i !== 'all');
        });
      } else if (value.some(i => i === 'all')) {
        this.allFilters[key] = [];
      } else {
        this.allFilters[key] = value;
      }
    } else if (key === 'categoryFilters') {
      if (value[0] && value[0].value === 'all') {
        runInAction(() => {
          this.allFilters[key] = value.filter(i => i.value !== 'all');
        });
      } else if (value[0] && value.some(i => i.value === 'all')) {
        this.allFilters[key] = [];
      } else {
        this.allFilters[key] = value;
      }
    } else {
      runInAction(() => {
        this.allFilters[key] = value;
      });
    }
    this.fetchIngredients();
  };

  @action
  loadStorages = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storageList = [
        { value: 'all', text: getLocalizedString('filters.all') },
        ...items.map(item => ({
          value: item.id,
          text: item.name,
          key: item.id,
        })),
      ];
    });
  };

  @action
  loadCategoryOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientCategoryAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientCategoryAPI.list(0, 20, search);
      filteredOptions = [...items];
    }

    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;

    return {
      options: slicedOptions,
      hasMore,
    };
  };
}

export default StorageStore;
