import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { Form, Dropdown } from 'semantic-ui-react';
import queryString from 'query-string';
import DateRangePicker from 'components/common/DateRangePicker';
import { AddButton } from 'components/common/buttons';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { routes } from 'routes';
import { getLocalizedString } from 'i18n/utils';
import { processTableData } from 'utils/process-table-data';

const Inventory = inject(
  'Inventory',
  'InventoryEditor',
  'Modals',
)(
  observer(({ Inventory, history, InventoryEditor, Modals }) => {
    const {
      tableFormat,
      data,
      fetchData,
      loading,
      changeAllFilters,
      allFilters,
      loadIngredientOptions,
      storagesOptions,
      loadStoragesOptions,
      // changePaginateData,
      paginateData,
      paginateOptions,
    } = Inventory;

    const { getDraft, inventoryDraft } = InventoryEditor;

    const { start, end, ingredientId, storageId } = allFilters;

    const [allQueryFilters, setAllQueryFilters] = useState(
      history.location.search
        ? JSON.parse(queryString.parse(history.location.search).allQueryFilters)
        : '',
    );

    const fetch = async () => {
      await loadStoragesOptions();
    };

    useEffect(() => {
      fetch();
    }, []);

    useEffect(() => {
      if (history.location.search) {
        setAllQueryFilters(JSON.parse(queryString.parse(history.location.search).allQueryFilters));
      }
    }, [history]);

    useEffect(() => {
      if (allQueryFilters) {
        changeAllFilters(allQueryFilters);
      }
    }, [allQueryFilters]);

    useEffect(() => {
      fetchData();
    }, []);

    // useEffect(() => {
    // changePaginateData({ ...paginateData, page: 0 });
    //   fetchData();
    //   InventoryEditor.getDraft();
    // }, [allFilters, paginateData]);

    const takeStock = () => history.push(`${routes.inventory}/new`);

    const editDraft = id => history.push(`${routes.inventory}/new/${id}`);

    const editItem = id => history.push(`${routes.inventory}/${id}`);

    const edit = (id, item) => {
      if (item.status === 'DRAFT') {
        editDraft(id);
      } else {
        editItem(id);
      }
    };

    const deleteItem = useCallback((id, item) => {
      Modals.show(undefined, { subject: item.storageName }, () => Inventory.deleteItem(id));
    }, []);

    const menu = [
      {
        name: <FormattedMessage id="menu.edit" defaultMessage="Edit" />,
        action: edit,
      },
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          orderNumber: item.orderNumber === 0 ? '-' : item.orderNumber,
          storageRecordType: (
            <FormattedMessage
              id={`supply-record.data.${item.storageRecordType}`}
              defaultMessage={item.storageRecordType}
            />
          ),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
          onRowClick: item.status === 'DRAFT' ? () => editDraft(item.id) : () => editItem(item.id),
        };
      });
    };

    const changeDate = dates => {
      if (dates) {
        changeAllFilters({ ...allFilters, start: dates[0], end: dates[1] });
      }
    };

    const newIngredientIdFilters = !ingredientId.value
      ? { value: 'all', label: getLocalizedString('filters.all') }
      : ingredientId;

    return (
      <div>
        <form className="ui form">
          <Form.Group widths="2">
            <Form.Field>
              <Form.Group widths="2">
                <Form.Field>
                  <FormattedMessage id="dateFilter.rangeCalendarFilter" defaultMessage="Date range">
                    {label => (
                      <DateRangePicker
                        label={label}
                        date={!start && !end ? [] : [start, end]}
                        selectDate={changeDate}
                        onClean={() => changeDate([undefined, undefined])}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="analytics.dropdown.storage" defaultMessage="Storage" />
                  </label>
                  <FormattedMessage
                    id="analytics.dropdown.storage.placeholder"
                    defaultMessage="Select storage"
                  >
                    {placeholder => (
                      <Dropdown
                        placeholder={placeholder}
                        fluid
                        search
                        selection
                        options={storagesOptions}
                        name="storageId"
                        value={storageId}
                        onChange={(event, { name, value }) =>
                          changeAllFilters({ ...allFilters, [name]: value })
                        }
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
              </Form.Group>
            </Form.Field>

            <Form.Field>
              <Form.Group widths="2">
                <Form.Field>
                  <label>
                    <FormattedMessage
                      id="supply-record.dropdown.ingredient"
                      defaultMessage="Ingredient"
                    />
                  </label>
                  <FormattedMessage
                    id="supply-record.dropdown.ingredient"
                    defaultMessage="Ingredient"
                  >
                    {placeholder => (
                      <AsyncDropdown
                        loadOptions={loadIngredientOptions}
                        placeholder={placeholder}
                        value={newIngredientIdFilters}
                        onChange={value => {
                          changeAllFilters({ ...allFilters, ingredientId: value });
                        }}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
              </Form.Group>
            </Form.Field>
          </Form.Group>
        </form>
        <div>
          <AddButton
            text={
              <FormattedMessage
                id="inventory.add.conduct-inventory"
                defaultMessage="Take stock"
                values={{ storageName: inventoryDraft.storageName }}
              />
            }
            onClick={takeStock}
          />
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateData={paginateData}
            paginateOptions={paginateOptions}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Inventory);
