import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Icon, Button } from 'semantic-ui-react';

import { ButtonWrapper } from './style';

const Back = ({ backClick, wrapper = true }) => {
  const onClick = () => {
    window.history.back();
    if (backClick) {
      backClick();
    }
  };

  const button = (
    <Button
      icon
      type="button"
      labelPosition="left"
      onClick={wrapper ? onClick : backClick}
      className="backButton"
    >
      <Icon name="left arrow" />
      <FormattedMessage id="buttons.back" defaultMessage="Back" />
    </Button>
  );

  if (wrapper) {
    return <ButtonWrapper>{button}</ButtonWrapper>;
  }

  return button;
};

Back.propTypes = {
  wrapper: PropTypes.bool,
  backClick: PropTypes.func,
};

export default Back;
