import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import App from './components/app';

const IS_LOCAL = process.env.CONFIG === 'local';

const renderApp = (stores, history, i18n) => {
  let Component = (
    <Provider {...stores}>
      <IntlProvider locale={i18n.language} messages={i18n.messages}>
        <Router history={history}>
          <Fragment>
            <App />
          </Fragment>
        </Router>
      </IntlProvider>
    </Provider>
  );

  // if (!IS_LOCAL) {
  //   Bugsnag.start({
  //     apiKey: 'a3fd27c90aa72f35b0d0d16be872377c',
  //     plugins: [new BugsnagPluginReact()],
  //   });
  //   const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  //   Component = <ErrorBoundary>{Component}</ErrorBoundary>;
  // }

  ReactDOM.render(Component, document.getElementById('root'));
};

export default renderApp;
