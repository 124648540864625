import { action, computed, observable, runInAction } from 'mobx';

import AuthAPI from 'api/auth';
import moment from 'moment';
import history, { routes } from 'routes';

import Intercom from 'thirdparty/Intercom';
import { isKybio } from 'utils/env';

const ADMIN = 'POS_ADMIN';
const MANAGER = 'POS_MANAGER';
// const RIDER = 'POS_RIDER';
const MARKETING_MANAGER = 'POS_MARKETING_MANAGER';
const NEW_USER_STATUS = 'NEW';
const INACTIVE_STATUS = 'INACTIVE';
const REDIRECT_RULE = 'redirect_rule';

class User {
  @observable info = {};
  @observable isUserFromPOS = false;

  resetPasswordEmailToken = null;

  @computed get isAuthorized() {
    if (this.info) {
      const { id, merchantId, role } = this.info;
      return !!(id && merchantId && role);
    } else {
      return true;
    }
  }

  @computed get hasOnePermission() {
    return this.info.permissionDtos.length === 1;
  }

  @computed get notConfirmed() {
    const { status } = this.info;

    return status && status === NEW_USER_STATUS;
  }

  @computed get isAdmin() {
    return this.info.role === ADMIN;
  }

  @computed get canEditCrm() {
    return this.info.role === ADMIN || this.info.role === MARKETING_MANAGER;
  }

  @computed get canLoadFetchGroupById() {
    return (
      this.info.role === ADMIN || this.info.role === MARKETING_MANAGER || this.info.role === MANAGER
    );
  }

  @computed get userInfo() {
    return this.info;
  }

  @action
  redirectUserBasedOnPermissions = (userInfo = {}) => {
    history.push(routes.supplyRecord);
  };

  @action
  setRegistrationInfo = registrationData => {
    if (registrationData) {
      runInAction(() => {
        this.info = registrationData;
      });
    }
  };

  @action
  authorizeUser = async () => {
    const userInfo = await AuthAPI.getUserInfo();

    if (!isKybio) {
      window.civchat = {
        apiKey: 'e5LcUV',
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        user_id: `${userInfo.id}`,
        email: `${userInfo.email}`,
        gender: '',
        status: `${userInfo.role}`,
        date_attr: moment().toISOString(),
        phone_number: `+${userInfo.mobile}`,
        score: 0,
        company: {
          name: '',
          company_id: `${userInfo.merchantGroupId}`,
          revenue: '',
        },
      };
    }

    runInAction(() => {
      this.info = {
        ...this.info,
        ...userInfo,
      };
    });
  };

  @action
  refreshToken = async () => {
    await AuthAPI.refreshTokenOnly();
  };

  refreshUserInfo = async () => {
    const userInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.info = userInfo;
    });
  };

  @action
  addPermission = async (name, merchantId) => {
    this.info.permissionDtos.push({ name, merchantId });

    runInAction(() => {
      this.info = { ...this.info };
    });
  };

  @action
  deletePermission = async merchantIdToDelete => {
    const permissions = this.info.permissionDtos.filter(
      ({ merchantId }) => merchantId !== merchantIdToDelete,
    );
    this.info.permissionDtos = permissions;

    runInAction(() => {
      this.info = { ...this.info };
    });

    return permissions;
  };

  setRedirectSignUpRule = route => {
    localStorage.setItem(REDIRECT_RULE, route);
  };

  getRedirectSignUpRule = () => {
    const redirect = localStorage.getItem(REDIRECT_RULE);
    localStorage.removeItem(REDIRECT_RULE);
    return redirect;
  };

  signUp = async () => {
    await this.authorizeUser();
    const redirect = this.getRedirectSignUpRule();

    if (redirect) {
      history.push(redirect);
    } else {
      history.push(`${routes.products}?freshUser`);
    }
  };

  @action
  confirmSignUp = async confirmationCode => {
    await AuthAPI.confirmRegistration(confirmationCode);
  };

  login = async (credentials, groupMerchantCallback) => {
    await AuthAPI.login(credentials);
    await this.authorizeUser(groupMerchantCallback);
  };

  @action
  autoLogin = async (token, fromPOS) => {
    AuthAPI.setToken(token);
    await this.authorizeUser();

    this.isUserFromPOS = fromPOS;

    history.push(routes.supplyRecord);
  };

  logout = () => {
    AuthAPI.logout();
    if (isKybio) {
      Intercom.shutDown();
    }
    runInAction(() => {
      this.info = {};
    });
    location.reload();
    history.replace(routes.login);
  };

  @action
  updateInfo = (key, value) => {
    this.info[key] = value;
  };

  resetPassword = async mobile => {
    try {
      await AuthAPI.resetPassword(mobile);

      history.push(routes.setNewPassword);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  resetConfirmationCode = async mobile => {
    try {
      await AuthAPI.resendConfirmationCode(mobile);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  @action
  checkUserCredentials = async credentials => {
    try {
      await AuthAPI.checkCredentials(credentials);
    } catch (e) {
      throw new Error(e.message);
    }
  };

  setResetPasswordInfo = (mobile, password) => {
    const info = {
      mobile,
      password,
    };
    localStorage.setItem('reset_password_info', JSON.stringify(info));
  };

  getResetPasswordInfo = () => {
    const info = localStorage.getItem('reset_password_info');
    return info ? JSON.parse(info) : {};
  };

  @action
  createNewPassword = async body => {
    await AuthAPI.createNewPassword(body);
    await this.authorizeUser();

    history.push(routes.supplyRecord);
  };
}

export default User;
