import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import { PanelWrapper } from './style';

const BackPanel = withRouter(({ history }) => {
  const onClick = () => history.goBack();

  return (
    <PanelWrapper>
      <Icon
        name='angle left'
        onClick={onClick}
        size="big"
      />
    </PanelWrapper>
  );
});

export default BackPanel;