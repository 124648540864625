import React, { useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import Switcher from 'components/common/switcher';
import { CURRENCY_CODE_TO_SIGN, HALF_STUFF } from 'utils/constants';
import autoSetOptionItem from 'utils/auto-set-option-item';
import Toggle from 'components/common/toggle';
import VersioningIngredientCard from 'components/common/versioning-ingredient-cards';
import { toFixedTwo } from 'utils/numbers';
import IngredientCard from './add-ingredient';
import styles from './styles.scss';

const General = inject(
  'IngredientEditor',
  'IngredientCategory',
  'IngredientCategoryEditor',
  'Dictionary',
  'MerchantsGroup',
)(
  observer(
    ({
      IngredientEditor,
      IngredientCategory,
      Dictionary,
      IngredientCategoryEditor,
      MerchantsGroup,
    }) => {
      const {
        ingredient,
        changeIngredient,
        changeProductType,
        productTypes,
        productType,
        ingredientsData,
        searchIngredientsData,
        loadIngredientOptions,
        changeIngredientRecipe,
        validationErrors,
        ingredientRecipeAudit,
        structuredIngredientRecipeItems,
        totalPrice,
      } = IngredientEditor;
      const { ingredientCategoriesAsOptions, fetchData } = IngredientCategory;

      const { currency } = MerchantsGroup.info;
      const formatedCurrency = CURRENCY_CODE_TO_SIGN[currency];

      const { loadIngredientUnits, ingredientUnitsIngredientCard, ingredientUnits } = Dictionary;

      const {
        name,
        unit,
        ingredientCategoryId,
        stockThreshold,
        unitWeight,
        approxPrice,
        wasteRatio,
        cookingRatio,
        fryingRatio,
        stewingRatio,
        roastingRatio,
        prepack,
      } = ingredient;

      useEffect(() => {
        loadIngredientUnits();
        fetchData({ page: 0, size: { size: 500 } });
      }, []);

      useEffect(() => {
        autoSetOptionItem(ingredientCategoriesAsOptions, 'ingredientCategoryId', changeIngredient);
      }, [ingredientCategoriesAsOptions.length]);

      const change = (_event, { name, value }) => {
        changeIngredient(name, value);
      };

      const changeValue = (_event, { name, value }, maxValue) => {
        if (value.toString().indexOf(',') !== -1 || value.toString().indexOf('.') !== -1) {
          if (maxValue && Number(value) > maxValue) {
            changeIngredient(name, maxValue);
          } else {
            changeIngredient(name, value);
          }
        } else if (value[0] == 0) {
          const newValue = value.toString().slice(1);
          if (maxValue && Number(newValue) > maxValue) {
            changeIngredient(name, maxValue);
          } else {
            changeIngredient(name, newValue);
          }
        } else {
          if (maxValue && Number(value) > maxValue) {
            changeIngredient(name, maxValue);
          } else {
            changeIngredient(name, value);
          }
        }
      };

      const handleAddCategory = useCallback(async (e, { value }) => {
        const { id } = await IngredientCategoryEditor.addCategory(value);
        changeIngredient('ingredientCategoryId', id);
      }, []);

      const unitValue = ingredientUnits;
      const newStockThreshold = stockThreshold === '' ? 0 : stockThreshold;

      const isHalfStaff = productType === HALF_STUFF;

      return (
        <div>
          <div className={styles.sectionWrapper}>
            <Form.Group widths="2">
              <Form.Field>
                <label>
                  <FormattedMessage id="account.data.type" defaultMessage="Type" />
                </label>
                <Form.Field>
                  <Switcher
                    onChange={changeProductType}
                    options={productTypes}
                    value={productType}
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
            {isHalfStaff && (
              <Toggle
                checked={!prepack}
                onChange={() => changeIngredient('prepack', !prepack)}
                description={
                  <FormattedMessage
                    id="ingredient.data.prepack.descriptions"
                    defaultMessage="By default, the bulk recipe product is deducted during the sale of associated products. In the 'Requires production' mode, the bulk recipe has to be explicitly produced (Transactions > Production) before it can be sold."
                  />
                }
                title={
                  <FormattedMessage id="ingredient.data.prepack.title" defaultMessage="Prepack" />
                }
              />
            )}
            <Form.Group widths="2">
              <Form.Field required error={validationErrors['name']}>
                <label>
                  <FormattedMessage id="ingredient.data.name" defaultMessage="Name" />
                </label>
                <FormattedMessage id="ingredient.data.name.placeholder" defaultMessage="Name...">
                  {placeholder => (
                    <Input
                      fluid
                      autoFocus
                      type="text"
                      name="name"
                      placeholder={placeholder}
                      maxLength={120}
                      value={name}
                      onChange={change}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field required error={validationErrors['unit']}>
                <label>
                  <FormattedMessage id="ingredient.data.unit" defaultMessage="Unit" />
                </label>
                <FormattedMessage
                  id="ingredient.data.unit.placeholder"
                  defaultMessage="Select unit"
                >
                  {placeholder => (
                    <Dropdown
                      placeholder={placeholder}
                      fluid
                      search
                      key={unitValue}
                      selection
                      options={unitValue}
                      name="unit"
                      value={unit}
                      onChange={change}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field>
                <label>
                  <FormattedMessage
                    id="ingredient.data.ingredient-category"
                    defaultMessage="Category"
                  />
                </label>
                <FormattedMessage
                  id="ingredient.data.ingredient-category.placeholder"
                  defaultMessage="Select ingredient category"
                >
                  {placeholder => (
                    <Dropdown
                      placeholder={placeholder}
                      fluid
                      search
                      selection
                      allowAdditions
                      options={ingredientCategoriesAsOptions}
                      name="ingredientCategoryId"
                      value={ingredientCategoryId}
                      onAddItem={handleAddCategory}
                      onChange={change}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage
                    id="ingredient.data.stockThreshold"
                    defaultMessage="Stock threshold"
                  />
                </label>
                <FormattedMessage
                  id="ingredient.data.stockThreshold.placeholder"
                  defaultMessage="Enter stock threshold..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="stockThreshold"
                      placeholder={placeholder}
                      value={newStockThreshold}
                      onChange={changeValue}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2">
              <Form.Field>
                <label>
                  <FormattedMessage
                    id="ingredient.data.approxPrice"
                    defaultMessage="Approx price"
                  />
                </label>
                <FormattedMessage
                  id="ingredient.data.approxPrice.placeholder"
                  defaultMessage="Enter approx price"
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="approxPrice"
                      placeholder={placeholder}
                      value={approxPrice ? toFixedTwo(approxPrice) : toFixedTwo(totalPrice)}
                      onChange={(e, { name, value }) => changeIngredient(name, value)}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              {(unit === 'LITER' || unit === 'PCS') && (
                <Form.Field>
                  <label>
                    <FormattedMessage
                      id="ingredient.data.unitWeight"
                      defaultMessage="Unit weight"
                    />
                  </label>
                  <FormattedMessage
                    id="ingredient.data.unitWeight.placeholder"
                    defaultMessage="Enter unit weight"
                  >
                    {placeholder => (
                      <Input
                        fluid
                        type="number"
                        name="unitWeight"
                        placeholder={placeholder}
                        value={unitWeight}
                        onChange={(e, { name, value }) => changeIngredient(name, value)}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group widths="5">
              <Form.Field>
                <label>
                  <FormattedMessage id="ingredient.data.wasteRatio" defaultMessage="Waste" />
                </label>
                <FormattedMessage
                  id="ingredient.data.wasteRatio.placeholder"
                  defaultMessage="Enter waste..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="wasteRatio"
                      placeholder={placeholder}
                      value={wasteRatio === '' ? 0 : toFixedTwo(wasteRatio)}
                      onChange={(_event, { name, value }) =>
                        changeValue(_event, { name, value: toFixedTwo(value) }, 100)
                      }
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="ingredient.data.cookingRatio" defaultMessage="Cooking" />
                </label>
                <FormattedMessage
                  id="ingredient.data.cookingRatio.placeholder"
                  defaultMessage="Enter cooking..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="cookingRatio"
                      placeholder={placeholder}
                      value={cookingRatio === '' ? 0 : toFixedTwo(cookingRatio)}
                      onChange={(_event, { name, value }) =>
                        changeValue(_event, { name, value: toFixedTwo(value) }, 100)
                      }
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="ingredient.data.fryingRatio" defaultMessage="Frying" />
                </label>
                <FormattedMessage
                  id="ingredient.data.fryingRatio.placeholder"
                  defaultMessage="Enter frying..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="fryingRatio"
                      placeholder={placeholder}
                      value={fryingRatio === '' ? 0 : toFixedTwo(fryingRatio)}
                      onChange={(_event, { name, value }) =>
                        changeValue(_event, { name, value: toFixedTwo(value) }, 100)
                      }
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="ingredient.data.stewingRatio" defaultMessage="Stewing" />
                </label>
                <FormattedMessage
                  id="ingredient.data.stewingRatio.placeholder"
                  defaultMessage="Enter stewing..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="stewingRatio"
                      placeholder={placeholder}
                      value={stewingRatio === '' ? 0 : toFixedTwo(stewingRatio)}
                      onChange={(_event, { name, value }) =>
                        changeValue(_event, { name, value: toFixedTwo(value) }, 100)
                      }
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="ingredient.data.roastingRatio" defaultMessage="Roasting" />
                </label>
                <FormattedMessage
                  id="ingredient.data.roastingRatio.placeholder"
                  defaultMessage="Enter roasting..."
                >
                  {placeholder => (
                    <Input
                      fluid
                      type="number"
                      name="roastingRatio"
                      placeholder={placeholder}
                      value={roastingRatio === '' ? 0 : toFixedTwo(roastingRatio)}
                      onChange={(_event, { name, value }) =>
                        changeValue(_event, { name, value: toFixedTwo(value) }, 100)
                      }
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form.Group>
          </div>

          {isHalfStaff && (
            <div style={{ marginBottom: 70 }}>
              <div className={styles.sectionTitle}>
                <FormattedMessage
                  id="ingredient.data.ingredient-card"
                  defaultMessage="Pre-cook card"
                />{' '}
              </div>
              <IngredientCard
                supplyItemstName="ingredientRecipeItems"
                supplyItems={
                  (ingredient.ingredientRecipeDto &&
                    ingredient.ingredientRecipeDto.ingredientRecipeItems) ||
                  []
                }
                changeSupplyRecord={changeIngredientRecipe}
                searchIngredientsData={searchIngredientsData}
                fetchIngredientsUnits={loadIngredientUnits}
                ingredientsUnitsOptions={ingredientUnitsIngredientCard}
                ingredientsData={ingredientsData}
                errors={validationErrors}
                loadOptions={loadIngredientOptions}
                currency={formatedCurrency}
                isHalfStaff={isHalfStaff}
              />

              {ingredientRecipeAudit && ingredientRecipeAudit.length > 1 && (
                <VersioningIngredientCard
                  auditList={ingredientRecipeAudit.items}
                  structuredData={structuredIngredientRecipeItems}
                  currency={formatedCurrency}
                />
              )}
            </div>
          )}
        </div>
      );
    },
  ),
);

export default General;
