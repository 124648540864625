import IngredientCategoryAPI from 'api/ingredient-category';
import { action, computed, observable, runInAction } from 'mobx/lib/mobx';
import { getLocalizedString } from 'i18n/utils';

const NAME = 'name';

export const INGREDIENT_CATEGORIES_TABLE_FORMAT = [
  {
    name: getLocalizedString('ingredient-category.data.name'),
    property: NAME,
    variant: ['left'],
  },
];

class IngredientStore {
  @observable tableFormat = INGREDIENT_CATEGORIES_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };
  @observable allFilters = {
    name: '',
  };

  @action
  changeAllFilters = value => {
    runInAction(() => {
      this.allFilters = { ...value };
    });
  };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  fetchData = async () => {
    const { page, size } = this.paginateData;
    const { name } = this.allFilters;
    const { items, last, totalPages, totalElements } = await IngredientCategoryAPI.list(
      page,
      size,
      name,
    );

    runInAction(() => {
      this.data = items;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };
      this.loading = false;
    });
  };

  @action
  deleteCategory = async id => {
    try {
      await IngredientCategoryAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
    runInAction(() => {
      this.data = this.data.filter(i => i.id !== id);
    });
  };

  @computed get ingredientCategoriesAsOptions() {
    const categoryArray = this.data.map(item => ({
      value: item.id,
      text: item.name,
      key: item.id,
    }));
    return [
      { value: 0, text: getLocalizedString('ingredient-category.without-category'), key: 0 },
      ...categoryArray,
    ];
  }
}

export default IngredientStore;
