import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';

const Spinner = ({ active, size, verticalAlign, style }) => (
  <Dimmer active={active} inverted verticalAlign={verticalAlign} style={style}>
    <Loader inverted size={size} />
  </Dimmer>
);

Spinner.defaultProps = {
  size: 'medium',
  verticalAlign: undefined,
};

Spinner.propTypes = {
  verticalAlign: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

export default Spinner;
