import React, { useEffect, useState, useCallback, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_BREAKPOINT } from 'utils/constants';
import useWindowWidthHook from 'utils/hooks/useWindowWidth';
import SearchResetInput from 'components/common/inputs/search-reset';
import throttle from 'lodash/throttle';
import { withRouter } from 'react-router-dom';
import { AddButton, UploadButton } from 'components/common/buttons';

import { routes } from 'routes';
import { Form } from 'semantic-ui-react';
import IngredientTable from './table';
import styles from './styles.scss';
import AsyncDropdown from '../common/dropdowns/async-dropdown';
import { getLocalizedString } from '../../i18n/utils';

const Ingredient = inject(
  'Ingredient',
  'Notification',
)(
  observer(({ Ingredient, Notification, history }) => {
    const {
      fetchData,
      deleteIngredient,
      loadStorages,
      newTableFormat,
      dropTableFormat,
      changeAllFilters,
      allFilters,
      paginateData,
      changePaginateData,
      loadCategoryOptions,
      uploadFile,
    } = Ingredient;

    const [name, setName] = useState('');
    const uploadFileRef = useRef(null);

    const fetch = async () => {
      await loadStorages();
    };

    const width = useWindowWidthHook();
    const isMobile = width < MOBILE_BREAKPOINT;

    useEffect(() => {
      fetch();
      setName(allFilters.name);
    }, []);

    useEffect(() => {
      fetchData();
    }, [allFilters, paginateData]);

    const applyFilter = (name, value) => {
      if (value.length > 2) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters(name, value);
      } else if (value.length === 0) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters(name, value);
      }
    };

    const delayedCallback = useCallback(
      throttle((name, value) => applyFilter(name, value), 2000),
      [paginateData, allFilters],
    );

    const onChange = (name, value) => {
      setName(value);
      delayedCallback(name, value);
    };

    const addIngredient = () => history.push(`${routes.ingredient}/new`);

    const editIngredient = ingredientId => history.push(`${routes.ingredient}/${ingredientId}`);

    const deleteItem = id => {
      deleteIngredient(id);
    };

    const handleUploadFile = async ({ target }) => {
      if (target.files && target.files[0]) {
        try {
          if (target.files && target.files[0]) {
            const file = target.files[0];
            await uploadFile(file);
          }
        } catch (error) {
          Notification.showError(error.message);
        } finally {
          target.value = '';
        }
      }
    };

    const newCategoryFilter =
      allFilters.categoryFilters.length === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }]
        : [...allFilters.categoryFilters];

    return (
      <div>
        <form className={styles.ingredientForm}>
          <Form.Field className={styles.buttonWrapper}>
            <AddButton
              text={<FormattedMessage id="ingredient.create" defaultMessage="Add ingredient" />}
              onClick={addIngredient}
              className={styles.addIngredientButton}
            />
            <UploadButton onChange={handleUploadFile} forwardRef={uploadFileRef} />
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="storage.dropdown.category" defaultMessage="Category" />
            </label>
            <FormattedMessage
              id="storage.dropdown.category.placeholder"
              defaultMessage="Select category"
            >
              {placeholder => (
                <AsyncDropdown
                  loadOptions={loadCategoryOptions}
                  placeholder={placeholder}
                  value={newCategoryFilter}
                  onChange={value => changeAllFilters('categoryFilters', value)}
                  isMulti
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="ingredient.search" defaultMessage="Ingredient" />
            </label>
            <FormattedMessage
              id="ingredient.search.plaseholder"
              defaultMessage="Enter ingredient name"
            >
              {placeholder => (
                <SearchResetInput
                  value={name}
                  name="name"
                  placeholder={placeholder}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </form>
        <div>
          <IngredientTable
            tableFormat={newTableFormat}
            dropTableFormat={dropTableFormat}
            deleteItem={deleteItem}
            onRowClick={editIngredient}
            name={name}
            isMobile={isMobile}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Ingredient);
