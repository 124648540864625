import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import queryString from 'query-string';
import { NEW_ROUTE_PREFIX, MOBILE_BREAKPOINT } from 'utils/constants';
import SubHeader from 'components/common/sub-header';
import { withRouter } from 'react-router-dom';
import General from './general';

const SupplyRecordEditor = inject(
  'SupplyRecordEditor',
  'Notification',
  'SupplyRecord',
)(
  observer(({ SupplyRecordEditor, Notification, match, admin }) => {
    const { save, edit, onDuplicate, clearValidation, supplyRecord } = SupplyRecordEditor;
    const { queryAllowEditing } = queryString.parse(location.search);
    const { duplicate } = queryString.parse(location.search);

    const [allowEditing, setAllowEditing] = useState(queryAllowEditing === 'true');

    const supplyRecordId = match.params && match.params.id;
    const isEdit = supplyRecordId && supplyRecordId !== NEW_ROUTE_PREFIX;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      if (duplicate) {
        onDuplicate(duplicate);
        return;
      }
      edit(supplyRecordId, isEdit);
      return () => {
        clearValidation();
        setDisabled(false);
      };
    }, [supplyRecordId, isEdit, duplicate]);

    const header = isEdit ? (
      <FormattedMessage id="supply-record.edit" defaultMessage="Edit supply record" />
    ) : (
      <FormattedMessage id="supply-record.create" defaultMessage="Create supply record" />
    );

    const submitForm = async () => {
      setDisabled(true);
      try {
        await save({ admin, isEdit: allowEditing });
      } catch (error) {
        Notification.showError(error.message);
      }
      setDisabled(false);
    };

    const isMobile = window.innerWidth < 1136;
    const width = window.innerWidth;
    const desktop = width >= MOBILE_BREAKPOINT;

    return (
      <form className="ui form">
        <SubHeader
          title={header}
          button={
            !isEdit || (isEdit && allowEditing) ? (
              <Button
                type="button"
                primary
                disabled={disabled}
                onClick={submitForm}
                style={{ minWidth: isMobile ? 0 : 180 }}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            ) : (
              <span>
                {supplyRecord.status === 'ACTIVE' && supplyRecord.storageRecordType !== 'CAST' && (
                  <Button
                    type="button"
                    primary
                    onClick={() => setAllowEditing(true)}
                    style={{ minWidth: isMobile ? 0 : 180 }}
                  >
                    <FormattedMessage id="buttons.edit" />
                  </Button>
                )}
              </span>
            )
          }
        />
        <ErrorBoundary>
          <General
            desktop={desktop}
            isEdit={isEdit}
            duplicate={duplicate}
            supplyRecordId={supplyRecordId}
            allowEditing={allowEditing}
          />
        </ErrorBoundary>
      </form>
    );
  }),
);

export default withRouter(SupplyRecordEditor);
