import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'rsuite';
import { formatReportDate } from 'utils/time';
import { toFixedFive } from 'utils/numbers';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import AddButton from '../add-button';
import styles from '../styles.scss';
import mobStyles from './styles.scss';

const AddIngredientSupply = inject('SupplyRecordEditor')(
  observer(
    ({ SupplyRecordEditor, isEdit, errors, currency, isMobile, allowEditing, ingredientUnits }) => {
      const {
        supplyRecord,
        changeSupplyRecord,
        changeInitSupplyRecord,
        loadOptionsAllIngredients,
        ingredientsAllData,
        searchIngredientsAllData,
        packOptions,
        supplierList,
      } = SupplyRecordEditor;
      const { supplyItems } = supplyRecord;

      const [taxRate, setTaxRate] = useState(0);

      useEffect(() => {
        const taxPct = findTaxPct(supplyRecord.supplierId)?.taxPct;
        if (taxPct !== undefined) {
          changeInitSupplyRecord('taxPct', taxPct);
          setTaxRate(taxPct);
          return;
        }
        if (supplyRecord.hasOwnProperty('taxPct')) {
          setTaxRate(supplyRecord.taxPct);
          return;
        }
      }, [supplyRecord.supplierId]);

      useEffect(() => {
        if (supplyRecord.supplyItems.length > 0) {
          const newSupplyItems = [...supplyItems];
          supplyItems.forEach((item, index) => {
            newSupplyItems[index] = {
              ...newSupplyItems[index],
              total: taxRate
                ? (
                    Number(item.ingredientAmount) *
                    Number(item.ingredientUnitPrice) *
                    (taxRate / 100 + 1)
                  ).toFixed(2)
                : Number(item.ingredientAmount) * Number(item.ingredientUnitPrice),
            };
          });
          changeSupplyRecord('supplyItems', newSupplyItems);
        }
      }, [taxRate]);

      const findTaxPct = supplierId => {
        return supplierList.find(item => item.id === supplierId);
      };

      const change = (index, { value }) => {
        const newSupplyItems = [...supplyItems];
        const findIngredient =
          ingredientsAllData.find(i => i.id === value) ||
          searchIngredientsAllData.find(i => i.id === value);
        newSupplyItems[index] = {
          ...newSupplyItems[index],
          ingredientId: value,
          ingredientName: findIngredient.name,
          ingredientUnit: findIngredient.unit,
        };

        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const newPackOptions = [...ingredientUnits, ...packOptions];

      const onPackChange = (index, value) => {
        const newSupplyItems = [...supplyItems];
        const unitArr = ['LITER', 'PCS', 'KILOGRAM'];
        if (unitArr.some(i => i === value)) {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientPackId: '',
            ingredientPackName: '',
            ingredientPackAmount: '',
          };
        } else {
          const findItem = newPackOptions.find(i => i.value === value);
          const ingredientPackAmount = Number(findItem.amount);

          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientPackId: findItem.value || '',
            ingredientPackName: findItem.text || '',
            ingredientPackAmount,
          };
        }

        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const changeIngredientAmount = index => e => {
        const ingredientAmount = e.target.value;
        const newSupplyItems = [...supplyItems];
        const ingredientUnitPrice = newSupplyItems[index].ingredientUnitPrice;

        newSupplyItems[index] = {
          ...newSupplyItems[index],
          ingredientAmount: ingredientAmount,
          ingredientPackSupplyAmount: ingredientAmount,
          total: ingredientUnitPrice
            ? taxRate
              ? (
                  Number(ingredientAmount) *
                  (Number(ingredientUnitPrice) * (taxRate / 100 + 1))
                ).toFixed(2)
              : (Number(ingredientAmount) * Number(ingredientUnitPrice)).toFixed(2)
            : '',
        };
        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const changeIngredientUnitPrice = index => e => {
        const ingredientUnitPrice = Number(e.target.value);
        const newSupplyItems = [...supplyItems];
        const ingredientAmount = newSupplyItems[index].ingredientAmount;
        const ingredientTotal = newSupplyItems[index].total;
        const newIngredientAmount = taxRate
          ? (
              Number(ingredientTotal) / (Number(ingredientUnitPrice) * (taxRate / 100 + 1)) || 0
            ).toFixed(2)
          : (Number(ingredientTotal) / Number(ingredientUnitPrice) || 0).toFixed(2);
        if (ingredientTotal && !ingredientAmount) {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientUnitPrice: ingredientUnitPrice,
            ingredientAmount: newIngredientAmount,
            ingredientPackSupplyAmount: newIngredientAmount,
          };
        } else if (ingredientAmount) {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientUnitPrice: ingredientUnitPrice,
            total: taxRate
              ? (
                  Number(ingredientAmount) *
                  Number(ingredientUnitPrice) *
                  (taxRate / 100 + 1)
                ).toFixed(2)
              : Number(ingredientAmount) * Number(ingredientUnitPrice),
          };
        } else {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientUnitPrice: ingredientUnitPrice,
            total: 0,
          };
        }
        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const changeIngredientTotal = (index, totalValue) => {
        const newSupplyItems = [...supplyItems];
        const ingredientAmount = newSupplyItems[index].ingredientAmount;
        const ingredientUnitPrice = newSupplyItems[index].ingredientUnitPrice;
        const newIngredientAmount = taxRate
          ? (Number(totalValue) / (Number(ingredientUnitPrice) / (taxRate / 100 + 1)) || 0).toFixed(
              2,
            )
          : (Number(totalValue) / Number(ingredientUnitPrice) || 0).toFixed(2);

        if (ingredientAmount) {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientUnitPrice: taxRate
              ? toFixedFive(
                  Number(totalValue) / Number(ingredientAmount) / (taxRate / 100 + 1) || 0,
                )
              : toFixedFive(Number(totalValue) / Number(ingredientAmount) || 0),
            total: totalValue,
          };
        } else if (ingredientUnitPrice) {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            ingredientAmount: newIngredientAmount,
            ingredientPackSupplyAmount: newIngredientAmount,
            total: totalValue,
          };
        } else {
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            total: totalValue,
          };
        }
        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const addIngredient = () => {
        changeSupplyRecord('supplyItems', [...supplyItems, {}]);
      };

      const removeIngredient = i => {
        const newSupplyItems = [...supplyItems].filter((item, index) => index !== i);
        changeSupplyRecord('supplyItems', newSupplyItems);
      };

      const changeDate = useCallback(
        (date, index) => {
          const newSupplyItems = [...supplyItems];
          newSupplyItems[index] = {
            ...newSupplyItems[index],
            expireTimestamp: moment(date),
          };
          changeSupplyRecord('supplyItems', newSupplyItems);
        },
        [supplyItems],
      );

      const customDatepicker = (props, expireTimestamp) => {
        const time = expireTimestamp === 0 || !expireTimestamp;
        return (
          <div {...props} className={styles.datepicker}>
            {time ? (
              <div className={styles.dateTitle}>
                {' '}
                <FormattedMessage
                  id="ingredient.specifyExpirationDate"
                  defaultMessage="Specify expire date"
                />
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <FormattedMessage id="ingredient.expireTimestamp" defaultMessage="Expire date" />:
                <div className={styles.dateTitle}>{formatReportDate(expireTimestamp)}</div>
              </div>
            )}
          </div>
        );
      };

      return (
        <div className={mobStyles.contentWrapper}>
          {supplyItems.map(
            (
              {
                ingredientId,
                ingredientUnit,
                ingredientAmount,
                ingredientUnitPrice,
                expireTimestamp,
                ingredientName,
                total,
                id,
                ingredientPackId,
                ingredientPackName,
              },
              index,
            ) => {
              const isIngredientPack = ingredientPackId && ingredientPackName;
              const packValue = ingredientUnit ? ingredientUnit : null;
              return (
                <div key={index} className={mobStyles.rowWrapper}>
                  <Form.Group className={mobStyles.inputWrapper}>
                    <Form.Field
                      className={mobStyles.inputFirst}
                      error={errors[`ingredientId.${index}`]}
                    >
                      {(!isEdit || (isEdit && (!id || id === 0))) && (
                        <FormattedMessage
                          id="supply-record.data.ingredient"
                          defaultMessage="Ingredient"
                        >
                          {placeholder => (
                            <AsyncDropdown
                              loadOptions={(search, prevOptions, page) =>
                                loadOptionsAllIngredients({
                                  search,
                                  prevOptions,
                                  page,
                                  type: 'forSupply',
                                })
                              }
                              placeholder={placeholder}
                              value={
                                !ingredientName && !ingredientId
                                  ? null
                                  : { label: ingredientName, value: ingredientId }
                              }
                              onChange={value => change(index, value)}
                            />
                          )}
                        </FormattedMessage>
                      )}
                      {isEdit && id > 0 && (
                        <Dropdown
                          disabled
                          options={[{ value: ingredientId, text: ingredientName }]}
                          value={ingredientId}
                          fluid
                          search
                          selection
                          name="ingredientId"
                          onChange={() => {}}
                        />
                      )}
                    </Form.Field>
                    {(!isEdit || (isEdit && allowEditing)) && (
                      <div className={mobStyles.iconWrapper}>
                        <Icon name="remove" onClick={() => removeIngredient(index)} />
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className={mobStyles.inputWrapper}>
                    <Form.Field
                      className={mobStyles.item}
                      error={errors[`ingredientUnit.${index}`]}
                    >
                      <FormattedMessage id="ingredient.data.unit" defaultMessage="Unit">
                        {placeholder => (
                          <Dropdown
                            placeholder={placeholder}
                            disabled={isEdit && !allowEditing}
                            options={[
                              ...(ingredientUnit
                                ? [
                                    {
                                      text: getLocalizedString(
                                        `ingredient.unit.${ingredientUnit.toLowerCase()}`,
                                      ),
                                      value: ingredientUnit,
                                      key: ingredientUnit,
                                    },
                                  ]
                                : []),
                              ...packOptions,
                            ]}
                            value={!isIngredientPack ? packValue : ingredientPackId}
                            fluid
                            key={!isIngredientPack ? packValue : ingredientPackId}
                            search
                            selection
                            onChange={(e, { value }) => onPackChange(index, value)}
                          />
                        )}
                      </FormattedMessage>
                    </Form.Field>
                    <Form.Field
                      className={mobStyles.itemHaveLabel}
                      error={errors[`ingredientAmount.${index}`]}
                    >
                      <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount">
                        {placeholder => (
                          <Input
                            disabled={isEdit && !allowEditing}
                            value={Number(ingredientAmount)}
                            min="0"
                            placeholder={placeholder}
                            type="number"
                            onChange={changeIngredientAmount(index)}
                            label={{
                              basic: true,
                              content: ingredientUnit ? (
                                <div style={{ width: 20 }}>
                                  {!isIngredientPack && (
                                    <FormattedMessage
                                      id={`ingredient.unit.${ingredientUnit.toLowerCase()}`}
                                      defaultMessage={`${ingredientUnit.toLowerCase()}`}
                                    />
                                  )}
                                  {isIngredientPack > 0 && (
                                    <FormattedMessage
                                      id="ingredient.unit.pcs"
                                      defaultMessage="pcs"
                                    />
                                  )}
                                </div>
                              ) : (
                                <div style={{ width: 20 }} />
                              ),
                            }}
                            labelPosition="right"
                          />
                        )}
                      </FormattedMessage>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group className={mobStyles.inputWrapper}>
                    <Form.Field
                      className={mobStyles.item}
                      error={errors[`ingredientUnitPrice.${index}`]}
                    >
                      <FormattedMessage
                        id="supply-record.data.price"
                        defaultMessage="Price per pc."
                      >
                        {placeholder => (
                          <input
                            placeholder={placeholder}
                            disabled={isEdit && !allowEditing}
                            value={Number(ingredientUnitPrice)}
                            min="0"
                            type="number"
                            onChange={changeIngredientUnitPrice(index)}
                          />
                        )}
                      </FormattedMessage>
                    </Form.Field>
                    {taxRate ? (
                      <Form.Field className={mobStyles.item}>
                        <input disabled value={`${taxRate} %`} />
                      </Form.Field>
                    ) : (
                      <></>
                    )}

                    {isEdit && !allowEditing ? (
                      <Form.Field className={mobStyles.itemTotalValue} style={{}}>
                        <span className={styles.itemTotalValue}>
                          {currency}
                          {ingredientAmount && ingredientUnitPrice
                            ? toFixedFive(total || 0)
                            : '0.00'}
                        </span>
                      </Form.Field>
                    ) : (
                      <FormattedMessage id="supply-record.data.total" defaultMessage="Total">
                        {placeholder => (
                          <Form.Field
                            className={mobStyles.itemHaveLabel}
                            // style={{ marginRight: 46 }}
                          >
                            <Input
                              disabled={isEdit && !allowEditing}
                              value={toFixedFive(total)}
                              placeholder={placeholder}
                              min="0"
                              type="number"
                              onChange={(e, { value }) => changeIngredientTotal(index, value)}
                              label={{
                                basic: true,
                                content: (
                                  <div style={{ width: 20, textAlign: 'center' }}>{currency}</div>
                                ),
                              }}
                              labelPosition="right"
                            />
                          </Form.Field>
                        )}
                      </FormattedMessage>
                    )}
                  </Form.Group>

                  <div style={{ width: '82%' }}>
                    <DatePicker
                      disabled={isEdit && !allowEditing}
                      fluid
                      selectYear
                      cleanable
                      placement="auto"
                      onChange={date => changeDate(date, index)}
                      toggleComponentClass={props => customDatepicker(props, expireTimestamp)}
                      format="YYYY-MM-DD HH:mm:ss"
                      value={expireTimestamp}
                    />
                  </div>
                </div>
              );
            },
          )}
          {(!isEdit || (isEdit && allowEditing)) && (
            <AddButton onClick={addIngredient} whiteIcon={isMobile} />
          )}
        </div>
      );
    },
  ),
);

export default AddIngredientSupply;
