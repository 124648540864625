import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/supply-record';

class SupplyRecordAPI {
  static list = (page = 0, size = 20, filters) => {
    const queryFilters = extractFiltersQuery(filters);
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${queryFilters}`);
  };

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static update = supplyRecordData => Request.PUT(ENDPOINT, supplyRecordData);

  static create = supplyRecordData => Request.POST(ENDPOINT, supplyRecordData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default SupplyRecordAPI;
