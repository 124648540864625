import React, { useState, useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import throttle from 'lodash/throttle';
import SearchResetInput from 'components/common/inputs/search-reset';
import { withRouter } from 'react-router-dom';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { AddButton } from 'components/common/buttons';
import { processTableData } from 'utils/process-table-data';
import { routes } from 'routes';
import styles from './styles.scss';

const IngredientCategories = inject(
  'IngredientCategory',
  'IngredientCategoryEditor',
  'Modals',
)(
  observer(({ IngredientCategory, history, Modals }) => {
    const {
      tableFormat,
      data,
      fetchData,
      deleteCategory,
      loading,
      paginateOptions,
      allFilters,
      paginateData,
      changePaginateData,
      changeAllFilters,
    } = IngredientCategory;
    const [name, setName] = useState('');

    useEffect(() => {
      setName(allFilters.name);
    }, []);

    useEffect(() => {
      fetchData();
    }, [allFilters, paginateData]);

    const addIngredientCategory = () => history.push(`${routes.ingredientCategory}/new`);

    const editIngredientCategory = ingredientCategoryId =>
      history.push(`${routes.ingredientCategory}/${ingredientCategoryId}`);

    const deleteItem = (id, item) => {
      Modals.show(undefined, { subject: item.name }, () => deleteCategory(id));
    };

    const menu = [
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    const applyFilter = (name, value) => {
      if (value.length > 2) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters({ ...allFilters, [name]: value });
      } else if (value.length === 0) {
        changePaginateData({ ...paginateData, page: 0 });
        changeAllFilters({ ...allFilters, [name]: value });
      }
    };

    const delayedCallback = useCallback(
      throttle((name, value) => applyFilter(name, value), 2000),
      [paginateData, allFilters],
    );

    const onChange = (name, value) => {
      setName(value);
      delayedCallback(name, value);
    };

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          onRowClick: () => editIngredientCategory(item.id),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
        };
      });
    };

    return (
      <div>
        <div className={styles.headerWrapper}>
          <AddButton
            text={
              <FormattedMessage
                id="ingredient-category.create"
                defaultMessage="Create ingredient category"
              />
            }
            onClick={addIngredientCategory}
          />
          <div className={styles.inputWrapper}>
            <FormattedMessage
              id="ingredient-category.search.plaseholder"
              defaultMessage="Enter ingredient category"
            >
              {placeholder => (
                <SearchResetInput
                  value={name}
                  name="name"
                  placeholder={placeholder}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
              )}
            </FormattedMessage>
          </div>
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateOptions={paginateOptions}
            paginateData={paginateData}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(IngredientCategories);
