import React, { useState } from 'react';
import { Tab, Menu } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { routes } from 'routes';
import Inventory from 'components/inventory';
import { BottomWrapper, DashboardWrapper } from '../style';
import Storage from '../../components/storage';

const tabRoutes = {
  '/storage': 0,
  '/inventory': 1,
};

const StockPage = ({ history }) => {
  const [activeIndex, setActiveIndex] = useState(tabRoutes[history.location.pathname]);

  const panes = [
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.storage)} key="storage">
          <FormattedMessage id="storage.leftovers" defaultMessage="Leftovers" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Storage />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item onClick={() => history.push(routes.inventory)} key="inventory">
          <FormattedMessage id="inventory.name" defaultMessage="Inventory" />
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Inventory />
        </Tab.Pane>
      ),
    },
  ];

  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <DashboardWrapper>
      <BottomWrapper />
      <Tab
        panes={panes}
        menu={{ secondary: true, pointing: true }}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
      />
    </DashboardWrapper>
  );
};

export default StockPage;
