import React from 'react';
import { Input, Icon } from 'semantic-ui-react';

import styles from './styles.scss';

const SearchResetInput = ({ placeholder, onChange, style, value, name }) => {
  return (
    <span className={styles.inputWrapper}>
      <Input
        icon="search"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(event, { name, value }) => onChange(name, value)}
        style={style}
      />

      <Icon name="close" className={styles.resetIcon} onClick={() => onChange(name, '')} />
    </span>
  );
};

export default SearchResetInput;
