import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { toFixedTwo } from 'utils/numbers';
import get from 'lodash/get';
import InfoPopup from './info-popup';

const IngredientTable = inject(
  'Ingredient',
  'Modals',
)(
  observer(({ Ingredient, onRowClick, deleteItem, tableFormat, Modals }) => {
    const { data, loading, stockInfo, productsList, paginateOptions, paginateData } = Ingredient;
    const [allData, setAllData] = useState(data);

    useEffect(() => {
      setAllData(data);
    }, [data]);

    const deleteIngredient = (id, item) => {
      Modals.show(undefined, { subject: item.name }, () => deleteItem(id));
    };

    const menu = [
      {
        name: <FormattedMessage id="menu.edit" defaultMessage="Edit" />,
        action: onRowClick,
      },
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteIngredient,
      },
    ];

    const processTableData = data => {
      return data.map((item, index) => {
        const optionMap = get(productsList, `optionMap[${item.id}]`);
        const productMap = get(productsList, `productMap[${item.id}]`);
        const ingredientMap = get(productsList, `ingredientMap[${item.id}]`);
        return {
          ...item,
          name:
            optionMap || productMap || ingredientMap ? (
              <InfoPopup
                name={item.name}
                id={item.id}
                optionMap={optionMap || {}}
                productMap={productMap || {}}
                ingredientMap={ingredientMap || {}}
                position={index < 5 ? 'right bottom' : 'right center'}
              />
            ) : (
              item.name
            ),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
          onRowClick: () => {},
          unit: (
            <FormattedMessage
              id={`ingredient.unit.${item && item.unit.toLowerCase()}`}
              defaultMessage={item.unit}
            />
          ),
          stock: stockInfo[item.id] ? toFixedTwo(stockInfo[item.id].totalStock) : '-',
        };
      });
    };

    return (
      <div>
        <TableWrapper
          key={allData.length}
          data={processTableData(allData)}
          headers={[
            ...tableFormat,
            {
              name: '',
              ignoreTd: true,
              property: 'menu',
              variant: ['nowrap'],
            },
          ]}
          sizeable
          loading={loading}
          showPagination
          paginateOptions={paginateOptions}
          paginateData={paginateData}
        />
      </div>
    );
  }),
);

IngredientTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  deleteModifier: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  admin: PropTypes.bool,
  merchatSettings: PropTypes.object,
  groupSettings: PropTypes.object,
};

export default IngredientTable;
