import React from 'react';
import { Icon } from 'semantic-ui-react';
import cn from 'classnames';
import { CSVLink } from 'react-csv';
import { inject, observer } from 'mobx-react';
import DownloadCSVModal from 'components/common/popups/download-csv';
import { toJS } from 'mobx';
import styles from './styles.scss';

const DownloadCSVButton = inject('Modals')(
  observer(({ Modals, data, style, headers }) => {
    const isArray = Array.isArray(data);
    const newHeaders = toJS(headers);
    const showModal = () => {
      const { dataByIngredients, dataByLots } = data;
      Modals.show(DownloadCSVModal, {
        dataByIngredients: toJS(dataByIngredients),
        dataByLots: toJS(dataByLots),
        headers: newHeaders,
      });
    };

    if (isArray) {
      return (
        <div style={style}>
          <CSVLink
            data={data}
            headers={newHeaders}
            filename="my-file.csv"
            className={cn('btn btn-primary', styles.downloadCSV)}
            target="_blank"
          >
            <Icon name="download" />
          </CSVLink>
        </div>
      );
    } else {
      return (
        <div style={style}>
          <div className={cn('btn btn-primary', styles.downloadCSV)} onClick={showModal}>
            <Icon name="download" />
          </div>
        </div>
      );
    }
  }),
);

export default DownloadCSVButton;
