import AnalyticsAPI from 'api/analytics';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import StorageAPI from 'api/storage';
import IngredientCategoryAPI from 'api/ingredient-category';
import moment from 'moment';
import { getUTCOffset } from 'utils/time';
import { getLocalizedString } from 'i18n/utils';

const INGREDIENT_NAME = 'ingredientName';
const TOTAL_AMOUT = 'totalAmount';
const DISPOSE_AMOUT = 'disposeAmount';
const SELL_AMOUT = 'sellAmount';
const SUPPLY_AMOUT = 'supplyAmount';
const START_AMOUT = 'startAmount';
const TAKE_AMOUT = 'takeAmount';
const START_AVG_PRICE = 'startAvgPrice';
const END_AVG_PRICE = 'endAvgPrice';
const END_AMOUNT = 'endAmount';
const MOVE = 'moveAmount';
const CASTED_AMOUNT = 'castedAmount';

export const TABLE_FORMAT = [
  {
    name: getLocalizedString('analytics.table.ingredient'),
    property: INGREDIENT_NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.action.type.startAmount'),
    property: START_AMOUT,
    variant: ['left'],
    className: 'whiteSpacePre',
  },
  {
    name: getLocalizedString('analytics.action.type.startAvgPrice'),
    property: START_AVG_PRICE,
    variant: ['left'],
    className: 'whiteSpacePre',
  },
  {
    name: getLocalizedString('analytics.action.type.SUPPLY'),
    property: SUPPLY_AMOUT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.action.type.SELL'),
    property: SELL_AMOUT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.action.type.DISPOSE'),
    property: DISPOSE_AMOUT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.action.type.TAKE'),
    property: TAKE_AMOUT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.action.type.MOVE'),
    property: MOVE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('analytics.table.castedAmount'),
    property: CASTED_AMOUNT,
    variant: ['left'],
    className: 'whiteSpacePre',
  },
  {
    name: getLocalizedString('analytics.action.type.endAmount'),
    property: END_AMOUNT,
    variant: ['left'],
    className: 'whiteSpacePre',
  },
  {
    name: getLocalizedString('analytics.action.type.endAvgPrice'),
    property: END_AVG_PRICE,
    variant: ['left'],
    className: 'whiteSpacePre',
  },
  {
    name: getLocalizedString('analytics.table.amount'),
    property: TOTAL_AMOUT,
    variant: ['left'],
  },
];

const initAllFilters = {
  startTimeUtc: new Date(),
  endTimeUtc: new Date(),
  storageFilters: 'all',
  categoryFilters: [],
  ingredientNameFilter: '',
};

class AnalyticsStore {
  @observable tableFormat = TABLE_FORMAT;
  @observable list = [];
  @observable paginateOptions = {};
  @observable storagesOptions = [];
  @observable loading = false;
  @observable currentPage = -1;
  @observable totalPages = 0;
  @observable hasMore = true;
  @observable totalElements = 0;
  @observable paginateData = { page: 0, size: 20 };
  @observable allFilters = initAllFilters;

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  changeAllFilters = (key, value) => {
    if (key === 'storageFilters') {
      this.allFilters[key] = value;
    } else if (key === 'categoryFilters') {
      if (value[0] && value[0].value === 'all') {
        runInAction(() => {
          this.allFilters[key] = value.filter(i => i.value !== 'all');
        });
      } else if (value[0] && value.some(i => i.value === 'all')) {
        this.allFilters[key] = [];
      } else {
        this.allFilters[key] = value;
      }
    } else if (key === 'allQueryFilters') {
      runInAction(() => {
        this.allFilters = { ...this.allFilters, ...value };
      });
    } else {
      runInAction(() => {
        this.allFilters[key] = value;
      });
    }
    this.changePaginateData({ ...this.paginateData, page: 0 });
  };

  @action
  getFormattedDate = () => {
    const startDate = moment(this.allFilters.startTimeUtc)
      .startOf('day')
      .valueOf();
    const endDate = moment(this.allFilters.endTimeUtc)
      .startOf('day')
      .valueOf();
    return {
      startDate: startDate + getUTCOffset(startDate),
      endDate: endDate + getUTCOffset(endDate),
    };
  };

  @action
  getAnalytics = async () => {
    const { startDate, endDate } = this.getFormattedDate();
    const { page, size } = this.paginateData;
    const newFilters = {
      ...this.allFilters,
      startTimeUtc: startDate,
      endTimeUtc: endDate,
      categoryFilters: this.allFilters.categoryFilters.map(i => i.value),
      storageFilters:
        this.allFilters.storageFilters === 'all' ? [] : [this.allFilters.storageFilters],
      page,
      size,
    };
    const { items, last, totalPages, number, totalElements } = await AnalyticsAPI.getAnalytics(
      newFilters,
    );

    runInAction(() => {
      this.list = items;
      this.totalPages = totalPages;
      this.hasMore = !last;
      this.currentPage = number;
      this.totalElements = totalElements;
      this.loading = false;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };
    });
  };

  @action
  loadStoragesOptions = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storagesOptions = [
        { value: 'all', text: getLocalizedString('filters.all') },
        ...items.map(item => ({
          value: item.id,
          text: item.name,
          key: item.id,
        })),
      ];
    });
  };

  @action
  getAnalyticsBySales = async ingredientId => {
    const { startDate, endDate } = this.getFormattedDate();
    const newFilters = {
      ingredientId,
      startTimeUtc: startDate,
      endTimeUtc: endDate,
    };
    return await AnalyticsAPI.getAnalyticsBySales(newFilters);
  };

  @action
  loadCategoryOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientCategoryAPI.list(page, 20);
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientCategoryAPI.list(0, 20, search);
      filteredOptions = [...items];
    }
    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;
    return {
      options: slicedOptions,
      hasMore,
    };
  };
}

export default AnalyticsStore;
