import React from 'react';
import { FormattedMessage } from 'react-intl';
import TableWrapper from 'components/common/TableWrapper';
import moment from 'moment';
import styles from './styles.scss';
import InfoPopup from './info-popup';

const tableHeaderOptions = [
  {
    name: (
      <FormattedMessage
        id="ingredientCard.versioningIngredientCard.userHumanName"
        defaultMessage="User human name"
      />
    ),
    property: 'userHumanName',
    variant: ['left'],
  },
  {
    name: (
      <FormattedMessage
        id="ingredientCard.versioningIngredientCard.updatedTimestamp"
        defaultMessage="Updated timestamp"
      />
    ),
    property: 'updatedTimestamp',
    variant: ['left'],
  },
  {
    name: (
      <FormattedMessage
        id="ingredientCard.versioningIngredientCard.recipeAction"
        defaultMessage="Recipe action"
      />
    ),
    property: 'recipeAction',
    variant: ['left'],
  },
];

const VersioningIngredientCard = ({ auditList, structuredData, currency }) => {
  const processTableData = data => {
    return data.map(item => {
      return {
        ...item,
        updatedTimestamp: (
          <InfoPopup
            date={moment(item.actionTimestamp).format('DD-MM-YYYY HH:mm')}
            data={item.recipeItemAudits}
            currency={currency}
            structuredData={structuredData}
          />
        ),
        recipeAction: (
          <FormattedMessage
            id={`ingredientCard.versioningIngredientCard.actions.${item.recipeAction}`}
            defaultMessage={item.recipeAction}
          />
        ),
      };
    });
  };

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.header}>
        <FormattedMessage
          id="ingredientCard.versioningIngredientCard.header"
          defaultMessage="Changelog"
        />
      </div>
      <TableWrapper
        data={processTableData(auditList || [])}
        headers={tableHeaderOptions}
        useOuterComponentFetch
        sizeable={false}
        onClick={() => {}}
      />
    </div>
  );
};

export default VersioningIngredientCard;
