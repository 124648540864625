import { observable, action } from 'mobx';

class Notification {
  @observable visible = false;
  @observable visibleSnackbar = false;
  @observable message = {
    text: '',
    isError: false,
  };

  @action
  show = () => {
    this.visible = true;

    this.setupTimer();
  };

  @action
  showSnackbar = () => {
    this.visibleSnackbar = true;
  };

  @action
  hideSnackbar = () => {
    this.visibleSnackbar = false;
  };

  @action
  showSuccess = (message = 'Success') => {
    this.message = {
      text: message,
    };

    this.show();
  };

  @action
  showError = error => {
    this.message = {
      text: error,
      isError: true,
    };

    this.show();
  };

  @action
  hide = () => {
    this.visible = false;
  };

  timer = null;

  setupTimer = () => {
    this.timer = setTimeout(this.hide, 5000);
  };
}

export default Notification;
