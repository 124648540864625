import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { CURRENCY_CODE_TO_SIGN } from 'utils/constants';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { toFixedFive, toFixedTwo } from 'utils/numbers';
import { getLocalizedString } from 'i18n/utils';
import cn from 'classnames';
import AddButton from '../add-button';

import styles from '../styles.scss';

const AddIngredientRest = inject(
  'SupplyRecordEditor',
  'Ingredient',
  'MerchantsGroup',
)(
  observer(({ SupplyRecordEditor, isEdit, errors, MerchantsGroup, isMobile, allowEditing }) => {
    const {
      supplyRecord,
      changeSupplyRecord,
      ingredientsData,
      searchIngredientsData,
      loadOptions,
    } = SupplyRecordEditor;

    const { supplyItems, fromStorageId } = supplyRecord;

    const currency = CURRENCY_CODE_TO_SIGN[MerchantsGroup.info.currency];

    const change = (index, obj) => {
      const newSupplyItems = [...supplyItems];
      const arrValue = obj.value.split('_');

      const optionItem =
        ingredientsData.find(
          i =>
            Number(i.ingredientId) === Number(arrValue[0]) &&
            Number(i.stock) === Number(arrValue[1]) &&
            (Number(i.ingredientExpireTimestamp) === Number(arrValue[2]) ||
              !i.ingredientExpireTimestamp) &&
            Number(i.ingredientUnitPrice) === Number(arrValue[3]),
        ) ||
        searchIngredientsData.find(
          i =>
            Number(i.ingredientId) === Number(arrValue[0]) &&
            Number(i.stock) === Number(arrValue[1]) &&
            (Number(i.ingredientExpireTimestamp) === Number(arrValue[2]) ||
              !i.ingredientExpireTimestamp) &&
            Number(i.ingredientUnitPrice) === Number(arrValue[3]),
        ) ||
        {};

      newSupplyItems[index] = {
        ...newSupplyItems[index],
        ingredientUnitPrice: optionItem.ingredientUnitPrice,
        ingredientId: arrValue[0],
        ingredientUnit: optionItem.ingredientUnit,
        ingredientName: optionItem.ingredientName,
        supplyTimestamp: optionItem.supplyTimestamp,
        expireTimestamp: optionItem.expireTimestamp,
        stock: optionItem.stock,
        total:
          newSupplyItems[index] && newSupplyItems[index].ingredientAmount
            ? optionItem.ingredientUnitPrice * newSupplyItems[index].ingredientAmount || 0
            : 0,
      };
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const changeIngredientAmount = index => e => {
      const ingredientAmount = e.target.value;
      const newSupplyItems = [...supplyItems];
      newSupplyItems[index] = {
        ...newSupplyItems[index],
        ingredientAmount: ingredientAmount,
        total:
          newSupplyItems[index] && newSupplyItems[index].ingredientUnitPrice
            ? newSupplyItems[index].ingredientUnitPrice * ingredientAmount || 0
            : 0,
      };
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const addIngredient = () => {
      changeSupplyRecord('supplyItems', [...supplyItems, {}]);
    };

    const removeIngredient = i => {
      const newSupplyItems = [...supplyItems].filter((item, index) => index !== i);
      changeSupplyRecord('supplyItems', newSupplyItems);
    };
    const getIngredientName = item => {
      if (item.ingredientUnit) {
        const stock = item.stock
          ? `(${toFixedTwo(item.stock)}${' '}${getLocalizedString(
              `ingredient.unit.${item.ingredientUnit.toLowerCase()}`,
            )})${' '}`
          : '';
        const price = `(${toFixedTwo(item.ingredientUnitPrice)}${currency})${' '}`;

        return `${item.ingredientName}${' '}${stock}${
          item.ingredientUnitPrice !== '0.00' ? price : ''
        }`;
      } else {
        return '';
      }
    };

    const newSupplyItems = supplyItems.length === 0 ? [{}] : supplyItems;

    return (
      <div className={styles.contentWrapper}>
        {!!newSupplyItems.length && (
          <div style={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
            <Form.Field
              className={cn(styles.inputHeaderFirst, styles.inputHeaderFirstMove)}
              required
            >
              <label>
                <FormattedMessage id="supply-record.data.ingredient" defaultMessage="Ingredient" />
              </label>
            </Form.Field>
            <div className={styles.padding} />
          </div>
        )}
        {newSupplyItems.map(
          (
            {
              ingredientId,
              ingredientUnit,
              ingredientName,
              ingredientAmount,
              ingredientUnitPrice,
              stock,
              id,
              total,
            },
            index,
          ) => (
            <div key={index} className={styles.rowWrapper}>
              <Form.Group className={styles.inputWrapper}>
                <Form.Field
                  className={styles.inputFirstMove}
                  error={errors[`ingredientId.${index}`]}
                >
                  {(!isEdit || (isEdit && (!id || id === 0))) && (
                    <FormattedMessage
                      id="supply-record.data.ingredient"
                      defaultMessage="Ingredient"
                    >
                      {placeholder => (
                        <AsyncDropdown
                          key={fromStorageId}
                          loadOptions={(search, prevOptions, page) =>
                            loadOptions(search, prevOptions, page, fromStorageId)
                          }
                          placeholder={placeholder}
                          value={{
                            label: getIngredientName({
                              ingredientName,
                              ingredientUnitPrice,
                              ingredientUnit,
                              stock,
                            }),
                            value: ingredientId,
                          }}
                          onChange={value => change(index, value)}
                        />
                      )}
                    </FormattedMessage>
                  )}
                  {isEdit && id > 0 && (
                    <Dropdown
                      disabled
                      options={[
                        {
                          value: ingredientId,
                          text: ingredientName,
                        },
                      ]}
                      value={ingredientId}
                      fluid
                      search
                      selection
                      name="ingredientId"
                      onChange={() => {}}
                    />
                  )}
                </Form.Field>
                {(!isEdit || (isEdit && allowEditing)) && (
                  <div className={styles.iconWrapper}>
                    <Icon name="remove" onClick={() => removeIngredient(index)} />
                  </div>
                )}
              </Form.Group>
              <Form.Group className={styles.inputWrapper} widths="2">
                <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount">
                  {placeholder => (
                    <Form.Field
                      error={errors[`ingredientAmount.${index}`]}
                      required
                      className={styles.item}
                    >
                      <Input
                        disabled={isEdit && !allowEditing}
                        value={ingredientAmount}
                        key={Number(ingredientName)}
                        min="0"
                        type="number"
                        placeholder={placeholder}
                        onChange={changeIngredientAmount(index)}
                        label={{
                          basic: true,
                          content: ingredientUnit ? (
                            <FormattedMessage
                              id={`ingredient.unit.${ingredientUnit.toLowerCase()}`}
                              defaultMessage={`${ingredientUnit.toLowerCase()}`}
                            />
                          ) : (
                            <div style={{ width: 18 }} />
                          ),
                        }}
                        labelPosition="right"
                      />
                    </Form.Field>
                  )}
                </FormattedMessage>
                <FormattedMessage id="supply-record.data.total" defaultMessage="Total">
                  {placeholder => (
                    <Form.Field className={styles.item}>
                      <Input
                        disabled
                        value={toFixedFive(total)}
                        placeholder={placeholder}
                        type="number"
                        label={{
                          basic: true,
                          content: <div style={{ width: 20, textAlign: 'center' }}>{currency}</div>,
                        }}
                        labelPosition="right"
                      />
                    </Form.Field>
                  )}
                </FormattedMessage>
              </Form.Group>
            </div>
          ),
        )}
        {(!isEdit || (isEdit && allowEditing)) && (
          <AddButton onClick={addIngredient} whiteIcon={isMobile} />
        )}
      </div>
    );
  }),
);

export default AddIngredientRest;
