import React, { useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import Spinner from 'components/common/Spinner';
import { getLocalizedString } from 'i18n/utils';

export const CSVHeader = [
  {
    label: getLocalizedString('inventory.business-date'),
    key: 'date',
  },
  {
    label: getLocalizedString('analytics.csvHeader.orderNumber'),
    key: 'orderNumber',
  },
  {
    label: getLocalizedString('analytics.csvHeader.ingredient'),
    key: 'name',
  },
  {
    label: getLocalizedString('inventory.modal.table.count'),
    key: 'amount',
  },
];

const DownloadCsvBySales = inject(
  'Analytics',
  'Notification',
)(
  observer(({ Analytics, Notification, item }) => {
    const { sellAmount, ingredientId } = item;
    const { getAnalyticsBySales } = Analytics;
    const [ingredientData, setIngredientData] = useState([]);
    const [loading, setLoading] = useState(false);
    const csvInstance = useRef(null);

    const fetchCSVData = async () => {
      if (!loading) {
        setLoading(true);
        try {
          const data = await getAnalyticsBySales(ingredientId);
          setLoading(false);
          setIngredientData(data.items || []);
          csvInstance.current.link.click();
        } catch (error) {
          Notification.showError(error.message);
        }
      }
    };

    if (Number(sellAmount) === 0) {
      return <div style={{ color: '#8f8f8f' }}>{sellAmount}</div>;
    } else {
      return (
        <div>
          <div
            style={{
              color: '#8f8f8f',
              textDecoration: 'underline',
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={() => fetchCSVData(ingredientId)}
          >
            {sellAmount}
            <Spinner active={loading} size="mini" style={{ marginTop: -4 }} />
          </div>
          <CSVLink
            headers={CSVHeader}
            filename={`sales_${item.ingredientName}.csv`}
            data={ingredientData}
            ref={csvInstance}
            style={{ display: 'none' }}
          />
        </div>
      );
    }
  }),
);

DownloadCsvBySales.propTypes = {
  item: PropTypes.object,
};

export default DownloadCsvBySales;
