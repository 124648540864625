import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { AddButton } from 'components/common/buttons';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { routes } from 'routes';
import { processTableData } from 'utils/process-table-data';

const Suppliers = inject('Supplier')(
  observer(({ Supplier, history }) => {
    const {
      tableFormat,
      data,
      fetchData,
      deleteSupplier,
      loading,
      paginateOptions,
      paginateData,
    } = Supplier;

    useEffect(() => {
      fetchData();
    }, [paginateData]);

    const addSupplier = () => history.push(`${routes.supplier}/new`);

    const editSupplier = supplierId => history.push(`${routes.supplier}/${supplierId}`);

    const deleteItem = id => {
      deleteSupplier(id);
    };

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          onRowClick: () => editSupplier(item.id),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
        };
      });
    };

    const menu = [
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    return (
      <div>
        <div>
          <AddButton
            text={<FormattedMessage id="storage.add.supplier" defaultMessage="Add supplier" />}
            onClick={addSupplier}
          />
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateOptions={paginateOptions}
            paginateData={paginateData}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Suppliers);
