import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/ingredient-category';

class IngredientCategoryAPI {
  static list = (page = 0, size = 20, name = '') => {
    const newName = name.length ? `&name=${name}` : '';
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${newName}`);
  };

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static update = ingredientCategoryData => Request.PUT(`${ENDPOINT}`, ingredientCategoryData);

  static create = ingredientCategoryData => Request.POST(ENDPOINT, ingredientCategoryData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default IngredientCategoryAPI;
