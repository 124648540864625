import { action, observable, runInAction } from 'mobx';
import PackingAPI from 'api/packing';
import history, { routes } from 'routes';
import validate from 'validate.js';

const initPacking = {
  id: 0,
  name: '',
  unit: '',
  amount: '',
};

const VALIDATION_SCHEMA = {
  name: { presence: { allowEmpty: false } },
  unit: { presence: { allowEmpty: false } },
  amount: { numericality: true },
};

class PackingEditorStore {
  constructor(packingStore) {
    this.packingStore = packingStore;
  }
  @observable packing = initPacking;
  @observable validationErrors = {};

  @action
  create = path => {
    history.push(path);
  };

  @action
  isValid = () => {
    return Object.values(this.validationErrors).length === 0;
  };

  @action
  changePacking = (key, value) => {
    runInAction(() => {
      if (!this.isValid()) {
        this.validationErrors = {};
      }
      this.packing[key] = value;
    });
  };

  @action
  edit = async id => {
    runInAction(() => {
      const item = this.packingStore.data.find(item => item.id == id);
      if (item) {
        this.packing = { ...item };
      }
    });

    const data = await PackingAPI.get(id);

    runInAction(() => {
      this.packing = data;
    });
  };

  @action
  onSubmit = async (packing, { isEdit }) => {
    try {
      if (isEdit) {
        await PackingAPI.update(packing.id, packing);
      } else {
        await PackingAPI.create(packing);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  save = async ({ admin, isEdit, merchantGroupId }) => {
    runInAction(() => {
      this.validationErrors = validate(this.packing, VALIDATION_SCHEMA) || {};
    });
    try {
      if (this.isValid()) {
        await this.onSubmit({ ...this.packing, merchantGroupId }, { isEdit });
        history.push(routes.packing);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  @action
  clearValidation = () => {
    runInAction(() => {
      this.validationErrors = {};
      this.packing = { ...initPacking };
    });
  };
}

export default PackingEditorStore;
