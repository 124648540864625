import React from 'react';
import PropTypes from 'prop-types';
import { General, Title } from './style';

const FormSection = ({ title, children, style }) => (
  <General style={style}>
    <Title>{title}</Title>
    {children}
  </General>
);

FormSection.propTypes = {
  style: PropTypes.object,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default FormSection;
