import React, { useEffect, useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Icon, Popup } from 'semantic-ui-react';
import SearchResetInput from 'components/common/inputs/search-reset';
import { FormattedMessage } from 'react-intl';
import { getLocalizedString } from 'i18n/utils';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import Checkbox from 'components/common/checkbox';
import { INVENTORY_ACCESS_LEVEL, CURRENCY_CODE_TO_SIGN } from 'utils/constants';
import get from 'lodash.get';
import throttle from 'lodash/throttle';
import moment from 'moment';
import { processTableData } from 'utils/process-table-data';
import DownloadCSVButton from 'components/common/dowload-csv-button';
import TableWrapper from 'components/common/TableWrapper';
import styles from './styles.scss';

const General = inject(
  'Storage',
  'Notification',
  'User',
  'AccountEditor',
  'MerchantsGroup',
)(
  observer(({ Storage, User, MerchantsGroup }) => {
    const {
      changeAllFilters,
      storageList,
      loadStorages,
      fetchIngredients,
      loading,
      fullTableFormat,
      storageItems,
      allFilters,
      loadCategoryOptions,
      headerForCSV,
      totalStockLeftoverValuation,
    } = Storage;
    const { storageFilters, categoryFilters, ingredientNameFilter } = allFilters;
    // const [newStorageItems, setNewStorageItems] = useState(storageItems);

    const [name, setName] = useState('');
    const [lowStock, setLowStock] = useState(false);

    const applyFilter = value => {
      if (value.length > 2 || value.length === 0) {
        changeAllFilters('ingredientNameFilter', value);
      }
    };

    const delayedCallback = useCallback(
      throttle(value => applyFilter(value), 2000),
      [],
    );

    const onChange = (name, value) => {
      setName(value);
      delayedCallback(value);
    };

    useEffect(() => {
      setName(ingredientNameFilter);
    }, [ingredientNameFilter]);

    // const changeDropData = data => {
    //   return data.map(item => {
    //     const ingredientUnit = get(item, 'ingredientUnit')
    //       ? getLocalizedString(`ingredient.unit.${item.ingredientUnit.toLowerCase()}`)
    //       : '';
    //
    //     return {
    //       ...item,
    //       ingredientName: item.ingredientName,
    //       stock: `${Number(item.stock || 0).toFixed(3)} ${ingredientUnit}`,
    //       ingredientExpireTimestamp:
    //         item.ingredientExpireTimestamp === 0
    //           ? '-'
    //           : moment(item.ingredientExpireTimestamp).format('HH:mm DD MMM YYYY'),
    //       supplyTimestamp:
    //         item.supplyTimestamp === 0
    //           ? '-'
    //           : moment(item.supplyTimestamp).format('HH:mm DD MMM YYYY'),
    //       ingredientUnitPrice:
    //         item.ingredientUnitPrice === '0.00'
    //           ? '0.00'
    //           : Number(item.ingredientUnitPrice).toFixed(2),
    //       noClick: { ingredientAmount: true, ingredientTakeAmount: true },
    //       onRowClick: () => {},
    //     };
    //   });
    // };

    // const [activeIds, setActiveIds] = useState({});
    // const [allData, setAllData] = useState([]);

    // const setOpenDropdown = data => {
    //   let newData = [...data];
    //   data.map(item => {
    //     const index = newData.findIndex(i => i.ingredientId == item.ingredientId);
    //     const stockItemsData = newStorageItems.filter(i => i.ingredientId == item.ingredientId);
    //     if (item.dropdown) {
    //       newData.splice(index + 1, 0, changeDropData(stockItemsData)).join();
    //     }
    //   });
    //
    //   return newData;
    // };

    // const combineIngredients = data => {
    //   let obj = {};
    //   data.map(i => {
    //     if (!obj[i.ingredientId]) {
    //       obj[i.ingredientId] = [i];
    //     } else {
    //       obj[i.ingredientId].push(i);
    //     }
    //   });
    //   const getSum = (arr, value) => {
    //     return arr.reduce((a, b) => a + Number(b[value]), 0);
    //   };
    //
    //   const getIngredientUnitPrice = arr => {
    //     return arr.reduce((a, b) => {
    //       return a + (Number(b.ingredientUnitPrice) || 0) * (Number(b.stock) || 0);
    //     }, 0);
    //   };
    //
    //   const arr = Object.values(obj).map(i => {
    //     const stock = toFixedTwo(getSum(i, 'stock'));
    //     if (i.length > 1) {
    //       return {
    //         ...i[0],
    //         stock: stock,
    //         ingredientUnitPrice:
    //           stock <= 0
    //             ? '-'
    //             : getIngredientUnitPrice(i.filter(item => Number(item.stock) > 0)) /
    //                 toFixedTwo(
    //                   getSum(
    //                     i.filter(item => Number(item.stock) > 0),
    //                     'stock',
    //                   ),
    //                 ) || 0,
    //         dropdown: activeIds[i[0].ingredientId] ? activeIds[i[0].ingredientId].active : false,
    //       };
    //     } else {
    //       return {
    //         ...i[0],
    //         dropdown: activeIds[i[0].ingredientId] ? activeIds[i[0].ingredientId].active : false,
    //       };
    //     }
    //   });
    //
    //   return setOpenDropdown(arr);
    // };

    // useEffect(() => {
    //   setAllData(combineIngredients(newStorageItems));
    // }, [newStorageItems]);

    const fetchData = async () => {
      await fetchIngredients();
    };

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      if (storageList.length === 1) {
        changeAllFilters('storageFilters', storageList[0].value);
      }
      // else {
      //   setActiveIds({});
      // }
    }, [storageList, storageFilters, categoryFilters, ingredientNameFilter]);

    // useEffect(() => {
    //   setNewStorageItems(storageItems);
    // }, [storageFilters, storageItems]);

    useEffect(() => {
      loadStorages();
    }, []);

    const currency = CURRENCY_CODE_TO_SIGN[MerchantsGroup.info.currency];

    const canManageSupply = get(User, 'info.inventoryAccessLevel') === INVENTORY_ACCESS_LEVEL.OWNER;
    let tableFormat = fullTableFormat.map(item => {
      const { name, property } = item;

      if (property === 'stockLeftoverValuation') {
        return {
          ...item,
          name: (
            <div className={styles.totalStockLeftoverValuation}>
              {name}, {currency} <span>{totalStockLeftoverValuation}</span>
            </div>
          ),
        };
      }

      return item;
    });

    if (!canManageSupply) {
      tableFormat = tableFormat
        .map(i => {
          if (i.property === 'stock' || i.property === 'ingredientUnitPrice') {
            return undefined;
          } else {
            return i;
          }
        })
        .filter(i => i);
    }

    // const onTableRowClick = async (id, index) => {
    //   if (activeIds[id] && activeIds[id].active) {
    //     const newIndex = allData.findIndex(i => i.ingredientId == id);
    //
    //     const newData = allData.filter((item, ind) => {
    //       return ind !== newIndex + 1;
    //     });
    //     newData[newIndex] = { ...newData[newIndex], dropdown: false };
    //     setAllData([...newData]);
    //     setActiveIds({ ...activeIds, [id]: { active: false } });
    //   } else {
    //     const newIndex = index;
    //     const newData = [...allData];
    //     newData[newIndex] = { ...newData[newIndex], dropdown: true };
    //     newData
    //       .splice(newIndex + 1, 0, {
    //         fullInformation: changeDropData(newStorageItems.filter(i => i.ingredientId == id)),
    //       })
    //       .join();
    //     setAllData(newData);
    //     setActiveIds({ ...activeIds, [id]: { active: true } });
    //   }
    // };

    const renderStock = (item, newStock) => {
      if (Number(item.stock) <= Number(item.stockThreshold)) {
        return (
          <span className={styles.warningIconWrapper}>
            <Popup
              position="bottom right"
              content={
                <FormattedMessage
                  id="storage.stockThreshold.infoMessage"
                  defaultMessage="Stock threshold exceeded"
                />
              }
              trigger={<Icon name="warning circle" color="red" className={styles.warningIcon} />}
            />

            <span>{newStock}</span>
          </span>
        );
      } else {
        return <span style={{ paddingLeft: 10 }}>{newStock}</span>;
      }
    };

    const changeData = data => {
      return data
        .map((item, index) => {
          // if (item.fullInformation) {
          //   return item;
          // } else {
          const ingredientUnit = get(item, 'ingredientUnit')
            ? getLocalizedString(`ingredient.unit.${item.ingredientUnit.toLowerCase()}`)
            : '';

          const newStock = `${Number(item.stock || 0).toFixed(3)} ${ingredientUnit}`;

          if (Number(item.stock) > Number(item.stockThreshold) && lowStock === true) {
            return undefined;
          } else {
            return {
              ...item,
              ingredientName: <div style={{ paddingLeft: 16 }}>{item.ingredientName}</div>,
              newIngredientName: item.ingredientName,
              stock: renderStock(item, newStock),
              newStock: newStock,
              ingredientExpireTimestamp: '-',
              supplyTimestamp: '-',
              ingredientUnitPrice:
                item.ingredientUnitPrice === '-'
                  ? '-'
                  : Number(item.ingredientUnitPrice).toFixed(2),

              noClick: { ingredientAmount: true, ingredientTakeAmount: true },
              // onRowClick: canManageSupply
              //   ? () => onTableRowClick(item.ingredientId, index, item.ingredientName)
              //   : () => {},
            };
            // }
          }
        })
        .filter(i => i);
    };

    const newStorageFilters = storageFilters.length === 0 ? ['all'] : storageFilters;
    const newCategoryFilters =
      categoryFilters.length === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }]
        : [...categoryFilters];

    const sortByName = data =>
      data.sort(
        (a, b) =>
          a.ingredientName && b.ingredientName && a.ingredientName.localeCompare(b.ingredientName),
      );

    const tableData = processTableData(changeData(sortByName(storageItems)));

    const formatDataByIngredients = data => {
      return sortByName(data).map(i => {
        const ingredientUnit = get(i, 'ingredientUnit')
          ? getLocalizedString(`ingredient.unit.${i.ingredientUnit.toLowerCase()}`)
          : '';
        return {
          ...i,
          newStock: `${i.stock} ${ingredientUnit}`,
          newIngredientName: i.ingredientName,
          ingredientUnitPrice: Number(i.ingredientUnitPrice || 0).toFixed(2),
          ingredientExpireTimestamp: i.ingredientExpireTimestamp
            ? moment(i.ingredientExpireTimestamp).format('HH:mm DD MMM YYYY')
            : '',
          supplyTimestamp: i.supplyTimestamp
            ? moment(i.supplyTimestamp).format('HH:mm DD MMM YYYY')
            : '',
        };
      });
    };

    return (
      <div>
        <Form.Group widths="3">
          <Form.Field className={styles.storesInput}>
            <label>
              <FormattedMessage id="storage.dropdown.storage" defaultMessage="Storage" />
            </label>
            <FormattedMessage
              id="storage.dropdown.storage.placeholder"
              defaultMessage="Select storage"
            >
              {placeholder => (
                <Dropdown
                  placeholder={placeholder}
                  fluid
                  search
                  multiple
                  selection
                  options={storageList}
                  name="storageFilters"
                  value={newStorageFilters}
                  onChange={(_event, { name, value }) => changeAllFilters(name, value)}
                />
              )}
            </FormattedMessage>
          </Form.Field>

          <Form.Field>
            <label>
              <FormattedMessage id="storage.dropdown.category" defaultMessage="Category" />
            </label>
            <FormattedMessage
              id="storage.dropdown.category.placeholder"
              defaultMessage="Select category"
            >
              {placeholder => (
                <AsyncDropdown
                  loadOptions={loadCategoryOptions}
                  placeholder={placeholder}
                  value={newCategoryFilters}
                  onChange={value => changeAllFilters('categoryFilters', value)}
                  isMulti
                />
              )}
            </FormattedMessage>
          </Form.Field>

          <Form.Field>
            <label>
              <FormattedMessage
                id="storage.dropdown.ingredient.search"
                defaultMessage="Ingredient name"
              />
            </label>
            <FormattedMessage
              id="storage.dropdown.ingredient.search.placeholder"
              defaultMessage="Ingredient name"
            >
              {placeholder => (
                <SearchResetInput
                  value={name}
                  name="ingredientNameFilter"
                  placeholder={placeholder}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>

        <div className={styles.checkboxWrapper}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox inline checked={lowStock} onChange={() => setLowStock(!lowStock)} />
            <FormattedMessage
              id="storage.stockThreshold.lowStock"
              defaultMessage="Running low on stock"
            />
          </span>

          <DownloadCSVButton data={formatDataByIngredients(storageItems)} headers={headerForCSV} />
        </div>
        <TableWrapper
          key={storageItems.length}
          data={tableData}
          headers={tableFormat}
          sizeable={false}
          loading={loading}
          classNameTable={styles.storageTable}
          // dropdown
        />
      </div>
    );
  }),
);

export default General;
