import React, { useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { getLocalizedString } from 'i18n/utils';
import { CURRENCY_CODE_TO_SIGN } from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { debounce } from 'debounce';
import { routes } from 'routes';
import { toFixedTwo } from 'utils/numbers';
import { withRouter } from 'react-router-dom';
import Modal from 'components/common/modal-info';
import NumericalInput from 'components/common/numerical-input';
import { processTableData } from 'utils/process-table-data';
import TableWrapper from 'components/common/TableWrapper';
import DownloadSVG from '../download-svg';
import IngredientHistory from '../ingredient-history';

const General = inject(
  'InventoryEditor',
  'Notification',
  'MerchantsGroup',
  'Modals',
)(
  observer(
    ({
      InventoryEditor,
      isEdit,
      MerchantsGroup,
      history,
      canManageSupply,
      visibleModal,
      setVisibleModal,
      saveDraft,
    }) => {
      const { inventory, loading, changeStockTakeItems, fullTableFormat } = InventoryEditor;
      const { stockTakeItems, storageId } = inventory;

      const delayedCallback = useCallback(
        debounce(storageId => saveDraft(storageId), 5000),
        [],
      );

      const currency = CURRENCY_CODE_TO_SIGN[MerchantsGroup.info.currency];

      const onChange = (uniqueKey, value) => {
        if (!isEdit) {
          delayedCallback(storageId);
        }

        changeStockTakeItems(uniqueKey, value);
      };

      let tableFormat = [...fullTableFormat];

      if (!canManageSupply) {
        tableFormat = tableFormat
          .map(i => {
            if (i.property === 'ingredientAmount' || i.property === 'differenceAmount') {
              return undefined;
            } else {
              return i;
            }
          })
          .filter(i => i);
      }

      const changeData = data => {
        return data.map(item => {
          const ingredientPrice = `(${toFixedTwo(
            Number(item.ingredientUnitPrice || 0) * Number(item.ingredientAmount || 0),
          ) || 0}${currency})`;
          const differenceAmount =
            Number(item.ingredientTakeAmount) - Number(item.ingredientAmount);
          const ingredientUnit = getLocalizedString(
            `ingredient.unit.${item.ingredientUnit.toLowerCase()}`,
          );

          const differencePrice =
            Number(item.ingredientUnitPrice) === '-'
              ? '-'
              : `${toFixedTwo(differenceAmount * Number(item.ingredientUnitPrice))}${currency}`;

          const ingredientInfoData = {
            ingredientId: item.ingredientId,
            unit: item.ingredientUnit,
          };

          const ingredientTakeAmount =
            item.ingredientTakeAmount === '' ? '' : Number(item.ingredientTakeAmount).toFixed(2);

          const ingredientUnitPrice =
            item.stock <= 0
              ? '-'
              : Number(item.ingredientPrice) / Number(item.ingredientAmount) || '-';

          const newIngredientName = `${item.ingredientName}${
            !canManageSupply ? `(${ingredientUnit})` : ''
          }`;

          const newIngredientTakeAmount = `${toFixedTwo(
            item.ingredientTakeAmount || 0,
          )} ${ingredientUnit}`;

          return {
            ...item,
            ingredientName: <div style={{ paddingLeft: 16, height: 24 }}>{newIngredientName}</div>,
            newIngredientName: newIngredientName,
            ingredientTakeAmount: (
              <NumericalInput
                key={ingredientTakeAmount}
                defaultValue={ingredientTakeAmount}
                onBlur={value => {
                  onChange(item.ingredientId, value);
                }}
              />
            ),
            newIngredientTakeAmount: newIngredientTakeAmount,
            ingredientExpireTimestamp: '',
            supplyTimestamp: '',
            ingredientUnitPrice: ingredientUnitPrice !== '-' ? ingredientUnitPrice.toFixed(2) : '-',
            noClick: { ingredientAmount: true, ingredientTakeAmount: true },
            ingredientAmount: (
              <IngredientHistory
                ingredientData={ingredientInfoData}
                ingredientPrice={ingredientPrice}
                ingredientAmount={item.ingredientAmount}
                ingredientUnit={ingredientUnit}
                ingredientName={item.ingredientName}
                storageId={storageId}
              />
            ),
            newIngredientAmount: `${Number(item.ingredientAmount).toFixed(
              3,
            )} ${ingredientUnit} ${ingredientPrice}`,
            differenceAmount:
              item.ingredientTakeAmount !== ''
                ? `${toFixedTwo(differenceAmount)} ${ingredientUnit} (${differencePrice})`
                : '',
          };
        });
      };

      const sortByName = data =>
        data.sort(
          (a, b) =>
            a.ingredientName &&
            b.ingredientName &&
            a.ingredientName.localeCompare(b.ingredientName),
        );

      const tableData = processTableData(changeData(sortByName(stockTakeItems)));

      return (
        <div>
          <DownloadSVG data={tableData} />
          <TableWrapper
            key={stockTakeItems.length}
            data={tableData}
            headers={tableFormat}
            sizeable={false}
            loading={loading}
          />
          <Modal
            children={
              <FormattedMessage
                id="inventory.modal.stockTakeCompleted"
                defaultMessage="Stock take already completed"
              />
            }
            onClose={() => {
              setVisibleModal(false);
              history.push(routes.inventory);
            }}
            open={visibleModal}
            size="mini"
            minHeight="170"
          />
        </div>
      );
    },
  ),
);

export default withRouter(General);
