import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import { NEW_ROUTE_PREFIX, MOBILE_BREAKPOINT } from 'utils/constants';
import SubHeader from 'components/common/sub-header';
import General from './general';

const SupplierEditor = inject(
  'SupplierEditor',
  'Notification',
  'Supplier',
)(
  observer(({ SupplierEditor, Notification, match, admin }) => {
    const { save, edit, clearValidation } = SupplierEditor;

    const supplierId = match.params && match.params.id;
    const isEdit = supplierId && supplierId !== NEW_ROUTE_PREFIX;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      if (isEdit) {
        edit(supplierId);
      }
      return () => {
        clearValidation();
      };
    }, []);

    const header = isEdit ? (
      <FormattedMessage id="supplier.edit" defaultMessage="Edit supplier" />
    ) : (
      <FormattedMessage id="supplier.create" defaultMessage="Create supplier" />
    );

    const submitForm = useCallback(async () => {
      setDisabled(true);
      await save({ admin, isEdit }).catch(e => {
        Notification.showError(e.message);
      });
      setDisabled(false);
    }, [admin, isEdit]);

    const isMobile = window.innerWidth < 1136;
    const width = window.innerWidth;
    const desktop = width >= MOBILE_BREAKPOINT;

    return (
      <form className="ui form">
        <SubHeader
          title={header}
          button={
            <Button
              type="button"
              primary
              disabled={disabled}
              onClick={submitForm}
              style={{ minWidth: isMobile ? 0 : 180 }}
            >
              <FormattedMessage id="buttons.save" />
            </Button>
          }
        />
        <ErrorBoundary>
          <General desktop={desktop} isEdit={isEdit} />
        </ErrorBoundary>
      </form>
    );
  }),
);

export default SupplierEditor;
