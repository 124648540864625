import React from 'react';
import { inject, observer } from 'mobx-react';

import ErrorBoundary from 'components/common/error-boundary';
import { DeleteModal, WarningModal } from 'components/common/popups';

import { modalTypes } from 'utils/constants';

const ModalProvider = inject('Modals')(
  observer(({ Modals }) => {
    const { hide, openModalType, loading, resolve, context } = Modals;
    const { subject, action, bold, applyText, size } = context;
    const OpenModalType = openModalType;

    return (
      <ErrorBoundary>
        <DeleteModal
          bold={bold}
          subject={subject}
          action={action}
          closeModal={hide}
          isLoading={loading}
          submitModal={resolve}
          applyText={applyText}
          open={openModalType === modalTypes.DELETE}
        />
        <WarningModal
          size={size}
          subject={subject}
          closeModal={hide}
          isLoading={loading}
          submitModal={resolve}
          open={openModalType === modalTypes.WARNING}
        />
        {(typeof openModalType === 'function' || typeof openModalType === 'object') && (
          <OpenModalType closeModal={hide} onClose={hide} {...context} />
        )}
      </ErrorBoundary>
    );
  }),
);

export default ModalProvider;
