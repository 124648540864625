import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FormSection from 'components/common/form-section';

const General = inject(
  'PackingEditor',
  'Dictionary',
)(
  observer(({ PackingEditor, isEdit, Dictionary }) => {
    const { packing, changePacking, validationErrors } = PackingEditor;
    const { ingredientUnits } = Dictionary;

    const { name, unit, amount } = packing;

    const change = (_event, { name, value }) => {
      changePacking(name, value);
    };

    // const unitOptions = [];

    return (
      <FormSection>
        <Form.Group widths="2">
          <Form.Field required error={validationErrors['name']}>
            <label>
              <FormattedMessage id="packing.edit.name" defaultMessage="Name" />
            </label>
            <FormattedMessage id="packing.edit.name.placeholder" defaultMessage="Enter the name">
              {placeholder => (
                <Input
                  fluid
                  type="text"
                  name="name"
                  placeholder={placeholder}
                  maxLength={35}
                  value={name}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field required error={validationErrors['amount']}>
            <label>
              <FormattedMessage id="packing.edit.amount" defaultMessage="Amount" />
            </label>
            <FormattedMessage
              id="packing.edit.amount.placeholder"
              defaultMessage="Enter the amount"
            >
              {placeholder => (
                <Input
                  fluid
                  type="number"
                  name="amount"
                  placeholder={placeholder}
                  value={amount}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field required error={validationErrors['unit']}>
            <label>
              <FormattedMessage id="packing.edit.unit" defaultMessage="Unit" />
            </label>
            <FormattedMessage id="packing.edit.unit.placeholder" defaultMessage="Select unit">
              {placeholder => (
                <Dropdown
                  placeholder={placeholder}
                  fluid
                  search
                  selection
                  options={ingredientUnits}
                  name="unit"
                  value={unit}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default General;
