import React from 'react';
import PropTypes from 'prop-types';
import { General, Option } from './style';

const Switcher = ({ value, onChange, options, disabled, style }) => (
  <General style={style} disabled={disabled}>
    {options.map(option => (
      <Option
        key={option.name}
        selected={value === option.name}
        onClick={() => onChange(option.name)}
      >
        {option.label}
      </Option>
    ))}
  </General>
);

Switcher.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Switcher;
