import StorageAPI from 'api/storage';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import { getLocalizedString } from 'i18n/utils';

const NAME = 'name';

export const ACCOUNTS_TABLE_FORMAT = [
  {
    name: getLocalizedString('storage.data.name'),
    property: NAME,
    variant: ['left'],
  },
];

class StorageStore {
  tableFormat = ACCOUNTS_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  fetchData = async () => {
    const { page, size } = this.paginateData;
    const { items, last, totalPages, totalElements } = await StorageAPI.list(page, size);

    runInAction(() => {
      this.data = items;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };
      this.loading = false;
    });
  };

  @action
  deleteStorage = async id => {
    try {
      await StorageAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
    runInAction(() => {
      this.data = this.data.filter(i => i.id != id);
    });
  };
}

export default StorageStore;
