import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Icon, Button } from 'semantic-ui-react';
import { Sticky, StickyContainer } from 'react-sticky';
import cn from 'classnames';
import styles from './styles.scss';

const SubHeader = withRouter(({ title, button, history, sticky }) => {
  const isMobile = window.innerWidth < 1136;

  return (
    <StickyContainer>
      <Sticky disableCompensation>
        {props => {
          const isSticky = sticky ? isMobile && props.distanceFromTop < 0 : false;

          return (
            <div>
              <div className={cn(styles.subHeaderWrap, isSticky && styles.sticky)}>
                <div className={styles.box}>
                  {isMobile ? (
                    <Button icon type="button" onClick={() => history.goBack()}>
                      <Icon name="chevron left" />
                    </Button>
                  ) : (
                    <Button
                      icon
                      type="button"
                      labelPosition="left"
                      onClick={() => history.goBack()}
                      className="backButton"
                    >
                      <Icon name="left arrow" />
                      <FormattedMessage id="buttons.back" defaultMessage="Back" />
                    </Button>
                  )}

                  <div className={styles.title}>{title}</div>
                </div>
                {button}
              </div>
              {isSticky && <div style={{ height: 74 }} />}
            </div>
          );
        }}
      </Sticky>
    </StickyContainer>
  );
});

SubHeader.propTypes = {
  title: PropTypes.node,
  button: PropTypes.node,
  sticky: PropTypes.bool,
};

SubHeader.defaultProps = {
  sticky: true,
};

export default SubHeader;
