import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const AsyncDropdown = ({ loadOptions, placeholder, value, onChange, isMulti }) => {
  const [page, setPage] = useState(0);

  const newLoadOptions = async (search, prevOptions) => {
    if (search) {
      if (search.length > 2) {
        const data = await loadOptions(search, prevOptions);
        return data;
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    } else {
      const data = await loadOptions(search, prevOptions, page);
      setPage(page + 1);
      return data;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <AsyncPaginate
        placeholder={placeholder}
        value={value}
        loadOptions={newLoadOptions}
        isMulti={isMulti}
        onChange={onChange}
      />
    </div>
  );
};

export default AsyncDropdown;
