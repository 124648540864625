import React, { useEffect, useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import SearchResetInput from 'components/common/inputs/search-reset';
import throttle from 'lodash/throttle';
import { getLocalizedString } from 'i18n/utils';
import { Form, Dropdown } from 'semantic-ui-react';
import { MOBILE_BREAKPOINT } from 'utils/constants';
import useWindowWidthHook from 'utils/hooks/useWindowWidth';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { withRouter } from 'react-router-dom';
import DateRangePicker from 'components/common/DateRangePicker';
import queryString from 'query-string';
import Checkbox from 'components/common/checkbox';
import cn from 'classnames';
import AnalyticsTable from './table';
import styles from './styles.scss';

const Analytics = inject('Analytics')(
  observer(({ Analytics, history }) => {
    const {
      getAnalytics,
      changeAllFilters,
      loadStoragesOptions,
      tableFormat,
      allFilters,
      storagesOptions,
      loadCategoryOptions,
      paginateData,
    } = Analytics;
    const {
      startTimeUtc,
      endTimeUtc,
      storageFilters,
      categoryFilters,
      ingredientNameFilter,
    } = allFilters;

    const fetch = async () => {
      await loadStoragesOptions();
    };

    const width = useWindowWidthHook();
    const isMobile = width < MOBILE_BREAKPOINT;

    useEffect(() => {
      fetch();
    }, []);

    const [allQueryFilters, setAllQueryFilters] = useState(
      history.location.search
        ? JSON.parse(queryString.parse(history.location.search).allQueryFilters)
        : '',
    );

    useEffect(() => {
      if (history.location.search) {
        setAllQueryFilters(JSON.parse(queryString.parse(history.location.search).allQueryFilters));
      }
    }, [history.location.search]);

    useEffect(() => {
      if (allQueryFilters) {
        changeAllFilters('allQueryFilters', allQueryFilters);
      }
    }, [allQueryFilters]);

    useEffect(() => {
      getAnalytics();
    }, [allFilters, paginateData]);

    const changeDate = dates => {
      if (dates) {
        changeAllFilters('startTimeUtc', dates[0]);
        changeAllFilters('endTimeUtc', dates[1]);
      }
    };

    const [name, setName] = useState('');

    const applyFilter = value => {
      if (value.length > 2 || value.length === 0) {
        changeAllFilters('ingredientNameFilter', value);
      }
    };

    const delayedCallback = useCallback(
      throttle(value => applyFilter(value), 2000),
      [],
    );

    const onChange = (name, value) => {
      setName(value);
      delayedCallback(value);
    };

    useEffect(() => {
      setName(ingredientNameFilter);
    }, [ingredientNameFilter]);

    const newStorageFilters = !storageFilters ? ['all'] : storageFilters;
    const newCategoryFilters =
      categoryFilters.length === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }]
        : [...categoryFilters];

    return (
      <div className={cn('ui form', styles.analyticsForm)}>
        <div className={styles.headerWrapper}>
          <Form.Group widths="2" className={styles.formGroup}>
            <Form.Field>
              <Form.Group widths="2" className={styles.formGroup}>
                <Form.Field>
                  <FormattedMessage id="dateFilter.rangeCalendarFilter" defaultMessage="Date range">
                    {label => (
                      <DateRangePicker
                        label={label}
                        date={[new Date(startTimeUtc), new Date(endTimeUtc)]}
                        selectDate={changeDate}
                        onClean={() => changeDate([new Date(), new Date()])}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="analytics.dropdown.storage" defaultMessage="Storage" />
                  </label>
                  <FormattedMessage
                    id="analytics.dropdown.storage.placeholder"
                    defaultMessage="Select storage"
                  >
                    {placeholder => (
                      <Dropdown
                        placeholder={placeholder}
                        fluid
                        search
                        selection
                        options={storagesOptions}
                        name="storageFilters"
                        value={newStorageFilters}
                        onChange={(event, { name, value }) => changeAllFilters(name, value)}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
              </Form.Group>
            </Form.Field>
            <Form.Field>
              <Form.Group widths="2" className={styles.formGroup}>
                <Form.Field>
                  <label>
                    <FormattedMessage id="analytics.dropdown.category" defaultMessage="Category" />
                  </label>
                  <FormattedMessage
                    id="analytics.dropdown.category.placeholder"
                    defaultMessage="Select category"
                  >
                    {placeholder => (
                      <AsyncDropdown
                        loadOptions={loadCategoryOptions}
                        placeholder={placeholder}
                        value={newCategoryFilters}
                        onChange={value => changeAllFilters('categoryFilters', value)}
                        isMulti
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="ingredient.search" />
                  </label>
                  <FormattedMessage id="ingredient.search.plaseholder" defaultMessage="Ingredient">
                    {placeholder => (
                      <SearchResetInput
                        value={name}
                        name="ingredientNameFilter"
                        placeholder={placeholder}
                        onChange={onChange}
                        style={{ width: '100%' }}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>
              </Form.Group>
            </Form.Field>
          </Form.Group>
        </div>
        <div className={styles.checkboxWrapper}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              inline
              name="showOnlyChanged"
              onChange={(evt, { name, checked }) => changeAllFilters(name, checked)}
            />
            <FormattedMessage id="global.showOnlyChanged" defaultMessage="Show Only Changed" />
          </span>
        </div>
        <div>
          <AnalyticsTable tableFormat={tableFormat} isMobile={isMobile} />
        </div>
      </div>
    );
  }),
);

export default withRouter(Analytics);
