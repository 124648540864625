import styled from 'styled-components';

const mobileViewport = '479px';
const tableHoverColor = 'rgba(0, 0, 0, .05)';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${({ noHeader }) => (noHeader ? '100vw' : 'calc(100vw - 140px)')};
  overflow-x: auto;
  white-space: nowrap;

  @media all and (max-width: 990px) {
    max-width: ${({ tableInPopup }) =>
      tableInPopup ? 'calc(100vw - 65px)' : 'calc(100vw - 15px)'};
  }
`;

export const Placeholder = styled.td`
  text-align: center !important;
  color: rgba(40, 40, 40, 0.3);
  border-top: 1px solid #dededf;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  cursor: auto;
  text-align: inherit;
  padding: 0.9em 0.7em;
  vertical-align: inherit;
  font-weight: 700;
  text-transform: none;
  border: 0;
  user-select: none;
  border-radius: 0.28571429rem 0.28571429rem 0 0;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  border: none;
  overflow-x: auto;

  thead th {
    border-left: none;
    color: rgba(0, 0, 0, 0.54);
    vertical-align: inherit;
    border-bottom: 0;
    font-family: Roboto, san-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgb(0, 0, 0);
    white-space: nowrap;
    background-color: #f5f5f5;
  }

  td {
    white-space: nowrap;
    font-size: 0.975rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;

    letter-spacing: 0.01071em;
    vertical-align: inherit;
  }
`;

export const TableRow = styled.tr`
  border-top: 1px solid #ebebeb;
  color: ${({ color }) => (color ? '#a1a1a1' : '#3f3f4b')};
  font-family: Roboto, san-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  height: 35px;
  letter-spacing: normal;
  line-height: 2.14;

  td {
    padding: ${({ thinRow, noPadding }) =>
      noPadding
        ? '0px 40px 0px 16px !important'
        : thinRow
        ? '0px 40px 0px 16px !important'
        : '10px 40px 10px 16px'};
    @media screen and (max-width: ${mobileViewport}) {
      padding: 1.485714vw 1.485714vw !important;
    }
  }

  &:nth-child(even) {
    background: ${({ whiteRow }) => (whiteRow ? 'white' : '#f5f5f5')};
  }

  &:nth-child(odd) {
    background: ${({ whiteRow }) => (whiteRow ? 'white' : '#eff0f1')};
  }
  &:hover {
    background: #fcfcfc;
  }
  &:hover {
    background: ${tableHoverColor} !important;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    touch-action: none;
  }

  @media screen and (max-width: ${mobileViewport}) {
    font-size: 12px;
    height: 30px;
    line-height: 1;

    & > td {
      max-width: 100px;
      padding: 1.485714vw 1.485714vw !important;

      &.nowrap {
        white-space: pre-wrap;
      }
    }
  }
`;

export const HeaderCell = styled.th`
  padding: ${({ thinRow }) => (thinRow ? '0em 1em' : '0.785714em 1em')};
  background-color: ${({ whiteRow }) => (whiteRow ? 'white !important' : '#f5f5f5')};
  text-align: left;

  @media screen and (max-width: ${mobileViewport}) {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.small {
    width: 40px;
  }
`;

export const TableData = styled.td`
  padding: 5px;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'auto')};
  position: relative;
`;

export const PaginateWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const PaginationSizeWrapper = styled.div`
  & > div {
    margin-top: 0;
  }
`;

export const ButtonTriangleActive = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 5.7px;
  position: absolute;
  border-color: transparent transparent transparent #000000;
  @media screen and (min-width: 480px) {
    bottom: ${({ dropdownIconPosition }) =>
      dropdownIconPosition ? `${dropdownIconPosition.desktop}` : '15px'};
    left: 18px;
  }
  @media screen and (max-width: 480px) {
    bottom: ${({ dropdownIconPosition }) =>
      dropdownIconPosition ? `${dropdownIconPosition.mobile}` : '11px'};
    left: 12px;
  }
`;

export const ButtonTriangle = styled(ButtonTriangleActive)`
  -webkit-transform: rotate(90deg);
`;
