import React, { useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import history, { routes } from 'routes';
import { Dropdown, Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { getLocalizedString } from 'i18n/utils';
import FormSection from 'components/common/form-section';
import Switcher from 'components/common/switcher';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import cn from 'classnames';
import MaterialDatePicker from 'components/common/DatePicker';
import { DISABLE_AFTER, DISABLE_BEFORE } from 'utils/constants';
import Modal from 'components/common/modal';
import moment from 'moment';

import styles from './styles.scss';

const Inputs = inject(
  'InventoryEditor',
  'Inventory',
  'User',
  'AccountEditor',
  'Modals',
)(
  observer(({ InventoryEditor, Inventory, isEdit, Modals, saveDraft }) => {
    const {
      inventory,
      changeInventory,
      storageList,
      loadStorages,
      validationErrors,
      fetchIngredients,
      allFilters,
      changeAllFilters,
      loadCategoryOptions,
      removeStockTakeItems,
      inventoryDraft,
      stockTimeTypeList,
    } = InventoryEditor;
    const { data } = Inventory;
    const { storageFilters, categoryFilters } = allFilters;
    const { storageId, comment, businessTimestamp, endOfDayStock, status } = inventory;

    const activeInventory = data.filter(item => item.storageId === storageId).pop();

    const activeBusinessTimestamp = moment(activeInventory?.businessTimestamp)
      .add(1, 'd')
      .endOf('day')
      .valueOf();

    const beforeDate =
      activeInventory && status !== 'ACTIVE' ? activeBusinessTimestamp : DISABLE_BEFORE;

    useEffect(() => {
      if (storageId > 0) {
        fetchIngredients();
      }
    }, [storageFilters, categoryFilters, allFilters.businessTimestamp, allFilters.endOfDayStock]);

    useEffect(() => {
      loadStorages();
    }, []);

    const change = useCallback(async (_event, { name, value }) => {
      changeInventory(name, value);
    }, []);

    const changeDate = useCallback(
      async date => {
        changeInventory('businessTimestamp', date);
        changeAllFilters(
          'businessTimestamp',
          date
            .utc()
            .startOf('day')
            .valueOf(),
        );
        await saveDraft(storageId);
      },
      [storageId, saveDraft],
    );

    const changeStockTime = useCallback(
      async (value, onClose) => {
        changeInventory('endOfDayStock', value);
        changeAllFilters('endOfDayStock', value);
        if (onClose) {
          onClose();
        }
        await saveDraft(storageId);
      },
      [storageId, saveDraft],
    );

    const newCategoryFilters =
      categoryFilters.length === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }]
        : [...categoryFilters];

    const setStorage = useCallback(async (value, onClose) => {
      changeAllFilters('storageFilters', [value]);
      changeInventory('storageId', value);
      removeStockTakeItems();
      if (onClose) {
        onClose();
      }
      await saveDraft(value);
    }, []);

    const showModal = useCallback(value => {
      Modals.show(Modal, {
        buttonOnClick: onClose => setStorage(value, onClose),
        buttonText: (
          <FormattedMessage
            id="inventory.modal.changeStorage.button"
            defaultMessage="Change storage"
          />
        ),
        open: true,
        children: (
          <div className={styles.modalTitle}>
            <FormattedMessage
              id="inventory.modal.changeStorage.description"
              defaultMessage="If you change the storage, all inventory data will be lost."
            />
          </div>
        ),
      });
    }, []);

    const editDraft = (value, onClose) => {
      history.push(`${routes.inventory}/new/${value}`);
      if (onClose) {
        onClose();
      }
    };

    const showModalEditDraft = useCallback(value => {
      Modals.show(Modal, {
        buttonOnClick: onClose => editDraft(value, onClose),
        buttonText: (
          <FormattedMessage
            id="inventory.modal.existDraft.button"
            defaultMessage="Change storage"
          />
        ),
        open: true,
        children: (
          <div className={styles.modalTitle}>
            <FormattedMessage
              id="inventory.modal.existDraft.description"
              defaultMessage="If you change the storage, all inventory data will be lost."
            />
          </div>
        ),
      });
    }, []);

    const changeStorage = useCallback(
      (event, { value }) => {
        const existDraft = Inventory.listDrafts.find(item => item.storageId === value);
        if (inventoryDraft.id > 0) {
          showModal(value);
        } else if (existDraft) {
          showModalEditDraft(existDraft.id);
        } else {
          setStorage(value);
        }
      },
      [inventoryDraft],
    );

    return (
      <FormSection style={{ borderBottom: 'none' }}>
        <Form.Group widths="2">
          <div className={styles.allInputsWrapper}>
            <Form.Group widths="2">
              <Form.Field
                required
                error={validationErrors['storageId']}
                className={styles.storesInput}
              >
                <label>
                  <FormattedMessage id="inventory.stock" defaultMessage="Storage" />
                </label>
                <FormattedMessage
                  id="supply-record.data.storage.placeholder"
                  defaultMessage="Select from storage"
                >
                  {placeholder => (
                    <Dropdown
                      disabled={isEdit}
                      placeholder={placeholder}
                      fluid
                      search
                      selection
                      options={storageList}
                      name="storageFilters"
                      value={storageId}
                      onChange={changeStorage}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage
                    id="ingredient.data.ingredient-category"
                    defaultMessage="Category"
                  />
                </label>
                <FormattedMessage
                  id="storage.dropdown.category.placeholder"
                  defaultMessage="Select category"
                >
                  {placeholder => (
                    <AsyncDropdown
                      loadOptions={loadCategoryOptions}
                      placeholder={placeholder}
                      value={newCategoryFilters}
                      onChange={value => changeAllFilters('categoryFilters', value)}
                      isMulti
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="2" className={styles.inputsWrapper}>
              <Form.Field required error={validationErrors['businessTimestamp']}>
                <label>
                  <FormattedMessage
                    id="supply-record.data.business-timestamp"
                    defaultMessage="Date"
                  />
                </label>
                <MaterialDatePicker
                  className={cn(styles.datePickerWrapper, {
                    [styles.error]: validationErrors['businessTimestamp'],
                  })}
                  fluid
                  date={businessTimestamp === 0 ? new Date() : businessTimestamp}
                  disableBefore={beforeDate}
                  selectDate={changeDate}
                  disableAfter={DISABLE_AFTER}
                  disabled={status === 'ACTIVE' ? true : false}
                />
              </Form.Field>
              <Form.Field required error={validationErrors['businessTimestamp']}>
                <label>
                  <FormattedMessage id="inventory.switcher.stockTime" defaultMessage="Stock time" />
                </label>
                <div className={styles.switcher}>
                  <Switcher
                    onChange={changeStockTime}
                    options={stockTimeTypeList}
                    value={endOfDayStock}
                    disabled={status === 'ACTIVE' ? true : false}
                  />
                </div>
              </Form.Field>
            </Form.Group>
          </div>
          <div className={styles.textareaWrapper}>
            <Form.Field>
              <label>
                <FormattedMessage id="supply-record.data.comment" defaultMessage="Comment" />
              </label>
              <FormattedMessage
                id="supply-item.data.comment.placeholder"
                defaultMessage="Enter comment"
              >
                {placeholder => (
                  <Form.TextArea
                    style={{ width: '100%' }}
                    autoHeight
                    rows={3}
                    maxLength="500"
                    name="comment"
                    placeholder={placeholder}
                    value={comment}
                    onChange={change}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </div>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default Inputs;
