import React from 'react';
import { FormattedMessage } from 'react-intl';
import plusIcon from 'images/icons/add-button-icon.svg';
import whitePlusIcon from 'images/icons/white-add-button-icon.svg';
import styles from '../styles.scss';

const AddButton = ({ onClick, title = '', whiteIcon }) => {
  return (
    <div className={styles.addButtonWrapper}>
      <div onClick={onClick} className={styles.addButton}>
        <img src={whiteIcon ? whitePlusIcon : plusIcon} style={{ paddingRight: 12 }} />
        {title ? (
          title
        ) : (
          <FormattedMessage
            id="supply-record.data.add-field.placeholder"
            defaultMessage="Add field"
          />
        )}
      </div>
    </div>
  );
};

export default AddButton;
