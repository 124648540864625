import moment from 'moment';

export const DEVELOPMENT = 'development';
export const DEMO = 'demo';
export const PRODUCTION = 'production';

export const MOBILE_BREAKPOINT = 991;
export const TABLET_BREAKPOINT = 991;

export const DEFAULT_SEARCH_INPUT_DELAY = 1200;

export const DEFAULT_PAGINATE_SIZES = {
  mobile: 10,
  desktop: 20,
};

export const DATE_FORMAT = 'DD-MM-YYYY';

export const AVAILABLE_PAGINATE_SIZES = [10, 20, 40, 100];

export const NEW_ROUTE_PREFIX = 'new';

export const BLOCKED_MERCHANTS_STATUS = 'INACTIVE';

export const CURRENCY_CODE_TO_SIGN = {
  USD: '$',
  SGD: 'S$',
  CNY: '¥',
  UAH: '₴',
  PHP: '₱',
  RM: 'RM',
  PLN: 'zł',
};

export const IMAGE_PLACEHOLDER =
  'https://orty-public.sgp1.digitaloceanspaces.com/placeholder/merchant_logo_placeholder.png';

export const CURRENCY_CODE_TO_SMS_PRICE = {
  USD: '0.25 $',
  SGD: '0.25 S$',
  CNY: '1 ¥',
  UAH: '0.6 ₴',
  PHP: '10 ₱',
  RM: '0.3 RM',
  PLN: '1 zł',
};

export const REPORT_TIME_FORMAT = 'DD-MM-YYYY HH:mm';

export const IMAGE_DIMENSIONS = {
  LOGO: {
    WIDTH: 256,
    HEIGHT: 256,
  },
  THUMBNAIL: {
    WIDTH: 400,
    HEIGHT: 120,
  },
  PRODUCT: {
    WIDTH: 700,
    HEIGHT: 600,
  },
};

export const SUPPLY = 'SUPPLY';
export const MOVE = 'MOVE';
export const DISPOSAL = 'DISPOSAL';
export const CAST = 'CAST';

export const modalTypes = {
  DELETE: 'DELETE',
  WARNING: 'WARNING',
};

export const INGREDIENT = 'INGREDIENT';
export const HALF_STUFF = 'HALF_STUFF';

export const INVENTORY_ACCESS_LEVEL = {
  EMPLOYEE: 'EMPLOYEE',
  OWNER: 'OWNER',
};

export const DISABLE_BEFORE = moment(1614211200000);
export const DISABLE_AFTER = moment();
