import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal';
import TableWrapper from 'components/common/TableWrapper';
import { toFixedTwo } from 'utils/numbers';
import { formatDate } from 'utils/time';
import styles from './styles.scss';

const IngredientHistoryModal = inject('InventoryEditor')(
  observer(({ unit, InventoryEditor, onClose, open }) => {
    const {
      ingredientAudit: { data, periodStartUtc },
      storageList,
    } = InventoryEditor;

    const processDropTableData = data => {
      return data.map(item => {
        const newToStorageId = storageList.find(i => i.key == item.toStorageId);
        const toStorageId = newToStorageId === undefined ? '' : newToStorageId.text;
        const newFromStorageId = storageList.find(i => i.key == item.fromStorageId);
        const fromStorageId = newFromStorageId === undefined ? '' : newFromStorageId.text;
        return {
          ...item,
          action: (
            <FormattedMessage
              id={`supply-record.data.${item.action}`}
              defaultMessage={item.action}
            />
          ),
          toStorageId,
          fromStorageId,
          amount: `${toFixedTwo(item.amount)} ${unit}`,
        };
      });
    };

    const header = () => {
      if (periodStartUtc === 0 && data.length > 0) {
        return (
          <div className={styles.dateWrapper}>
            <FormattedMessage
              id="ingredient.audit.date.title.noInventory"
              defaultMessage="You did not have an inventory"
            />
          </div>
        );
      }
      if (periodStartUtc > 0 && data.length > 0) {
        return (
          <div className={styles.dateWrapper}>
            <FormattedMessage
              id="ingredient.audit.date.title"
              defaultMessage="Since last inventory:"
            />
            <div className={styles.date}>{formatDate(periodStartUtc)}</div>
          </div>
        );
      }
    };

    return (
      <Modal
        headerTitle={
          <FormattedMessage id="inventory.modal.title" defaultMessage="Ingredient history" />
        }
        open={open}
        onClose={onClose}
        buttonOnClick={onClose}
        buttonText={<FormattedMessage id="modal.ok" defaultMessage="OK" />}
        size="small"
      >
        <div>
          {header()}
          <TableWrapper
            sizeable={false}
            keyProperty="id"
            showPagination={false}
            headers={InventoryEditor.modalTableFormat}
            data={processDropTableData(data || [])}
          />
        </div>
      </Modal>
    );
  }),
);

export default IngredientHistoryModal;
