import React from 'react';
import { Modal as SemanticModal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import style from './styles.scss';

const Modal = ({
  onClose,
  buttonOnClick = onClose,
  buttonText = 'OK',
  children,
  width,
  open,
  size = 'tiny',
  minHeight,
}) => {
  const styles = {
    minHeight: minHeight ? minHeight : '270',
    borderRadius: 12,
    width: width ? width : null,
  };
  return (
    <SemanticModal
      open={open}
      style={styles}
      size={size}
      closeOnDimmerClick={false}
      onClose={onClose}
    >
      <div className={style.modalWrapper}>
        <div>
          <div className={style.contentWrapper}>{children}</div>
        </div>
        <div className={style.buttonWrapper}>
          <Button
            primary
            onClick={buttonOnClick}
            circular={false}
            style={{ fontSize: 16, fontWeight: 500, minWidth: 80 }}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </SemanticModal>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  buttonOnClick: PropTypes.func,
  buttonText: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default Modal;
