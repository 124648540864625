import React, { useEffect, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import { NEW_ROUTE_PREFIX, MOBILE_BREAKPOINT } from 'utils/constants';
import SubHeader from 'components/common/sub-header';
import General from './general';

const StorageEditor = inject(
  'StorageDictionaryEditor',
  'Notification',
  'Storage',
  'User',
)(
  observer(({ StorageDictionaryEditor, Notification, match, admin }) => {
    const { save, edit, clearValidation, storage } = StorageDictionaryEditor;

    const storageId = match.params && match.params.id;
    const isEdit = storageId && storageId !== NEW_ROUTE_PREFIX;
    const { name } = storage;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      if (isEdit) {
        edit(storageId);
      }
      return () => {
        clearValidation();
      };
    }, []);

    const header = isEdit ? (
      <FormattedMessage
        id="storage.edit"
        defaultMessage="Edit storage"
        values={{ storageName: name }}
      />
    ) : (
      <FormattedMessage id="storage.create" defaultMessage="Create storage" />
    );

    const submitForm = useCallback(async () => {
      setDisabled(true);
      await save({ admin, isEdit }).catch(e => {
        Notification.showError(e.message);
      });
      setDisabled(false);
    }, [admin, isEdit]);

    const isMobile = window.innerWidth < 1136;
    const width = window.innerWidth;
    const desktop = width >= MOBILE_BREAKPOINT;

    return (
      <div>
        <form className="ui form">
          <SubHeader
            title={header}
            button={
              <Button
                type="button"
                primary
                disabled={disabled}
                onClick={submitForm}
                style={{ minWidth: isMobile ? 0 : 180 }}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            }
          />
          <ErrorBoundary>
            <General desktop={desktop} isEdit={isEdit} />
          </ErrorBoundary>
        </form>
      </div>
    );
  }),
);

export default StorageEditor;
