import React, { useCallback, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { PropTypes } from 'mobx-react';
import styles from './styles.scss';

const NumericalInput = ({ defaultValue, onBlur, disabled }) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  const onChangeInput = useCallback(e => {
    const value = e.target.value;
    setInputValue(value);
  }, []);

  const blur = () => {
    onBlur(inputValue);
  };

  return (
    <div className={styles.inputWrapper}>
      <Input
        type="number"
        value={inputValue}
        onChange={onChangeInput}
        onBlur={blur}
        disabled={disabled}
      />
    </div>
  );
};

NumericalInput.propTypes = {
  defaultValue: PropTypes.string,
  onBlur: PropTypes.func,
};

export default NumericalInput;
