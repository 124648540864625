import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Segment } from 'semantic-ui-react';
import { IMAGE_DIMENSIONS, IMAGE_PLACEHOLDER } from 'utils/constants';
import { Section } from 'components/common/common-styles';
import { PreviewImageModal } from '../popups/preview-image';

import { Header, Img, Actions, DownloadImage } from './style';

const LOGO_VALIANT = 'logo';

class ImageLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: props.image,
      enableEditMode: false,
      imageModal: false,
    };

    this.file = createRef();
    this.canvas = createRef();
    this.IMAGE_SIZE = IMAGE_DIMENSIONS[props.variant.toUpperCase()];
  }

  uploadPhoto = () => this.file.current.click();

  generateThumbnail = (img, name, cb) => {
    const { WIDTH, HEIGHT } = IMAGE_DIMENSIONS.THUMBNAIL;
    const canvas = new window.CanvasPlus();
    const imageType = 'image/png';

    canvas.importImage(img);

    canvas.resize({
      width: WIDTH,
      height: HEIGHT,
      mode: 'fit',
    });

    canvas.write({ format: 'png', quality: 100 }, function(err, buf) {
      const blob = new Blob([buf], { type: imageType });
      const file = new File([blob], name, {
        type: imageType,
        lastModified: Date.now(),
      });
      cb(file);
    });
  };

  resizeImage = (img, name, cb) => {
    const { variant } = this.props;

    if (window.CanvasPlus) {
      const { WIDTH, HEIGHT } = this.IMAGE_SIZE;
      const canvas = new window.CanvasPlus();
      const self = this;
      const imageType = variant === LOGO_VALIANT ? 'image/png' : 'image/jpeg';

      canvas.importImage(img);
      canvas.render();

      canvas.resize({
        width: WIDTH,
        height: HEIGHT,
        mode: 'fit',
      });

      canvas.write({ format: 'png', quality: 100 }, function(err, buf) {
        const blob = new Blob([buf], { type: 'image/jpeg' });
        const image = URL.createObjectURL(blob);

        self.setState({ image });

        const file = new File([blob], name, {
          type: imageType,
          lastModified: Date.now(),
        });
        cb(file);
      });
    }
  };

  onChange = event => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const image = event.target.files[0];

      reader.onload = fileEvent => {
        this.processImage(fileEvent.target.result, image.name);
      };

      reader.readAsDataURL(image);
    }
  };

  processImage = (src, name) => {
    const { upload } = this.props;
    const img = new Image();

    img.src = src;
    img.onload = () => {
      this.setState({
        enableEditMode: true,
        image: src,
      });

      this.resizeImage(img, name, async file => {
        await this.generateThumbnail(img, name, thumbnailFile => {
          upload({ image: file, thumbnail: thumbnailFile });
        });
      });
    };
  };

  renderImage = () => {
    const { image, enableEditMode } = this.state;
    const { image: externalLink, edit } = this.props;
    const imageLink = edit && !enableEditMode ? externalLink : image;

    return (
      <Fragment>
        {image && (
          <Segment style={{ marginTop: 0 }} placeholder compact onClick={this.openModal}>
            <Img src={imageLink || IMAGE_PLACEHOLDER} className="imageLoaderImage" />
          </Segment>
        )}
      </Fragment>
    );
  };

  removePhoto = () => {
    const { onRemove, upload } = this.props;

    this.setState({
      enableEditMode: true,
      image: IMAGE_PLACEHOLDER,
    });
    upload({ image: '', thumbnail: '' });
    onRemove();
  };

  openModal = () => {
    const { image } = this.props;
    if (image && image !== IMAGE_PLACEHOLDER) this.setState({ imageModal: true });
  };

  closeModal = () => {
    this.setState({ imageModal: false });
  };

  render() {
    const { image, imageModal } = this.state;
    const { heading, onRemove, disabled } = this.props;

    return (
      <Section>
        <Header>{heading}</Header>

        <input type="file" name="image" hidden ref={this.file} onChange={this.onChange} />

        {this.renderImage()}

        {!disabled ? (
          <Actions>
            <Button
              type="button"
              icon
              labelPosition="right"
              onClick={this.uploadPhoto}
              className="imageLoaderButton"
            >
              <Icon name={image ? 'refresh' : 'photo'} />
              {image ? (
                <FormattedMessage id="imageLoader.button.change" defaultMessage="Change" />
              ) : (
                <FormattedMessage id="imageLoader.button.add" defaultMessage="Add" />
              )}
            </Button>

            {image && onRemove && (
              <Button
                type="button"
                icon
                labelPosition="right"
                onClick={this.removePhoto}
                className="imageLoaderButton"
              >
                <Icon name="remove" />
                <FormattedMessage id="imageLoader.button.remove" defaultMessage="Remove" />
              </Button>
            )}
          </Actions>
        ) : this.props.image ? (
          <Actions>
            <DownloadImage
              href={this.props.image}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <Button type="button" icon labelPosition="right">
                <Icon name="download" />
                <FormattedMessage id="imageLoader.button.download" defaultMessage="Download" />
              </Button>
            </DownloadImage>
          </Actions>
        ) : (
          ''
        )}
        <PreviewImageModal
          open={imageModal}
          closeModal={this.closeModal}
          image={this.props.image}
        />
      </Section>
    );
  }
}

ImageLoader.propTypes = {
  heading: PropTypes.node.isRequired,
  upload: PropTypes.func.isRequired,
  image: PropTypes.string,
  edit: PropTypes.bool,
  variant: PropTypes.string,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
};

ImageLoader.defaultProps = {
  image: '',
  thumbnail: '',
  edit: false,
  variant: 'image',
};

export default ImageLoader;
