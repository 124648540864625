import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/supplier';

class SupplierAPI {
  static list = (page = 0, size = 20) => Request.GET(`${ENDPOINT}?page=${page}&size=${size}`);

  static get = id => Request.GET(`${ENDPOINT}/${id}`);

  static update = (id, supplierData) => Request.PUT(`${ENDPOINT}`, supplierData);

  static create = supplierData => Request.POST(ENDPOINT, supplierData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default SupplierAPI;
