import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import TableWrapper from 'components/common/TableWrapper';
import EntryMenu from 'components/common/DataWidget/EntryMenu';
import { AddButton } from 'components/common/buttons';
import { routes } from 'routes';
import { processTableData } from 'utils/process-table-data';

const Accounts = inject('Account')(
  observer(({ Account, history }) => {
    const {
      tableFormat,
      data,
      fetchData,
      deleteAccount,
      loading,
      paginateOptions,
      paginateData,
    } = Account;

    useEffect(() => {
      fetchData();
    }, [paginateData]);

    const addAccount = () => history.push(`${routes.account}/new`);

    const editAccount = accountId => history.push(`${routes.account}/${accountId}`);

    const deleteItem = id => {
      deleteAccount(id);
    };

    const menu = [
      {
        name: <FormattedMessage id="menu.delete" defaultMessage="Delete" />,
        action: deleteItem,
      },
    ];

    const changeData = data => {
      return data.map(item => {
        return {
          ...item,
          onRowClick: () => editAccount(item.id),
          menu: <EntryMenu entry={item} menu={menu} tableCell />,
        };
      });
    };

    return (
      <div>
        <div>
          <AddButton
            text={<FormattedMessage id="storage.add.account" defaultMessage="Add account" />}
            onClick={addAccount}
          />
        </div>
        <div>
          <TableWrapper
            key={data.length}
            data={processTableData(changeData(data))}
            headers={[
              ...tableFormat,
              {
                name: '',
                ignoreTd: true,
                property: 'menu',
                variant: ['nowrap'],
              },
            ]}
            sizeable
            loading={loading}
            showPagination
            paginateOptions={paginateOptions}
            paginateData={paginateData}
          />
        </div>
      </div>
    );
  }),
);

export default withRouter(Accounts);
