import { action, observable, runInAction } from 'mobx';
import AccountAPI from 'api/account';
import history, { routes } from 'routes';
import DictionaryAPI from 'api/dictionary';
import { getLocalizedString } from 'i18n/utils';

const initAccount = {
  id: 0,
  name: '',
  type: 'CASH',
  accountNumber: '',
  currency: 'USD',
};

class Editor {
  constructor(accountStore) {
    this.accountStore = accountStore;
  }

  @observable account = initAccount;
  @observable currencyList = [];
  @observable typeList = [];

  @action
  create = path => {
    history.push(path);
  };

  @action
  changeAccount = (key, value) => {
    this.account[key] = value;
  };

  @action
  loadCurrency = async () => {
    const { items } = await DictionaryAPI.listCurrency();
    runInAction(() => {
      this.currencyList = items.map(item => ({
        value: item.name,
        text: item.name,
        key: item.name,
      }));
    });
  };

  @action
  loadTypes = async () => {
    const { items } = await DictionaryAPI.listAccountTypes();
    runInAction(() => {
      this.typeList = items.map(item => ({
        name: item.name,
        label: getLocalizedString(`account.data.${item.name}`),
      }));
    });
  };

  @action
  edit = async id => {
    runInAction(() => {
      const item = this.accountStore.data.find(item => item.id == id);
      if (item) {
        this.account = { ...item };
      }
    });

    const data = await AccountAPI.get(id);

    runInAction(() => {
      this.account = data;
    });
  };

  @action
  onSubmit = async (account, { isEdit }) => {
    if (isEdit) {
      await AccountAPI.update(account.id, account);
    } else {
      await AccountAPI.create(account);
    }
  };

  @action
  save = async ({ admin, isEdit, merchantGroupId }) => {
    await this.onSubmit({ ...this.account, merchantGroupId }, { isEdit });
    history.push(routes.account);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.account = initAccount;
    this.conditionSelections = [];
  };
}

export default Editor;
