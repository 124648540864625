import styled from 'styled-components';

export const Header = styled.h6`
  margin: 0;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #3f3f4b;
`;

export const Img = styled.img`
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: .3125em;
  position: relative;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
`;

export const Actions = styled.div`
  button {
    width: 100%;
    &:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

export const DownloadImage = styled.a`
  display: block;
  margin-top: 14px;
`;
