import { FormattedMessage } from 'react-intl';
import React from 'react';

const SplitIngredientLoadOptions = allOptions => {
  const options = (allOptions.options || []).map(i => {
    return {
      ...i,
      label: (
        <div>
          <div style={{ fontSize: 14 }}>{i.label}</div>
          <div style={{ fontSize: 10, color: '#a8a8a8' }}>
            {i.essence === 'intermediateProduct' && (
              <FormattedMessage
                id="ingredientCast.intermediateProduct"
                defaultMessage="Bulk recipe"
              />
            )}
            {i.essence === 'prepack' && (
              <FormattedMessage id="ingredient.data.prepack.title" defaultMessage="Prepack" />
            )}
          </div>
        </div>
      ),
    };
  });
  return { ...allOptions, options };
};

export default SplitIngredientLoadOptions;
