import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Icon } from 'semantic-ui-react';
import Checkbox from 'components/common/checkbox';
import { FormattedMessage } from 'react-intl';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import splitIngredientLoadOptions from 'components/common/split-ingredient-load-options';
import {
  getFullCookingPercent,
  getFullHalfStaffCookingPercent,
} from 'utils/get-full-cooking-persent';
import UnitInput from './unit-input';
import styles from './styles.scss';

const AddIngredient = inject(
  'Notification',
  'IngredientEditor',
)(
  observer(
    ({
      supplyItems,
      changeSupplyRecord,
      fetchIngredientsUnits,
      ingredientsUnitsOptions,
      ingredientsData,
      searchIngredientsData,
      disabled,
      errors = {},
      loadOptions,
      fetchOne,
      currency,
      controlIsMobile,
      isHalfStaff,
      IngredientEditor,
    }) => {
      const [setSupplyItem, onSetSupplyItem] = useState(true);

      const { ingredient, changeTotalPrice } = IngredientEditor;

      useEffect(() => {
        if (!supplyItems.length) {
          changeSupplyRecord([{}]);
        }
        if (supplyItems.length === 1 && setSupplyItem && fetchOne) {
          console.log([
            {
              ingredientId: supplyItems[0].ingredientId,
              ingredientName: supplyItems[0].ingredientName,
              ingredientUnit: supplyItems[0].ingredientUnit,
            },
          ]);
          onSetSupplyItem(false);
        }
      }, [supplyItems, fetchOne]);

      const change = (index, { value }) => {
        const newSupplyItems = [...supplyItems];
        const findIngredient =
          ingredientsData.find(i => i.id === value) ||
          searchIngredientsData.find(i => i.id === value);
        newSupplyItems[index] = {
          ...newSupplyItems[index],
          ingredientId: value,
          ingredientName: findIngredient.name,
          ingredientUnit: findIngredient.unit,
          approxPrice: findIngredient.approxPrice,
          unitWeight: findIngredient.unit === 'KILOGRAM' ? 1000 : findIngredient.unitWeight * 1000,
          wasteRatioPersent: findIngredient.wasteRatio,
          cookingRatioPersent: findIngredient.cookingRatio,
          fryingRatioPersent: findIngredient.fryingRatio,
          stewingRatioPersent: findIngredient.stewingRatio,
          roastingRatioPersent: findIngredient.roastingRatio,
          wasteRatio: false,
          cookingRatio: false,
          fryingRatio: false,
          stewingRatio: false,
          roastingRatio: false,
          ingredientBrutto: '',
          ingredientNetto: '',
          ingredientAmount: '',
          totalApproxPrice: '',
        };

        changeSupplyRecord(newSupplyItems);
      };

      useEffect(() => {
        if (!ingredientsUnitsOptions.length) {
          fetchIngredientsUnits();
        }
      }, [ingredientsUnitsOptions]);

      const changeIngredientAmount = index => e => {
        const ingredientAmount = e.target.value;
        const newSupplyItems = [...supplyItems];
        const oneUnitWeight = newSupplyItems[index].unitWeight / 1000;
        const totalUnitWeight = ingredientAmount * oneUnitWeight;
        const fullCookingPersent = getFullCookingPercent(newSupplyItems[index]);
        const getTotalApproxPrice = ingredient => {
          if (ingredient.ingredientUnit !== 'PCS') {
            return ((ingredientAmount / 1000) * newSupplyItems[index].approxPrice || 0).toFixed(2);
          } else if (ingredient.ingredientUnit === 'PCS') {
            return (ingredientAmount * ingredient.approxPrice).toFixed(2);
          }
        };

        const ingredientBrutto =
          newSupplyItems[index].ingredientUnit !== 'PCS'
            ? totalUnitWeight
            : newSupplyItems[index].unitWeight * ingredientAmount;
        newSupplyItems[index] = {
          ...newSupplyItems[index],
          ingredientAmount: ingredientAmount,
          ingredientBrutto: ingredientBrutto.toFixed(2),
          ingredientNetto: (
            ingredientBrutto - (ingredientBrutto / 100) * fullCookingPersent || 0
          ).toFixed(2),
          totalApproxPrice: getTotalApproxPrice(newSupplyItems[index]),
        };
        changeSupplyRecord(newSupplyItems);
      };

      const changeRatio = (index, name, value) => {
        // const va = e.target.value;
        const newSupplyItems = [...supplyItems];
        const newSupplyItem = {
          ...newSupplyItems[index],
          [name]: value,
        };
        const oneUnitWeight = newSupplyItems[index].unitWeight / 1000;
        const totalUnitWeight =
          newSupplyItems[index].ingredientUnit !== 'PCS'
            ? newSupplyItems[index].ingredientAmount * oneUnitWeight
            : newSupplyItems[index].unitWeight * newSupplyItems[index].ingredientAmount;
        const fullCookingPersent = getFullCookingPercent(newSupplyItem);
        newSupplyItems[index] = {
          ...newSupplyItems[index],
          ingredientBrutto: (totalUnitWeight || 0).toFixed(2),
          ingredientNetto: (
            totalUnitWeight - (totalUnitWeight / 100) * fullCookingPersent || 0
          ).toFixed(2),
          [name]: value,
        };
        changeSupplyRecord(newSupplyItems);
      };

      const add = () => {
        changeSupplyRecord([...supplyItems, {}]);
      };

      const remove = i => {
        const newSupplyItems = [...supplyItems].filter((item, index) => index !== i);
        changeSupplyRecord(newSupplyItems);
      };

      let totalNetto = supplyItems.reduce((acc, item) => {
        return (acc += Number(item.ingredientNetto) || 0);
      }, 0);

      let totalPrice = supplyItems.reduce((acc, item) => {
        return (acc += Number(item.totalApproxPrice) || 0);
      }, 0);

      changeTotalPrice(totalPrice);

      if (isHalfStaff) {
        const fullCookingPercent = getFullHalfStaffCookingPercent(ingredient);
        const priceForOneItem = totalPrice / totalNetto || 0;
        totalPrice = totalNetto * priceForOneItem || 0;
        totalNetto = Number(totalNetto - (totalNetto / 100) * fullCookingPercent);
        changeTotalPrice(totalPrice);
      }

      const isMobile = window.innerWidth < 767;
      if (controlIsMobile || isMobile) {
        return (
          <form className="ui form">
            <div
              className={styles.contentWrapper}
              style={controlIsMobile ? { width: '100%' } : null}
            >
              <div className={styles.rowWrapper}>
                {supplyItems.map(
                  (
                    {
                      ingredientId,
                      ingredientName,
                      ingredientUnit,
                      ingredientAmount,
                      ingredientBrutto,
                      wasteRatio,
                      cookingRatio,
                      fryingRatio,
                      stewingRatio,
                      roastingRatio,
                      ingredientNetto,
                      totalApproxPrice,
                    },
                    index,
                  ) => (
                    <div key={index} className={styles.mobInputWrapper}>
                      <div className={styles.mobInputsWrapper}>
                        <span className={styles.mobFirstInputWrapper}>
                          <Form.Field
                            className={styles.mobInputFirst}
                            error={errors[`ingredientId.${index}`]}
                          >
                            <FormattedMessage
                              id="supply-record.data.ingredient"
                              defaultMessage="Ingredient"
                            >
                              {placeholder => (
                                <AsyncDropdown
                                  style={{ width: '100%' }}
                                  loadOptions={async (search, prevOptions, page) => {
                                    const data = await loadOptions(search, prevOptions, page);
                                    return splitIngredientLoadOptions(data);
                                  }}
                                  placeholder={placeholder}
                                  value={
                                    !ingredientName && !ingredientId
                                      ? null
                                      : { label: ingredientName, value: ingredientId }
                                  }
                                  onChange={value => change(index, value)}
                                />
                              )}
                            </FormattedMessage>
                          </Form.Field>
                          {!disabled && (
                            <div className={styles.mobIconWrapper}>
                              <Icon name="remove" onClick={() => remove(index)} />
                            </div>
                          )}
                        </span>
                        <div className={styles.mobCookingMethodWrapper}>
                          <div className={styles.mobCheckboxWrapper}>
                            <Checkbox
                              inline
                              checked={wasteRatio}
                              onChange={() => changeRatio(index, 'wasteRatio', !wasteRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.wasteRatio"
                              defaultMessage="Waste"
                            />
                          </div>
                          <div className={styles.mobCheckboxWrapper}>
                            <Checkbox
                              inline
                              checked={cookingRatio}
                              onChange={() => changeRatio(index, 'cookingRatio', !cookingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.cookingRatio"
                              defaultMessage="Cooking"
                            />
                          </div>
                          <div className={styles.mobCheckboxWrapper}>
                            <Checkbox
                              inline
                              checked={fryingRatio}
                              onChange={() => changeRatio(index, 'fryingRatio', !fryingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.fryingRatio"
                              defaultMessage="Frying"
                            />
                          </div>
                          <div className={styles.mobCheckboxWrapper}>
                            <Checkbox
                              inline
                              checked={stewingRatio}
                              onChange={() => changeRatio(index, 'stewingRatio', !stewingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.stewingRatio"
                              defaultMessage="Stewing"
                            />
                          </div>
                          <div className={styles.mobCheckboxWrapper}>
                            <Checkbox
                              inline
                              checked={roastingRatio}
                              onChange={() => changeRatio(index, 'roastingRatio', !roastingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.roastingRatio"
                              defaultMessage="Roasting"
                            />
                          </div>
                        </div>
                        <div className={styles.inpursRowWrapper}>
                          <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount">
                            {placeholder => (
                              <UnitInput
                                value={ingredientAmount}
                                onChange={changeIngredientAmount(index)}
                                className={styles.mobileInputs}
                                unit={ingredientUnit}
                                placeholder={placeholder}
                                unitTitle={
                                  <FormattedMessage
                                    id={`ingredientCard.unit.${ingredientUnit &&
                                      ingredientUnit.toLowerCase()}`}
                                    defaultMessage={`${ingredientUnit &&
                                      ingredientUnit.toLowerCase()}`}
                                  />
                                }
                                error={errors[`ingredientAmount.${index}`]}
                              />
                            )}
                          </FormattedMessage>
                          <FormattedMessage
                            id="ingredient.editor.ingredientBruto"
                            defaultMessage="Bruto"
                          >
                            {placeholder => (
                              <UnitInput
                                value={ingredientBrutto}
                                onChange={() => {}}
                                disabled
                                placeholder={placeholder}
                                className={styles.mobileInputs}
                                unit={ingredientUnit}
                                unitTitle={
                                  <FormattedMessage
                                    id="ingredientCard.unit.kilogram"
                                    defaultMessage="g"
                                  />
                                }
                                error={false}
                              />
                            )}
                          </FormattedMessage>
                        </div>
                        <div className={styles.inpursRowWrapper}>
                          <FormattedMessage
                            id="ingredient.editor.ingredientNetto"
                            defaultMessage="Netto"
                          >
                            {placeholder => (
                              <UnitInput
                                value={ingredientNetto}
                                onChange={() => {}}
                                disabled
                                placeholder={placeholder}
                                className={styles.mobileInputs}
                                unit={ingredientUnit}
                                unitTitle={
                                  <FormattedMessage
                                    id="ingredientCard.unit.kilogram"
                                    defaultMessage="g"
                                  />
                                }
                                error={false}
                              />
                            )}
                          </FormattedMessage>
                          <div className={styles.mobApproxPrice}>
                            <span>{`${currency} ${totalApproxPrice || '0.00'}`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
              <div className={styles.mobTotalCountWrapper}>
                <span className={styles.totalNetto}>
                  <FormattedMessage id="ingredient.editor.output" defaultMessage="Output:" />
                  <span style={{ paddingLeft: 8, paddingRight: 2 }}>
                    {` ${(totalNetto || 0).toFixed(2)}`}
                  </span>
                  <FormattedMessage id="ingredientCard.unit.kilogram" defaultMessage="g" />
                </span>
                <span className={styles.totalPrice}>
                  <FormattedMessage
                    id="ingredient.editor.totalPrice"
                    defaultMessage="Total price:"
                  />
                  <span style={{ paddingLeft: 8 }}>
                    {`${currency} ${(totalPrice || 0).toFixed(2)}`}
                  </span>
                </span>
              </div>
              {!disabled && (
                <div className={styles.addButtonWrapper}>
                  <div onClick={add} className={styles.addButton}>
                    <FormattedMessage
                      id="supply-record.data.add-field.placeholder"
                      defaultMessage="Add ingredient"
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      } else {
        return (
          <form className="ui form">
            <div className={styles.contentWrapper}>
              <div className={styles.headerWrapper}>
                <span className={styles.ingredientTitle}>
                  <FormattedMessage
                    id="supply-record.data.ingredient"
                    defaultMessage="Ingredient"
                  />
                </span>
                <span className={styles.cookingMethodTitle}>
                  <FormattedMessage
                    id="ingredient.editor.cookingMethod"
                    defaultMessage="Cooking method"
                  />
                </span>
                <span className={styles.restTitle}>
                  <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount" />
                </span>
                <span className={styles.restTitle}>
                  <FormattedMessage id="ingredient.editor.ingredientBruto" defaultMessage="Bruto" />
                </span>
                <span className={styles.restTitle}>
                  <FormattedMessage id="ingredient.editor.ingredientNetto" defaultMessage="Netto" />
                </span>
                <span className={styles.priceTitle}>
                  <FormattedMessage id="ingredient.editor.price" defaultMessage="Price" />
                </span>
                <span style={{ width: '2%' }} />
              </div>
              <div className={styles.rowWrapper}>
                {supplyItems.map(
                  (
                    {
                      ingredientId,
                      ingredientName,
                      ingredientUnit,
                      ingredientAmount,
                      ingredientBrutto,
                      wasteRatio,
                      cookingRatio,
                      fryingRatio,
                      stewingRatio,
                      roastingRatio,
                      ingredientNetto,
                      totalApproxPrice,
                    },
                    index,
                  ) => (
                    <div key={index} className={styles.inputWrapper}>
                      <div className={styles.inputsWrapper}>
                        <Form.Field
                          className={styles.inputFirst}
                          error={errors[`ingredientId.${index}`]}
                        >
                          <FormattedMessage
                            id="ingredient.editor.ingredient"
                            defaultMessage="Ingredient"
                          >
                            {placeholder => (
                              <AsyncDropdown
                                style={{ width: '100%' }}
                                loadOptions={async (search, prevOptions, page) => {
                                  const data = await loadOptions(search, prevOptions, page);
                                  return splitIngredientLoadOptions(data);
                                }}
                                placeholder={placeholder}
                                value={{ label: ingredientName, value: ingredientId }}
                                onChange={value => change(index, value)}
                              />
                            )}
                          </FormattedMessage>
                        </Form.Field>
                        <div className={styles.cookingMethodWrapper}>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              inline
                              checked={wasteRatio}
                              onChange={() => changeRatio(index, 'wasteRatio', !wasteRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.wasteRatio"
                              defaultMessage="Waste"
                            />
                          </div>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              inline
                              checked={cookingRatio}
                              onChange={() => changeRatio(index, 'cookingRatio', !cookingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.cookingRatio"
                              defaultMessage="Cooking"
                            />
                          </div>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              inline
                              checked={fryingRatio}
                              onChange={() => changeRatio(index, 'fryingRatio', !fryingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.fryingRatio"
                              defaultMessage="Frying"
                            />
                          </div>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              inline
                              checked={stewingRatio}
                              onChange={() => changeRatio(index, 'stewingRatio', !stewingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.stewingRatio"
                              defaultMessage="Stewing"
                            />
                          </div>
                          <div className={styles.checkboxWrapper}>
                            <Checkbox
                              inline
                              checked={roastingRatio}
                              onChange={() => changeRatio(index, 'roastingRatio', !roastingRatio)}
                            />
                            <FormattedMessage
                              id="ingredient.editor.roastingRatio"
                              defaultMessage="Roasting"
                            />
                          </div>
                        </div>
                        <UnitInput
                          value={ingredientAmount}
                          onChange={changeIngredientAmount(index)}
                          className={styles.inputCenter}
                          unit={ingredientUnit}
                          unitTitle={
                            <FormattedMessage
                              id={`ingredientCard.unit.${ingredientUnit &&
                                ingredientUnit.toLowerCase()}`}
                              defaultMessage={`${ingredientUnit && ingredientUnit.toLowerCase()}`}
                            />
                          }
                          error={errors[`ingredientAmount.${index}`]}
                        />
                        <UnitInput
                          value={ingredientBrutto}
                          onChange={() => {}}
                          disabled
                          className={styles.inputCenter}
                          unit={ingredientUnit}
                          unitTitle={
                            <FormattedMessage
                              id="ingredientCard.unit.kilogram"
                              defaultMessage="g"
                            />
                          }
                          error={false}
                        />
                        <UnitInput
                          value={ingredientNetto}
                          onChange={() => {}}
                          disabled
                          className={styles.inputCenter}
                          unit={ingredientUnit}
                          unitTitle={
                            <FormattedMessage
                              id="ingredientCard.unit.kilogram"
                              defaultMessage="g"
                            />
                          }
                          error={false}
                        />
                        <div className={styles.approxPrice}>
                          {`${currency} ${totalApproxPrice || '0.00'}`}
                        </div>
                      </div>
                      {!disabled && (
                        <div className={styles.iconWrapper}>
                          <Icon name="remove" onClick={() => remove(index)} />
                        </div>
                      )}
                    </div>
                  ),
                )}
              </div>
              <div className={styles.totalCountWrapper}>
                <span className={styles.totalNetto}>
                  <FormattedMessage id="ingredient.editor.output" defaultMessage="Output:" />
                  <span style={{ paddingLeft: 8, paddingRight: 2 }}>
                    {` ${totalNetto.toFixed(2)}`}
                  </span>
                  <FormattedMessage id="ingredientCard.unit.kilogram" defaultMessage="g" />
                </span>
                <span className={styles.totalPrice}>
                  <FormattedMessage
                    id="ingredient.editor.totalPrice"
                    defaultMessage="Total price:"
                  />
                  <span style={{ paddingLeft: 8 }}>{`${currency} ${totalPrice.toFixed(2)}`}</span>
                </span>
              </div>
              {!disabled && (
                <div className={styles.addButtonWrapper}>
                  <div onClick={add} className={styles.addButton}>
                    <FormattedMessage
                      id="supply-record.data.add-field.placeholder"
                      defaultMessage="Add ingredient"
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
        );
      }
    },
  ),
);

export default AddIngredient;
