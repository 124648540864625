import { action, observable, runInAction } from 'mobx';
import IngredientCategoryAPI from 'api/ingredient-category';
import history, { routes } from 'routes';

const initIngredientCategory = {
  id: 0,
  name: '',
};

class Editor {
  constructor(IngredientCategory) {
    this.ingredientsCategorys = IngredientCategory;
  }

  @observable ingredientCategory = initIngredientCategory;

  @action
  create = path => {
    history.push(path);
  };

  @action
  changeIngredientCategory = (key, value) => {
    this.ingredientCategory[key] = value;
  };

  @action
  edit = async id => {
    runInAction(() => {
      const item = this.ingredientsCategorys.data.find(item => item.id == id);
      if (item) {
        this.ingredientCategory = { ...item };
      }
    });

    const data = await IngredientCategoryAPI.get(id);

    runInAction(() => {
      this.ingredientCategory = data;
    });
  };

  @action
  addCategory = async name => {
    const category = await IngredientCategoryAPI.create({ name: name });

    runInAction(() => {
      const haveCategory = this.ingredientsCategorys.data.find(
        categoryItem => categoryItem.name === category.name,
      );
      if (!haveCategory) {
        this.ingredientsCategorys.data.push({ ...category });
      }
    });

    return category;
  };

  @action
  removeCategory = categoryParam => {
    this.ingredientsCategorys.data = this.ingredientsCategorys.data.filter(
      category => category.name !== categoryParam,
    );
  };

  @action
  onSubmit = async (ingredientCategory, { isEdit } = {}) => {
    if (isEdit) {
      await IngredientCategoryAPI.update(ingredientCategory);
    } else {
      await IngredientCategoryAPI.create(ingredientCategory);
    }
  };

  @action
  save = async ({ admin, isEdit, merchantGroupId }) => {
    await this.onSubmit({ ...this.ingredientCategory, merchantGroupId }, { isEdit });
    history.push(routes.ingredientCategory);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.ingredientCategory = initIngredientCategory;
    this.conditionSelections = [];
  };
}

export default Editor;
