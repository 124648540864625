import SupplierAPI from 'api/supplier';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import { getLocalizedString } from 'i18n/utils';

const NAME = 'name';
const TAX_ID = 'taxId';
const MOBILE = 'mobile';
const EMAIL = 'email';
const CONTACT_PERSON = 'contactPerson';

export const SUPPLIERS_TABLE_FORMAT = [
  {
    name: getLocalizedString('supplier.data.name'),
    property: NAME,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supplier.data.tax-id'),
    property: TAX_ID,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supplier.data.mobile'),
    property: MOBILE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supplier.data.email'),
    property: EMAIL,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supplier.data.contact-person'),
    property: CONTACT_PERSON,
    variant: ['left'],
  },
];

class SupplierStore {
  tableFormat = SUPPLIERS_TABLE_FORMAT;
  @observable data = [];
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  fetchData = async () => {
    const { page, size } = this.paginateData;
    const { items, last, totalPages, totalElements } = await SupplierAPI.list(page, size);

    runInAction(() => {
      this.data = items;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
        onPageChange: (page, size) => {
          this.changePaginateData({ page, size });
        },
      };

      this.loading = false;
    });
  };

  @action
  deleteSupplier = async id => {
    try {
      await SupplierAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
    runInAction(() => {
      this.data = this.data.filter(i => i.id !== id);
    });
  };
}

export default SupplierStore;
