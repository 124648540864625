import React from 'react';
import { func, string, node } from 'prop-types';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import styles from './style.scss';

const UploadButton = ({ onChange, text, className, forwardRef }) => (
  <div className={cn(styles.buttonWrapper, className)}>
    <Button
      content={text}
      basic
      icon="upload"
      labelPosition="right"
      onClick={evt => {
        evt.preventDefault();
        forwardRef.current.click();
      }}
    />
    <input
      type="file"
      name="file"
      id="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      ref={forwardRef}
      onChange={onChange}
      className={styles.uploadInput}
    />
  </div>
);

UploadButton.defaultProps = {
  text: <FormattedMessage id="buttons.upload.file" defaultMessage="Upload file" />,
};

UploadButton.propTypes = {
  onChange: func.isRequired,
  text: node,
  className: string,
  forwardRef: node.isRequired,
};

export default UploadButton;
