import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from 'semantic-ui-react';

import { SimpleButtonWrapper } from './style';

const SimpleButton = ({ text, icon, onClick, disabled }) => (
  <SimpleButtonWrapper disabled={disabled}>
    <Button
      icon={icon}
      disabled={disabled}
      labelPosition='right'
      onClick={onClick}
    >
      {icon && (<Icon name={icon} />)}

      {text}
    </Button>
  </SimpleButtonWrapper>
);

SimpleButton.propTypes = {
  text: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

SimpleButton.defaultProps = {
  icon: '',
  disabled: false
};

export default SimpleButton;
