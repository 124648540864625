import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DateRangePicker as Datepicker } from 'rsuite';
import ErrorBoundary from 'components/common/error-boundary';

import { DATE_FORMAT } from 'utils/constants';

import { WrapperRangePicker, Label } from './style';

const defaultDates = [new Date(), new Date()];

const DateRangePicker = ({
  date,
  label,
  fluid,
  selectDate,
  onClean,
  useOuterDate,
  disabledDate,
  ...props
}) => {
  const [ranges, setRange] = useState([...date]);
  let [rangeIndex, setIndex] = useState(0);
  const [resetDates, setResetDates] = useState(true);
  const { afterToday } = Datepicker;

  const handleDateChange = date => {
    ranges[rangeIndex] = date;
    setRange(ranges);
    setIndex(1);
  };

  const submitDatePicker = dates => {
    setIndex(0);
    setResetDates(false);
    setRange(dates);
    selectDate(dates);
  };

  const setInitialDateRanges = () => {
    if (resetDates) {
      setRange([...defaultDates]);
    }
  };

  const resetRanges = () => {
    setRange([...defaultDates]);
    onClean();
  };

  return (
    <ErrorBoundary>
      <WrapperRangePicker fluid={fluid}>
        <Label>{label}</Label>
        <Datepicker
          appearance="default"
          cleanable
          value={date}
          preventOverflow
          onClean={resetRanges}
          format={DATE_FORMAT}
          ranges={useOuterDate ? date : ranges}
          onSelect={handleDateChange}
          onOk={submitDatePicker}
          onClose={setInitialDateRanges}
          disabledDate={disabledDate ? disabledDate : afterToday()}
          {...props}
        />
      </WrapperRangePicker>
    </ErrorBoundary>
  );
};

DateRangePicker.propTypes = {
  selectDate: PropTypes.func.isRequired,
  fluid: PropTypes.bool,
  date: PropTypes.arrayOf(PropTypes.string),
  onClean: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  useOuterDate: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  disabled: false,
  useOuterDate: false,
  label: '',
  onClean: () => {},
  fluid: false,
  date: [...defaultDates],
};

export default DateRangePicker;
