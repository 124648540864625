import { action, observable, runInAction } from 'mobx';
import StorageAPI from 'api/storage';
import history, { routes } from 'routes';

const initStorage = {
  id: 0,
  name: '',
  allowNegativeStock: true,
};

class Editor {
  constructor(storageStore, userStore) {
    this.storageStore = storageStore;
    this.userStore = userStore;
  }
  @observable storage = initStorage;
  @action
  create = path => {
    history.push(path);
  };

  @action
  changeStorage = (key, value) => {
    this.storage[key] = value;
  };

  @action
  edit = async id => {
    runInAction(() => {
      const item = this.storageStore.data.find(item => item.id == id);
      if (item) {
        this.storage = { ...item };
      }
    });

    const data = await StorageAPI.get(id);

    runInAction(() => {
      this.storage = data;
    });
  };

  @action
  onSubmit = async (storage, { isEdit }) => {
    if (isEdit) {
      await StorageAPI.update(storage.id, storage);
    } else {
      await StorageAPI.create(storage);
      await this.userStore.refreshToken();
    }
  };

  @action
  save = async ({ isEdit, merchantGroupId }) => {
    await this.onSubmit({ ...this.storage, merchantGroupId }, { isEdit });
    history.push(routes.storageDictionary);
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
    this.customersSelections = [];
    this.storage = initStorage;
    this.conditionSelections = [];
  };
}

export default Editor;
