import React from 'react';
import { inject, observer } from 'mobx-react';

import burger from 'images/burger.svg';
import close from 'images/close.svg';

import { MobileMenuButton } from './style';

const MenuButton = inject('MobileMenu')(
  observer(({ MobileMenu }) => (
    <MobileMenuButton onClick={MobileMenu.toggle}>
      <img src={MobileMenu.visible ? close : burger} alt="Menu" />
    </MobileMenuButton>
  )),
);

export default MenuButton;
