import { observable, action, computed, runInAction } from 'mobx';

import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';

class Pagination {
  @observable page = 0;
  @observable INITIAL_PAGINATION_PAGE = 0;
  @observable paginationSizes = DEFAULT_PAGINATE_SIZES;
  @observable currentSize = DEFAULT_PAGINATE_SIZES.desktop;
  
  @computed get sizes () {
    return this.paginationSizes;
  }
  
  @computed get size () {
    return this.currentSize;
  }

  @computed get initialPage () {
    return this.INITIAL_PAGINATION_PAGE;
  }
  
  @action
  changePaginationSize = size => {
    runInAction(() => {
      this.currentSize = size;
    });
  };

  @action
  changePaginationPage = page => {
    runInAction(() => {
      this.page = page;
    });
  };
}

export default Pagination;
