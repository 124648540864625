import React, { useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';
import IngredientHistoryModal from './modal';
import styles from './styles.scss';

const IngredientInfoModal = inject(
  'InventoryEditor',
  'Modals',
)(
  observer(
    ({
      ingredientAmount,
      ingredientPrice,
      ingredientUnit,
      ingredientData,
      InventoryEditor,
      Modals,
      ingredientName,
      storageId,
    }) => {
      const { ingredientId } = ingredientData;
      const [loading, setLoading] = useState(false);

      const fetchData = async () => {
        setLoading(true);
        await InventoryEditor.getAudit(ingredientData);
        setLoading(false);
      };

      // const showIngredientHistoryModal = async () => {
      //   if (ingredientId > 0) {
      //     await fetchData();
      //     Modals.show(IngredientHistoryModal, { unit: ingredientUnit, open: !loading });
      //   }
      // };

      const goToHistory = useCallback(() => {
        const allQueryFilters = {
          startTimeUtc: moment().subtract(14, 'd'),
          endTimeUtc: moment(),
          storageFilters: storageId,
          categoryFilters: [],
          ingredientNameFilter: ingredientName,
        };
        const url = `${window.location.origin}/analytics?allQueryFilters=${JSON.stringify(
          allQueryFilters,
        )}`;
        window.open(url, '_blank');
      }, [window, ingredientName, storageId, moment]);

      return (
        <div className={styles.historyWrapper}>
          {`${Number(ingredientAmount).toFixed(3)} ${ingredientUnit} ${ingredientPrice}`}
          {/*<div onClick={showIngredientHistoryModal} className={styles.history}>*/}
          {/*  <FormattedMessage id="ingredient.data.history" defaultMessage="History" />*/}
          {/*  <div className={styles.loaderWrapper}>*/}
          {/*    {loading && <Loader inverted size="mini" active />}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div onClick={goToHistory} className={styles.history}>
            <FormattedMessage id="ingredient.data.history" defaultMessage="History" />
          </div>
        </div>
      );
    },
  ),
);

export default IngredientInfoModal;

// <Spinner active={loading} size="mini" />
