import { getLocalizedString } from 'i18n/utils';
import moment from 'moment';

export const initInventory = {
  businessTimestamp: moment(new Date())
    .utc()
    .startOf('day')
    .valueOf(),
  comment: '',
  createdTimestamp: 0,
  id: 0,
  merchantGroupId: 0,
  status: '',
  stockTakeItems: [],
  storageId: '',
  storageName: '',
  updatedTimestamp: 0,
  userId: 0,
  userName: '',
  endOfDayStock: false,
};

export const initAllFilters = {
  storageFilters: [],
  categoryFilters: [],
  ingredientNameFilter: '',
  businessTimestamp: moment(new Date())
    .utc()
    .startOf('day')
    .valueOf(),
  endOfDayStock: false,
};

const INGREDIENT_NAME = 'ingredientName';
const INGREDIENT_AMOUNT = 'ingredientAmount';
const INGREDIENT_TAKE_AMOUNT = 'ingredientTakeAmount';
const INGREDIENT_CATEGORY_NAME = 'ingredientCategoryName';
const PRICE = 'ingredientUnitPrice';
const DIFFERENCE_AMOUNT = 'differenceAmount';

const START_DAY = false;
const END_DAY = true;

export const VALIDATION_SCHEMA = {
  storageId: { numericality: true },
};

export const MODAL_TABLE_FORMAT = [
  {
    name: getLocalizedString('inventory.modal.table.type'),
    property: 'action',
    variant: ['left'],
  },
  {
    name: getLocalizedString('inventory.modal.table.count'),
    property: 'amount',
    variant: ['left'],
  },
  {
    name: getLocalizedString('inventory.modal.table.fromStorage'),
    property: 'fromStorageId',
    variant: ['left'],
  },
  {
    name: getLocalizedString('inventory.modal.table.toStorage'),
    property: 'toStorageId',
    variant: ['left'],
  },
];

export const TABLE_FORMAT = [
  {
    name: getLocalizedString('ingredient.data.name'),
    property: INGREDIENT_NAME,
    variant: ['left'],
    width: '40%',
  },
  {
    name: getLocalizedString('ingredient.data.ingredient-category'),
    property: INGREDIENT_CATEGORY_NAME,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('inventory.pricePerUnit'),
    property: PRICE,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('inventory.planStock'),
    property: INGREDIENT_AMOUNT,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('inventory.remainder'),
    property: INGREDIENT_TAKE_AMOUNT,
    variant: ['left'],
    width: '10%',
  },
  {
    name: getLocalizedString('inventory.differenceAmount'),
    property: DIFFERENCE_AMOUNT,
    variant: ['left'],
    width: '10%',
  },
];

export const CSVHeader = [
  {
    label: getLocalizedString('ingredient.data.name'),
    key: 'newIngredientName',
  },
  {
    label: getLocalizedString('ingredient.data.ingredient-category'),
    key: INGREDIENT_CATEGORY_NAME,
  },
  {
    label: getLocalizedString('inventory.pricePerUnit'),
    key: PRICE,
  },
  {
    label: getLocalizedString('inventory.planStock'),
    key: 'newIngredientAmount',
  },
  {
    label: getLocalizedString('inventory.remainder'),
    key: 'newIngredientTakeAmount',
  },
  {
    label: getLocalizedString('inventory.differenceAmount'),
    key: DIFFERENCE_AMOUNT,
  },
];

export const STOCK_TIME_TYPE_LIST = [
  {
    name: START_DAY,
    label: getLocalizedString('inventory.switcher.stockTime.start'),
  },
  {
    name: END_DAY,
    label: getLocalizedString('inventory.switcher.stockTime.end'),
  },
];
