import styled from 'styled-components';

export const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ececec;
  min-height: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const HeaderTitle = styled.h3`
  margin: 0;
  color: '#3f3f4b';
  font-size: 18px;
  line-height: 1.25 em;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  min-width: 80px;
  margin-bottom: 20px;
`;

export const DropdownWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;
export const CloseIcon = styled.img`
  width: 19px;
  height: 19px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
