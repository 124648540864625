import React from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'rsuite';
import { formatReportDate, getUTCOffset } from 'utils/time';
import moment from 'moment';
import cn from 'classnames';
import styles from '../styles.scss';
import AddButton from '../add-button';

const AddIngredientSupply = inject('SupplyRecordEditor')(
  observer(({ SupplyRecordEditor, isEdit, errors, isMobile, allowEditing }) => {
    const {
      supplyRecord,
      changeSupplyRecord,
      loadOptionsAllIngredients,
      ingredientsAllData,
      searchIngredientsAllData,
    } = SupplyRecordEditor;
    const { supplyItems } = supplyRecord;

    const change = (index, { value }) => {
      const newSupplyItems = [...supplyItems];
      const findIngredient =
        ingredientsAllData.find(i => i.id === value) ||
        searchIngredientsAllData.find(i => i.id === value);
      newSupplyItems[index] = {
        ...newSupplyItems[index],
        ingredientId: value,
        ingredientName: findIngredient.name,
        ingredientUnit: findIngredient.unit,
        ingredientUnitPrice: findIngredient.approxPrice,
        total:
          findIngredient.approxPrice &&
          newSupplyItems[index] &&
          newSupplyItems[index].ingredientAmount
            ? findIngredient.approxPrice * newSupplyItems[index].ingredientAmount
            : 0,
      };

      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const changeIngredientAmount = index => e => {
      const ingredientAmount = e.target.value;
      const newSupplyItems = [...supplyItems];
      newSupplyItems[index] = {
        ...newSupplyItems[index],
        ingredientAmount: ingredientAmount,
        total:
          newSupplyItems[index] && newSupplyItems[index].ingredientUnitPrice && ingredientAmount
            ? newSupplyItems[index].ingredientUnitPrice * ingredientAmount
            : 0,
      };
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const changeIngredientUnitPrice = index => e => {
      const ingredientUnitPrice = e.target.value;
      const newSupplyItems = [...supplyItems];
      newSupplyItems[index] = {
        ...newSupplyItems[index],
        ingredientUnitPrice: ingredientUnitPrice,
      };
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const addIngredient = () => {
      changeSupplyRecord('supplyItems', [...supplyItems, {}]);
    };

    const removeIngredient = i => {
      const newSupplyItems = [...supplyItems].filter((item, index) => index !== i);
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const changeDate = (date, index) => {
      const newSupplyItems = [...supplyItems];
      newSupplyItems[index] = {
        ...newSupplyItems[index],
        expireTimestamp: moment(date),
      };
      changeSupplyRecord('supplyItems', newSupplyItems);
    };

    const customDatepicker = (props, expireTimestamp) => {
      const time = expireTimestamp === 0 || !expireTimestamp;
      return (
        <div {...props} className={styles.datepicker}>
          {time ? (
            <div className={styles.dateTitle}>
              {' '}
              <FormattedMessage
                id="ingredient.specifyExpirationDate"
                defaultMessage="Specify expire date"
              />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <FormattedMessage id="ingredient.expireTimestamp" defaultMessage="Expire date" />:
              <div className={styles.dateTitle}>{formatReportDate(expireTimestamp)}</div>
            </div>
          )}
        </div>
      );
    };

    const newSupplyItems = supplyItems.length === 0 ? [{}] : supplyItems;

    return (
      <div className={styles.contentWrapper}>
        {!!newSupplyItems.length && (
          <div style={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
            <Form.Field
              className={cn(styles.inputHeaderFirst, styles.inputHeaderFirstMove)}
              required
            >
              <label>
                <FormattedMessage
                  id="ingredientCast.intermediateProduct"
                  defaultMessage="Bulk recipe"
                />
              </label>
            </Form.Field>
            <Form.Field className={styles.inputHeaderCenter} required>
              <label style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <FormattedMessage id="supply-record.data.amount" defaultMessage="Amount" />
              </label>
            </Form.Field>

            {isEdit && (
              <Form.Field className={styles.inputHeaderRest}>
                <label style={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <FormattedMessage id="supply-record.data.price" defaultMessage="Price per pc." />
                </label>
              </Form.Field>
            )}

            <div className={styles.padding} />
          </div>
        )}
        {newSupplyItems.map(
          (
            {
              ingredientId,
              ingredientUnit,
              ingredientAmount,
              ingredientUnitPrice,
              expireTimestamp,
              ingredientName,
              id,
            },
            index,
          ) => (
            <div key={index} className={styles.rowWrapper}>
              <Form.Group className={styles.inputWrapper}>
                <Form.Field
                  className={styles.inputFirst}
                  style={{ width: isEdit ? '50%' : '75%' }}
                  error={errors[`ingredientId.${index}`]}
                >
                  {(!isEdit || (isEdit && (!id || id === 0))) && (
                    <FormattedMessage
                      id="supply-record.data.ingredient"
                      defaultMessage="Ingredient"
                    >
                      {placeholder => (
                        <AsyncDropdown
                          loadOptions={(search, prevOptions, page) =>
                            loadOptionsAllIngredients({
                              search,
                              prevOptions,
                              page,
                              type: 'forCast',
                            })
                          }
                          placeholder={placeholder}
                          value={{ label: ingredientName, value: ingredientId }}
                          onChange={value => change(index, value)}
                        />
                      )}
                    </FormattedMessage>
                  )}
                  {(isEdit || id > 0) && (
                    <Dropdown
                      disabled
                      options={[{ value: ingredientId, text: ingredientName }]}
                      value={ingredientId}
                      fluid
                      search
                      selection
                      name="ingredientId"
                      onChange={() => {}}
                    />
                  )}
                </Form.Field>
                <Form.Field
                  className={styles.inputCenter}
                  error={errors[`ingredientAmount.${index}`]}
                  style={{ marginRight: 30 }}
                >
                  <Input
                    disabled={isEdit}
                    value={Number(ingredientAmount)}
                    min="0"
                    type="number"
                    onChange={changeIngredientAmount(index)}
                    label={{
                      basic: true,
                      content: ingredientUnit ? (
                        <FormattedMessage
                          id={`ingredient.unit.${ingredientUnit.toLowerCase()}`}
                          defaultMessage={`${ingredientUnit.toLowerCase()}`}
                        />
                      ) : (
                        <div style={{ width: 18 }} />
                      ),
                    }}
                    labelPosition="right"
                  />
                </Form.Field>

                {isEdit && (
                  <Form.Field
                    className={styles.inputRest}
                    error={errors[`ingredientUnitPrice.${index}`]}
                  >
                    <input
                      disabled
                      value={Number(ingredientUnitPrice)}
                      min="0"
                      type="number"
                      onChange={changeIngredientUnitPrice(index)}
                    />
                  </Form.Field>
                )}

                {(!isEdit || (isEdit && allowEditing)) && (
                  <div className={styles.iconWrapper}>
                    <Icon name="remove" onClick={() => removeIngredient(index)} />
                  </div>
                )}
              </Form.Group>

              <div className={styles.datepickerWrapper}>
                <DatePicker
                  disabled={isEdit && !allowEditing}
                  fluid
                  selectYear
                  cleanable
                  placement="auto"
                  onChange={date => changeDate(date, index)}
                  toggleComponentClass={props => customDatepicker(props, expireTimestamp)}
                  format="YYYY-MM-DD HH:mm:ss"
                  value={expireTimestamp}
                />
                <div className={styles.padding} />
              </div>
            </div>
          ),
        )}
        {(!isEdit || (isEdit && allowEditing)) && (
          <AddButton
            onClick={addIngredient}
            title={
              <FormattedMessage
                id="ingredientCast.addIntermediateProduct"
                defaultMessage="Add bulk recipe"
              />
            }
            whiteIcon={isMobile}
          />
        )}
      </div>
    );
  }),
);

export default AddIngredientSupply;
