import APIConnector from './utils/APIConnector';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/ingredient';

class AnalyticsAPI {
  static getAnalytics = filters => {
    return Request.POST(`${ENDPOINT}/summary`, filters);
  };

  static getAnalyticsBySales = filters => {
    return Request.POST(`${ENDPOINT}/sales/summary`, filters);
  };
}

export default AnalyticsAPI;
