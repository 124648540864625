import React, { useEffect, useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown, Form } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import MaterialDatePicker from 'components/common/DatePicker';
import {
  CURRENCY_CODE_TO_SIGN,
  SUPPLY,
  DISPOSAL,
  MOVE,
  CAST,
  DISABLE_BEFORE,
  DISABLE_AFTER, IMAGE_PLACEHOLDER,
} from 'utils/constants';
import FormSection from 'components/common/form-section';
import Switcher from 'components/common/switcher';
import ImageLoader from 'components/common/ImageLoader';
import cn from 'classnames';
import autoSetOptionItem from 'utils/auto-set-option-item';
import get from 'lodash/get';
import { getLocalizedString } from 'i18n/utils';
import AsyncDropdown from 'components/common/dropdowns/async-dropdown';
import DownloadCSVButton from 'components/common/dowload-csv-button';
import AddIngredientRest from '../add-ingredient-rest';
import AddIngredientSupply from '../add-ingredient-supply';
import AddIngredientCast from '../add-ingredient-cast';
import AccordionWrapper from 'components/common/accordion-wrapper';

import styles from './styles.scss';

const General = inject(
  'SupplyRecordEditor',
  'Notification',
  'MerchantsGroup',
  'Dictionary',
)(
  observer(
    ({
      SupplyRecordEditor,
      isEdit,
      duplicate,
      supplyRecordId,
      Notification,
      MerchantsGroup,
      allowEditing,
      Dictionary,
    }) => {
      const {
        supplyRecord,
        changeSupplyRecord,
        recordTypeList,
        accountList,
        supplierList,
        storageList,
        loadTypes,
        loadStorages,
        loadSuppliers,
        updateImage,
        reasonOptions,
        validationErrors,
        validationSupplyErrors,
        loadAccountsOptions,
        loadSuppliersOptions,
        loadAccounts,
        clearSupplyRecordValidation,
        supplyItemsTotalValue,
        getDataForCSV,
        headersForCSV,
        loadPackOptions,
        supplyTimeTypeList,
      } = SupplyRecordEditor;

      const { currency: currencyValue } = MerchantsGroup.info;
      const { loadIngredientUnits, ingredientUnits } = Dictionary;
      const currency = CURRENCY_CODE_TO_SIGN[currencyValue];

      const isMobile = window.innerWidth < 767;

      const {
        accountId,
        supplierId,
        fromStorageId,
        toStorageId,
        comment,
        reason,
        attachment,
        storageRecordType,
        businessTimestamp,
        supplierName,
        accountName,
        startOfDaySupply,
      } = supplyRecord;

      const change = useCallback(
        (_event, { name, value }) => {
          if (
            storageRecordType === MOVE ||
            (storageRecordType === DISPOSAL && name === 'fromStorageId')
          ) {
            changeSupplyRecord('supplyItems', []);
          }
          if (storageRecordType === MOVE) {
            if (name === 'fromStorageId') {
              if (value === toStorageId) {
                Notification.showError(getLocalizedString('supply-record.error.message'));
              } else {
                changeSupplyRecord(name, value);
              }
            } else if (name === 'toStorageId') {
              if (value === fromStorageId) {
                Notification.showError(getLocalizedString('supply-record.error.message'));
              } else {
                changeSupplyRecord(name, value);
              }
            } else {
              changeSupplyRecord(name, value);
            }
          } else if (storageRecordType === DISPOSAL && name === 'fromStorageId') {
            changeSupplyRecord(name, value);
          } else {
            changeSupplyRecord(name, value);
          }
        },
        [toStorageId, fromStorageId, storageRecordType],
      );

      const onButtonClick = storageRecordType => {
        clearSupplyRecordValidation();
        changeSupplyRecord('storageRecordType', storageRecordType);
      };

      useEffect(() => {
        loadTypes();
        loadIngredientUnits();
        if (isEdit) {
          loadAccounts();
          loadSuppliers();
        } else {
          loadPackOptions();
        }
        loadStorages();
      }, []);

      const [newRecordTypeList, setNewRecordTypeList] = useState(recordTypeList);

      useEffect(() => {
        if (storageList.length < 2) {
          setNewRecordTypeList(recordTypeList.filter(i => i.name !== MOVE));
        } else {
          setNewRecordTypeList(recordTypeList);
        }
      }, [recordTypeList, storageList]);

      useEffect(() => {
        autoSetOptionItem(accountList, 'accountId', changeSupplyRecord);
      }, [accountList]);

      useEffect(() => {
        autoSetOptionItem(supplierList, 'supplierId', changeSupplyRecord);
      }, [supplierList]);

      let accountIdValue = {};
      let supplierIdValue = {};

      const accountObj = accountList.find(i => i.id == accountId);

      accountIdValue = {
        label: get(accountObj, 'name') || accountName ? accountName : undefined,
        value: get(accountObj, 'id') || accountId ? accountId : undefined,
      };

      const supplierObj = supplierList.find(i => i.id == supplierId);
      const name = get(supplierObj, 'name');
      const value = get(supplierObj, 'id');
      supplierIdValue = {
        label: name ? name : supplierName ? supplierName : undefined,
        value: value ? value : supplierId ? supplierId : undefined,
      };

      const dataForCSV = getDataForCSV();

      const renderTotalValue = (
        <div className={styles.totalValueWrapper}>
          <span className={styles.totalValueTitle}>
            <FormattedMessage id="supply-record.data.total" defaultMessage="Total" />
          </span>
          {isEdit && storageRecordType === DISPOSAL && (
            <DownloadCSVButton
              data={dataForCSV}
              headers={headersForCSV}
              style={{ paddingTop: 16 }}
            />
          )}
          <p className={styles.totalValue}>
            {currency}
            {Number(supplyItemsTotalValue).toFixed(2)}
          </p>
        </div>
      );

      const changeDate = useCallback(date => {
        changeSupplyRecord('businessTimestamp', date);
      }, []);

      const changeTimeSupply = useCallback(value => {
        changeSupplyRecord('startOfDaySupply', value);
      }, []);

      return (
        <FormSection style={{ borderBottom: 'none', paddingBottom: 0 }}>
          <div className={styles.inputsWrapper}>
            <div className={styles.leftBlockWrapper}>
              <div
                className={cn(styles.leftWrapper, {
                  [styles.leftBlockWrapperSupply]: storageRecordType === SUPPLY,
                })}
              >
                <Form.Field style={{ paddingLeft: '0.5rem' }}>
                  <label>
                    <FormattedMessage id="supply-record.data.type" defaultMessage="Record type" />
                  </label>
                  <div className={styles.switcherWrapper}>
                    <div className={styles.switcher}>
                      <Switcher
                        onChange={isEdit || duplicate ? () => {} : onButtonClick}
                        options={newRecordTypeList}
                        value={storageRecordType}
                      />
                    </div>
                  </div>
                </Form.Field>
                <Form.Group widths="2" style={{ margin: 0 }}>
                  {storageRecordType !== SUPPLY && storageRecordType !== CAST && (
                    <Form.Field required error={validationErrors['fromStorageId']}>
                      <label>
                        <FormattedMessage
                          id="supply-record.data.from-storage"
                          defaultMessage="Select from storage"
                        />
                      </label>
                      <FormattedMessage
                        id="supply-record.data.storage.placeholder"
                        defaultMessage="Select from storage"
                      >
                        {placeholder => (
                          <Dropdown
                            disabled={isEdit}
                            placeholder={placeholder}
                            fluid
                            search
                            selection
                            options={storageList}
                            name="fromStorageId"
                            value={fromStorageId}
                            onChange={change}
                          />
                        )}
                      </FormattedMessage>
                    </Form.Field>
                  )}
                  {storageRecordType !== DISPOSAL && (
                    <Form.Field required error={validationErrors['toStorageId']}>
                      <label>
                        <FormattedMessage
                          id="supply-record.data.to-storage"
                          defaultMessage="Select to storage"
                        />
                      </label>
                      <FormattedMessage
                        id="supply-record.data.storage.placeholder"
                        defaultMessage="Select to storage"
                      >
                        {placeholder => (
                          <Dropdown
                            disabled={isEdit}
                            placeholder={placeholder}
                            fluid
                            search
                            selection
                            options={storageList}
                            name="toStorageId"
                            value={toStorageId}
                            onChange={change}
                          />
                        )}
                      </FormattedMessage>
                    </Form.Field>
                  )}
                  {storageRecordType === SUPPLY && (
                    <Form.Field
                      error={validationErrors['supplierId']}
                      required={storageRecordType !== SUPPLY}
                    >
                      <label>
                        <FormattedMessage
                          id="supply-record.data.supplier"
                          defaultMessage="Select supplier"
                        />
                      </label>

                      {(!isEdit || (isEdit && allowEditing)) && (
                        <FormattedMessage
                          id="supply-record.data.supplier.placeholder"
                          defaultMessage="Select supplier"
                        >
                          {placeholder => (
                            <AsyncDropdown
                              loadOptions={loadSuppliersOptions}
                              placeholder={placeholder}
                              value={supplierIdValue}
                              onChange={value => {
                                change('', { name: 'supplierId', value: value.value });
                                change('', {
                                  name: 'supplierName',
                                  value: value.label,
                                });
                              }}
                            />
                          )}
                        </FormattedMessage>
                      )}
                      {isEdit && !allowEditing && (
                        <Dropdown
                          disabled={isEdit}
                          options={[{ text: supplierName, value: supplierId }]}
                          value={supplierId}
                          fluid
                          search
                          selection
                        />
                      )}
                    </Form.Field>
                  )}
                </Form.Group>
                {storageRecordType !== SUPPLY && storageRecordType !== CAST && (
                  <AddIngredientRest
                    supplyRecordId={supplyRecordId}
                    errors={validationSupplyErrors}
                    isEdit={isEdit}
                    isMobile={isMobile}
                    allowEditing={allowEditing}
                  />
                )}
                {storageRecordType === SUPPLY && (
                  <AddIngredientSupply
                    supplyRecordId={supplyRecordId}
                    errors={validationSupplyErrors}
                    isEdit={isEdit}
                    currency={currency}
                    isMobile={isMobile}
                    allowEditing={allowEditing}
                    ingredientUnits={ingredientUnits}
                  />
                )}
                {storageRecordType === CAST && (
                  <AddIngredientCast
                    supplyRecordId={supplyRecordId}
                    errors={validationSupplyErrors}
                    isEdit={isEdit}
                    isMobile={isMobile}
                    allowEditing={allowEditing}
                  />
                )}
              </div>
              {storageRecordType && !isMobile && renderTotalValue}
            </div>
            {storageRecordType && isMobile && renderTotalValue}
            <div className={styles.rightWrapper}>
              <Form.Group
                widths={
                  storageRecordType === DISPOSAL || storageRecordType === SUPPLY ? 2 : 'equal'
                }
                style={{ margin: 0 }}
              >
                {storageRecordType === DISPOSAL && (
                  <Form.Field
                    required={storageRecordType !== SUPPLY}
                    error={validationErrors['reason']}
                  >
                    <label>
                      <FormattedMessage
                        id="supply-record.data.reason"
                        defaultMessage="Select reason"
                      />
                    </label>
                    <FormattedMessage
                      id="supply-record.data.reason.placeholder"
                      defaultMessage="Select reason"
                    >
                      {placeholder => (
                        <Dropdown
                          disabled={isEdit && !allowEditing}
                          placeholder={placeholder}
                          fluid
                          search
                          selection
                          options={reasonOptions}
                          name="reason"
                          value={reason}
                          onChange={change}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                )}
                {/*{storageRecordType !== SUPPLY && (*/}
                {/*  <Form.Field>*/}
                {/*    <Form.Field>*/}
                {/*      <label>*/}
                {/*        <FormattedMessage*/}
                {/*          id="supply-record.data.business-timestamp"*/}
                {/*          defaultMessage="Date"*/}
                {/*        />*/}
                {/*      </label>*/}
                {/*      <DatePicker*/}
                {/*        disabled={isEdit && !allowEditing}*/}
                {/*        className={cn(styles.datePickerWrapper, {*/}
                {/*          [styles.error]: validationErrors['businessTimestamp'],*/}
                {/*        })}*/}
                {/*        fluid*/}
                {/*        selectYear*/}
                {/*        cleanable*/}
                {/*        placement="bottomStart"*/}
                {/*        onChange={changeDate}*/}
                {/*        format="YYYY-MM-DD HH:mm"*/}
                {/*        value={businessTimestamp < 2 ? new Date() : businessTimestamp}*/}
                {/*      />*/}
                {/*    </Form.Field>*/}
                {/*  </Form.Field>*/}
                {/*)}*/}
                {/*{storageRecordType === SUPPLY && (*/}
                <Form.Field>
                  <Form.Field>
                    <label>
                      <FormattedMessage
                        id="supply-record.data.business-timestamp"
                        defaultMessage="Date"
                      />
                    </label>
                    <MaterialDatePicker
                      fluid
                      date={businessTimestamp < 2 ? new Date() : businessTimestamp}
                      disableBefore={DISABLE_BEFORE}
                      selectDate={changeDate}
                      disableAfter={DISABLE_AFTER}
                    />
                  </Form.Field>
                </Form.Field>
                {storageRecordType === SUPPLY && (
                  <Form.Field>
                    <label>
                      <FormattedMessage id="supply-record.data.time" defaultMessage="Time" />
                    </label>
                    <div className={styles.switcher}>
                      <Switcher
                        onChange={changeTimeSupply}
                        options={supplyTimeTypeList}
                        value={startOfDaySupply}
                      />
                    </div>
                  </Form.Field>
                )}
                {/*)}*/}
              </Form.Group>
              {storageRecordType === SUPPLY && (
                <AccordionWrapper
                  title={
                    <FormattedMessage
                      id="accordionWrapper.title.payment"
                      defaultMessage="Payment options"
                    />
                  }
                  active={!!accountIdValue.label}
                >
                  <Form.Group widths="equal" fluid>
                    <Form.Field>
                      <label>
                        <FormattedMessage
                          id="supply-record.data.account"
                          defaultMessage="Select account"
                        />
                      </label>
                      {(!isEdit || (isEdit && allowEditing)) && (
                        <FormattedMessage
                          id="supply-record.data.account.placeholder"
                          defaultMessage="Select account"
                        >
                          {placeholder => (
                            <AsyncDropdown
                              loadOptions={loadAccountsOptions}
                              placeholder={placeholder}
                              value={accountIdValue}
                              onChange={value => {
                                change('', { name: 'accountId', value: value.value });
                                change('', { name: 'accountName', value: value.label });
                              }}
                            />
                          )}
                        </FormattedMessage>
                      )}
                      {isEdit && !allowEditing && (
                        <Dropdown
                          disabled={isEdit}
                          options={[{ text: accountName, value: accountId }]}
                          value={accountId}
                          fluid
                          search
                          selection
                        />
                      )}
                    </Form.Field>
                  </Form.Group>
                </AccordionWrapper>
              )}
              <AccordionWrapper
                title={
                  <FormattedMessage
                    id="accordionWrapper.title.memo"
                    defaultMessage="Memo options"
                  />
                }
                active={!!comment || (!!attachment && attachment !== IMAGE_PLACEHOLDER)}
              >
                <Form.Group className={styles.textareaWrapper} style={{ margin: 0 }}>
                  <Form.Field>
                    <label>
                      <FormattedMessage id="supply-record.data.comment" defaultMessage="Comment" />
                    </label>
                    <FormattedMessage
                      id="supply-item.data.comment.placeholder"
                      defaultMessage="Enter comment"
                    >
                      {placeholder => (
                        <Form.TextArea
                          disabled={isEdit && !allowEditing}
                          autoHeight
                          rows={3}
                          maxLength="500"
                          name="comment"
                          placeholder={placeholder}
                          value={comment}
                          onChange={change}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                </Form.Group>
                <Form.Field style={{ width: '100%' }}>
                  <div style={{ marginTop: 14 }} />
                  <label className={styles.imageTitle}>
                    <FormattedMessage id="supply-record.data.image" defaultMessage="Image" />
                  </label>
                  <div className={styles.imageWrapper}>
                    <ImageLoader
                      edit={isEdit}
                      disabled={isEdit && !allowEditing}
                      variant="product"
                      upload={updateImage}
                      image={attachment}
                    />
                  </div>
                </Form.Field>
              </AccordionWrapper>
            </div>
          </div>
        </FormSection>
      );
    },
  ),
);

export default General;
