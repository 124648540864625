const arrayKeys = ['wasteRatio', 'cookingRatio', 'fryingRatio', 'stewingRatio', 'roastingRatio'];

export const getFullCookingPercent = ingredientItem => {
  return (
    (arrayKeys.reduce((acc, item) => {
      if (ingredientItem[item]) {
        return acc + Number(ingredientItem[`${item}Persent`]);
      } else {
        return acc;
      }
    }, 0) || 0) * 100
  );
};

export const getFullHalfStaffCookingPercent = item => {
  let valuesArray = [];
  arrayKeys.map(i => valuesArray.push(item[i]) || 0);
  return valuesArray.reduce((acc, item) => acc + (Number(item) || 0), 0);
};
