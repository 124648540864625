import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getLocalizedString } from 'i18n/utils';
import cn from 'classnames';
import styles from './styles.scss';

const ShowDate = ({ timestamp, className }) => {
  const editDateTable = string => {
    const dataArr = string.split('-');
    const [hoursMinutes, seconds] = dataArr[0].split('_');
    const [date, year] = dataArr[1].split('*');
    const newMonth = getLocalizedString(`month.${moment(timestamp).month()}`);

    return (
      <span className={styles.dateWrapper}>
        <span>
          {hoursMinutes}
          <span style={{ color: '#ababba', marginRight: 4 }}>:{seconds}</span>
        </span>
        <span>{`${date} ${newMonth} ${year}`}</span>
      </span>
    );
  };
  const updatedTimestamp = timestamp === 0 ? '' : moment(timestamp).format('HH:mm_ss - DD*YYYY');
  return (
    <span className={cn(styles.draftUpdatedTimestamp, className)}>
      {updatedTimestamp !== '' && (
        <span>
          <FormattedMessage
            id="ingredient.data.draftUpdatedTimestamp"
            defaultMessage="Date the draft was saved:"
          />
          {editDateTable(updatedTimestamp)}
        </span>
      )}
    </span>
  );
};

export default ShowDate;
