export const getStructuredIngredientOptions = data => {
  return data.map(item => ({
    value: item.id,
    label: item.name,
    essence: item.ingredientRecipeDto
      ? item.prepack
        ? 'prepack'
        : 'intermediateProduct'
      : 'ingredient',
  }));
};
