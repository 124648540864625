import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import Spinner from 'components/common/Spinner';

import { ButtonWrapper, ShadowBox } from './style';

const DownloadButton = ({ text, onClick, disabled, loading }) => (
  <ButtonWrapper>
    <ShadowBox>
      <Button
        icon
        labelPosition="right"
        color="#3e3c3c"
        onClick={onClick}
        disabled={disabled}
      >
        <Icon name="download">
          <Spinner active={loading} size="mini" />
        </Icon>
        {text}
      </Button>
    </ShadowBox>

  </ButtonWrapper>
);

DownloadButton.defaultProps = {
  disabled: false,
  loading: false
};

DownloadButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DownloadButton;
