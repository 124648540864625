import { configure } from 'mobx';
import UserStore from './User';
import NotificationStore from './Notification';
import IngredientStore from './ingredient';
import AnalyticsStore from './analytics';
import IngredientEditorStore from './ingredient/editor';
import StorageStore from './storage';
import StorageDictionaryStore from './storage-dictionary';
import InventoryStore from './inventory';
import InventoryEditorStorage from './inventory/editor';
import StorageDictionaryEditorStore from './storage-dictionary/editor';
import AccountStore from './account';
import AccountEditorStore from './account/editor';
import SupplierStore from './supplier';
import SupplierEditorStore from './supplier/editor';
import PackingStore from './packing';
import PackingEditorStore from './packing/editor';
import SupplyRecordStore from './supply-record';
import SupplyRecordEditorStore from './supply-record/editor';
import IngredientCategoryStore from './ingredientCategory';
import IngredientCategoryEditorStore from './ingredientCategory/editor';
import MerchantStore from './Merchant';
import UI from './ui';
import MerchantsGroupStore from './merchants/MerchantsGroup';
import MerchantEditorStore from './merchants/MerchantEditor';
import PaginationStore from './Pagination';
import NavigationStore from './Navigation';
import DictionaryStore from './dictionary';
import MobileMenuStore from './MobileMenu';

configure({ enforceActions: 'observed' });

const initStores = () => {
  const User = new UserStore();
  const Navigations = new NavigationStore();
  const Notification = new NotificationStore();
  const Ingredient = new IngredientStore();
  const IngredientEditor = new IngredientEditorStore(Ingredient);
  const Storage = new StorageStore();
  const StorageDictionary = new StorageDictionaryStore();
  const StorageDictionaryEditor = new StorageDictionaryEditorStore(StorageDictionary, User);
  const Inventory = new InventoryStore();
  const Account = new AccountStore();
  const AccountEditor = new AccountEditorStore(Account);
  const InventoryEditor = new InventoryEditorStorage(Inventory, AccountEditor);
  const Supplier = new SupplierStore();
  const SupplierEditor = new SupplierEditorStore(Supplier);
  const Packing = new PackingStore();
  const PackingEditor = new PackingEditorStore(Supplier);
  const Merchant = new MerchantStore(User);
  const MerchantsGroup = new MerchantsGroupStore(User, Merchant, Navigations);
  const MerchantEditor = new MerchantEditorStore(MerchantsGroup);
  const SupplyRecord = new SupplyRecordStore();
  const SupplyRecordEditor = new SupplyRecordEditorStore(SupplyRecord, MerchantsGroup);
  const IngredientCategory = new IngredientCategoryStore();
  const IngredientCategoryEditor = new IngredientCategoryEditorStore(IngredientCategory);
  const Pagination = new PaginationStore();
  const Dictionary = new DictionaryStore();
  const MobileMenu = new MobileMenuStore();
  const Analytics = new AnalyticsStore();

  return {
    User,
    Notification,
    Ingredient,
    IngredientEditor,
    Pagination,
    IngredientCategory,
    IngredientCategoryEditor,
    Navigations,
    Merchant,
    MerchantsGroup,
    MerchantEditor,
    Storage,
    Account,
    AccountEditor,
    Supplier,
    SupplierEditor,
    SupplyRecord,
    SupplyRecordEditor,
    Dictionary,
    MobileMenu,
    Inventory,
    InventoryEditor,
    Analytics,
    StorageDictionary,
    StorageDictionaryEditor,
    Packing,
    PackingEditor,
    ...UI,
  };
};

export default initStores;
