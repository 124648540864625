import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import ErrorBoundary from 'components/common/error-boundary';
import { NEW_ROUTE_PREFIX, MOBILE_BREAKPOINT } from 'utils/constants';
import SubHeader from 'components/common/sub-header';
import { withRouter } from 'react-router-dom';
import { routes } from 'routes';
import General from './general';

const IngredientEditor = inject(
  'IngredientEditor',
  'Notification',
  'Ingredient',
  'User',
)(
  observer(({ IngredientEditor, Notification, match, admin, Ingredient, history }) => {
    const { save, edit, clearValidation, getValid } = IngredientEditor;

    const { loadStorages, fetchStockInfo } = Ingredient;
    const [disabled, setDisabled] = useState(false);

    const ingredientId = match.params && match.params.id;
    const isEdit = ingredientId && ingredientId !== NEW_ROUTE_PREFIX;

    useEffect(() => {
      if (isEdit) {
        edit(ingredientId);
      }
      return () => {
        clearValidation();
      };
    }, []);
    const fetch = async () => {
      await loadStorages();
      await fetchStockInfo(match.params.id);
    };

    useEffect(() => {
      fetch();
    }, []);

    const header = isEdit ? (
      <FormattedMessage id="ingredient.data.edit" defaultMessage="Edit ingredient" />
    ) : (
      <FormattedMessage id="ingredient.data.create" defaultMessage="Create ingredient" />
    );

    const submitForm = async () => {
      setDisabled(true);
      const { isValid } = getValid();
      if (isValid) {
        try {
          await save({ isEdit });
          history.push(routes.ingredient);
        } catch (error) {
          Notification.showError(error.message);
        }
      }
      setDisabled(false);
    };

    const isMobile = window.innerWidth < 1136;
    const width = window.innerWidth;
    const desktop = width >= MOBILE_BREAKPOINT;

    return (
      <div>
        <form className="ui form">
          <SubHeader
            title={header}
            button={
              <Button
                type="button"
                primary
                disabled={disabled}
                onClick={submitForm}
                style={{ minWidth: isMobile ? 0 : 180 }}
              >
                <FormattedMessage id="buttons.save" />
              </Button>
            }
          />
          <ErrorBoundary>
            <General desktop={desktop} isEdit={isEdit} />
          </ErrorBoundary>
        </form>
      </div>
    );
  }),
);

export default withRouter(IngredientEditor);
