import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import cn from 'classnames';
import styles from './styles.scss';

const UnitInput = ({
  value,
  className,
  unit,
  unitTitle,
  error,
  onChange,
  disabled,
  placeholder,
}) => {
  return (
    <Form.Field className={cn(className, styles.inputStyles)} error={error}>
      <Input
        value={value}
        min="0"
        placeholder={placeholder}
        type="number"
        style={{ color: 'black' }}
        onChange={onChange}
        disabled={disabled}
        label={{
          basic: true,
          content: unit ? (
            <span style={{ width: 16 }}>{unitTitle}</span>
          ) : (
            <div style={{ width: 16 }} />
          ),
        }}
        labelPosition="right"
      />
    </Form.Field>
  );
};

export default UnitInput;
