import { action, observable, runInAction } from 'mobx';
import DictionaryAPI from 'api/dictionary';
import { getLocalizedString } from 'i18n/utils';

class DictionaryStore {
  @observable ingredientUnits = [];
  @observable ingredientUnitsIngredientCard = [];

  @action
  loadIngredientUnits = async () => {
    const { items } = await DictionaryAPI.listUnits();
    runInAction(() => {
      this.ingredientUnitsIngredientCard = items.map(item => ({
        value: item.name,
        text: getLocalizedString(`ingredientCard.unit.${item.name.toLowerCase()}`),
        key: item.name,
      }));
      this.ingredientUnits = items.map(item => ({
        value: item.name,
        text: getLocalizedString(`ingredient.unit.${item.name.toLowerCase()}`),
        key: item.name,
      }));
    });
  };
}

export default DictionaryStore;
