import styled from 'styled-components';

const simpleButtonShadow = '0 2px 8px 0 rgba(0, 0, 0, 0.1)';

export const ButtonWrapper = styled.div`
  position: relative;
  margin-bottom: 1em;
  margin-right: 3.5px;

  .ui.labeled.button {
    margin-right: 0 !important;
  }
`;

export const ShadowBox = styled.div`
  display: inline-block;
  border-radius: 4px;
  margin-right: 0;
  transition: box-shadow 200ms ease-out;
`;

export const SimpleButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 10px;

  & > .ui.labeled.button {
    border-radius: 2px;
    margin-right: 0 !important;
    box-shadow: ${simpleButtonShadow} !important;
    background-color: ${({ disabled }) =>
      disabled ? 'rgba(156, 156, 162, .59)' : '#fff'} !important;
    padding: 0 30px !important;
    line-height: 36px;
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  margin-left: -8px;

  & > i {
    font-weight: 200;
    margin: 0;
    font-size: 22px !important;
  }

  & p {
    color: #3f3f4b;
    font-size: 16px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
`;
