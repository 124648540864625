import 'whatwg-fetch';
import 'regenerator-runtime/runtime';
import initStores from 'stores';
import setupI18n from 'i18n';
import history from 'routes';
import Intercom from 'thirdparty/Intercom';
import renderApp from 'render';
import { isKybio } from 'utils/env';
import 'thirdparty/canvas-plus';
import smoothscroll from 'smoothscroll-polyfill';
import 'themes/main.scss';
import './semantic.min.css';

// const ADMIN = 'POS_ADMIN';

window.startRequest = () => {};
window.endRequest = () => {};

const initApp = async stores => {
  smoothscroll.polyfill();
  try {
    const { User, MerchantsGroup } = stores;
    await User.authorizeUser();
    if (User.info.id) {
      await MerchantsGroup.fetchMerchantGroupInfo();
    }
  } catch (error) {
    return;
  }
};

const startApp = async () => {
  try {
    const stores = initStores();
    const i18n = setupI18n();
    if (isKybio) {
      Intercom.setup();
    }
    await initApp(stores);

    return renderApp(stores, history, i18n);
  } catch (error) {
    console.error(error);
  }
};

startApp();
