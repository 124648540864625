import React, { Fragment } from 'react';
import { bool, func, any, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useTransition, animated } from 'react-spring';

import { Button, Modal } from 'semantic-ui-react';
import ErrorBoundary from 'components/common/error-boundary';

import animations from 'utils/animations';
import { PopupActions, Content, Text, Bold } from './styles';

const actions = {
  delete: <FormattedMessage id="global.popups.action.delete" defaultMessage="remove" />,
  block: <FormattedMessage id="global.popups.action.block" defaultMessage="block" />,
};

export const DeleteModal = ({
  open,
  bold = true,
  closeModal,
  submitModal,
  isLoading,
  action = 'delete',
  cancelText = <FormattedMessage id="global.popups.delete.decline" defaultMessage="Cancel" />,
  applyText = <FormattedMessage id="global.popups.delete.confirm" defaultMessage="Remove" />,
  subject = '',
}) => {
  const { from, enter, leave } = animations.fadeIn;
  const transitions = useTransition(open, null, {
    from,
    enter,
    leave,
  });

  return transitions.map(({ item, key, props }) => {
    return (
      item && (
        <animated.div key={key} style={props}>
          <ErrorBoundary>
            <Modal open={open} onClose={closeModal} className="popupModal" size="mini">
              <Modal.Content>
                <Content>
                  <Text>
                    <FormattedMessage
                      id="global.popups.remove"
                      defaultMessage="Are you sure you want to"
                    />
                    &nbsp;
                    {actions[action]}
                    &nbsp;
                    {bold ? (
                      <Fragment>
                        {subject && (
                          <Bold>
                            &ldquo;
                            {subject}
                            &ldquo;
                          </Bold>
                        )}
                      </Fragment>
                    ) : (
                      <span>{subject}</span>
                    )}
                    ?
                  </Text>
                  <PopupActions>
                    <Button onClick={closeModal}>{cancelText}</Button>
                    <Button
                      primary
                      type="submit"
                      onClick={submitModal}
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      {applyText}
                    </Button>
                  </PopupActions>
                </Content>
              </Modal.Content>
            </Modal>
          </ErrorBoundary>
        </animated.div>
      )
    );
  });
};

DeleteModal.defaultProps = {
  subject: '',
  bold: true,
  applyText: <FormattedMessage id="global.popups.delete.confirm" defaultMessage="Remove" />,
  cancelText: <FormattedMessage id="global.popups.delete.decline" defaultMessage="Cancel" />,
};

DeleteModal.propTypes = {
  bold: bool,
  subject: any,
  action: string,
  applyText: shape(),
  cancelText: shape(),
  open: bool.isRequired,
  isLoading: bool.isRequired,
  closeModal: func.isRequired,
  submitModal: func.isRequired,
};
