import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash.get';
import Spinner from 'components/common/Spinner';
import { Pagination } from 'semantic-ui-react';
import ErrorBoundary from 'components/common/error-boundary';
import PaginationSize from 'components/common/DataWidget/Table/Filters/PaginationSize';
import HeadingPopup from 'components/common/DataWidget/Table/heading-popup';
import useWindowWidthHook from 'utils/hooks/useWindowWidth';
import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';

import {
  Wrapper,
  Table,
  Placeholder,
  HeaderCell,
  TableRow,
  TableData,
  PaginateWrapper,
  PaginationSizeWrapper,
  ButtonTriangleActive,
  ButtonTriangle,
} from './styles';

const TableWrapper = ({
  children,
  headers = [],
  componentForMobileView,
  data = [],
  loading,
  bordered,
  selectable,
  keyProperty,
  sizeable,
  showPagination,
  paginateOptions,
  mobileBreakpoint,
  thinRow,
  whiteRow,
  dropdown,
  paginateData,
  dropdownIconPosition,
  tableInPopup,
  classNameTable,
}) => {
  const width = useWindowWidthHook();
  const [currentSize, setSize] = useState(DEFAULT_PAGINATE_SIZES.desktop);
  const [currentPage, setPage] = useState(0);

  const controlPage = get(paginateData, 'page');
  const controlSize = get(paginateData, 'size');

  useEffect(() => {
    if (controlPage) {
      setPage(controlPage);
    }
    if (controlSize) {
      setSize(controlSize);
    }
  }, [controlPage, controlSize]);

  const { last, totalPages, totalElements, onPageChange } = paginateOptions;

  const handleChangePage = (event, { activePage = 0 }) => {
    setPage(activePage - 1);
    onPageChange(activePage - 1, currentSize);
  };

  const fetchTableData = size => {
    const { onPageChange } = paginateOptions;
    onPageChange(0, size);
    setSize(size);
  };

  if (componentForMobileView && width < mobileBreakpoint) {
    const Component = componentForMobileView;
    return data.map((item, index) => {
      return (
        <Fragment key={item[keyProperty] || index}>
          <Component headers={headers} data={item} />
          <Spinner active={loading} />
        </Fragment>
      );
    });
  }

  const getRowVariants = (variants = []) => variants.join(' ');

  const renderHeader = (headers = []) => {
    return headers.map(({ id, property, name, className = '', variant, width, tooltipMessage }) => (
      <HeaderCell
        key={id || property}
        className={`${getRowVariants(variant)} ${className}`}
        style={{ width }}
        thinRow={thinRow}
        whiteRow={whiteRow}
      >
        {!tooltipMessage && name}
        {tooltipMessage && (
          <HeadingPopup
            id="-"
            defaultMessage={tooltipMessage.defaultMessage}
            tooltipMessage={tooltipMessage}
          />
        )}
      </HeaderCell>
    ));
  };

  const renderDropTableBody = (headers, data) => {
    if (data && data.length === 0 && !loading) {
      return (
        <Placeholder colSpan={headers.length}>
          <FormattedMessage id="dataWidget.nodata.placeholder" defaultMessage="No Data Available" />
        </Placeholder>
      );
    }

    return (
      Array.isArray(data) &&
      data.map((item, index) => {
        return (
          <TableRow
            className={item.rowClassName || ''}
            key={item}
            color={item.color}
            whiteRow
            thinRow={thinRow}
            noPadding
          >
            {headers.map((i, ind) => {
              return (
                <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
                  {i.ignoreTd ? (
                    item[i.property]
                  ) : (
                    <TableData
                      style={item.style || {}}
                      selectable={selectable}
                      className={`${getRowVariants(i.variant)}`}
                      onClick={value =>
                        get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
                      }
                    >
                      {ind === 0 &&
                        dropdown &&
                        (!item.dropdown ? (
                          <span style={{ paddingLeft: 16 }}>{item[i.property]}</span>
                        ) : (
                          <span>{item[i.property]}</span>
                        ))}
                      {(ind !== 0 || !dropdown) && item[i.property]}
                    </TableData>
                  )}
                </Fragment>
              );
            })}
          </TableRow>
        );
      })
    );
  };

  const renderBody = (headers, data) => {
    if (data && data.length === 0 && !loading) {
      return (
        <Placeholder colSpan={headers.length}>
          <FormattedMessage id="dataWidget.nodata.placeholder" defaultMessage="No Data Available" />
        </Placeholder>
      );
    }

    return (
      Array.isArray(data) &&
      data.map((item, index) => {
        if (item.fullInformation) {
          return renderDropTableBody(headers, item.fullInformation);
        } else {
          return (
            <TableRow
              className={item.rowClassName || ''}
              key={item.stock + index}
              color={item.color}
              thinRow={thinRow}
            >
              {headers.map((i, ind) => {
                return (
                  <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
                    {i.ignoreTd ? (
                      item[i.property]
                    ) : (
                      <TableData
                        style={item.style || {}}
                        selectable={selectable}
                        className={`${getRowVariants(i.variant)}`}
                        onClick={value =>
                          get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
                        }
                      >
                        {ind === 0 &&
                          dropdown &&
                          (!item.dropdown ? (
                            <ButtonTriangleActive dropdownIconPosition={dropdownIconPosition} />
                          ) : (
                            <ButtonTriangle />
                          ))}
                        {item[i.property]}
                      </TableData>
                    )}
                  </Fragment>
                );
              })}
            </TableRow>
          );
        }
      })
    );
  };

  return (
    <>
      <Wrapper tableInPopup={tableInPopup}>
        <ErrorBoundary>
          <Table bordered={bordered} className={classNameTable}>
            <thead>
              <tr>{renderHeader(headers)}</tr>
            </thead>
            <tbody>{renderBody(headers, data)}</tbody>
          </Table>
        </ErrorBoundary>

        {children}

        <Spinner active={loading} />
      </Wrapper>

      <ErrorBoundary>
        {sizeable && (
          <PaginationSizeWrapper>
            <PaginationSize currentSize={currentSize} changePaginationSize={fetchTableData} />
          </PaginationSizeWrapper>
        )}
      </ErrorBoundary>

      <ErrorBoundary>
        {showPagination && totalElements >= currentSize && (
          <PaginateWrapper>
            <Pagination
              activePage={controlPage ? controlPage + 1 : currentPage + 1}
              boundaryRange={0}
              defaultActivePage={currentPage + 1}
              ellipsisItem={null}
              firstItem={null}
              siblingRange={1}
              lastItem={last}
              onPageChange={handleChangePage}
              totalPages={totalPages}
            />
          </PaginateWrapper>
        )}
      </ErrorBoundary>
    </>
  );
};

TableWrapper.defaultProps = {
  children: null,
  bordered: true,
  sizeable: true,
  keyProperty: 'id',
  mobileBreakpoint: '',
  showPagination: true,
  selectable: false,
  componentForMobileView: null,
  paginateOptions: {
    last: false,
    size: 10,
    totalPages: 10,
    totalElements: 10,
    onPageChange: () => {},
  },
};

TableWrapper.propTypes = {
  keyProperty: PropTypes.string,
  bordered: PropTypes.bool,
  mobileBreakpoint: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string.isRequired,
      variant: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    }),
  ).isRequired,
  sizeable: PropTypes.bool,
  selectable: PropTypes.bool,
  showPagination: PropTypes.bool,
  paginateOptions: PropTypes.shape({
    last: PropTypes.bool,
    size: PropTypes.number,
    totalPages: PropTypes.number,
    totalElements: PropTypes.number,
    onPageChange: PropTypes.func,
  }),
  componentForMobileView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  thinRow: PropTypes.bool,
  whiteRow: PropTypes.bool,
  dropdown: PropTypes.bool,
  dropdownIconPosition: PropTypes.number,
};

export default TableWrapper;

// import React, { Fragment, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
// import get from 'lodash.get';
// import Spinner from 'components/common/Spinner';
// import { Pagination } from 'semantic-ui-react';
// import ErrorBoundary from 'components/common/error-boundary';
// import PaginationSize from 'components/common/DataWidget/Table/Filters/PaginationSize';
// import HeadingPopup from 'components/common/DataWidget/Table/heading-popup';
// import useWindowWidthHook from 'utils/hooks/useWindowWidth';
// import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';
//
// import {
//   Wrapper,
//   Table,
//   Placeholder,
//   HeaderCell,
//   TableRow,
//   TableData,
//   PaginateWrapper,
//   PaginationSizeWrapper,
//   ButtonTriangleActive,
//   ButtonTriangle,
// } from './styles';
//
// const TableWrapper = ({
//   children,
//   headers = [],
//   componentForMobileView,
//   data = [],
//   loading,
//   bordered,
//   selectable,
//   keyProperty,
//   sizeable,
//   showPagination,
//   paginateOptions,
//   mobileBreakpoint,
//   thinRow,
//   whiteRow,
//   dropdown,
//   paginateData,
//   dropdownIconPosition,
// }) => {
//   const width = useWindowWidthHook();
//
//   const newPage = get(paginateData, 'page') || 0;
//   const newSize = get(paginateData, 'size') || DEFAULT_PAGINATE_SIZES;
//
//   const { last, totalPages, totalElements, onPageChange } = paginateOptions;
//
//   const handleChangePage = (event, { activePage = 0 }) => {
//     // setPage(activePage - 1);
//     onPageChange(activePage - 1, newSize);
//   };
//
//   const fetchTableData = size => {
//     const { onPageChange } = paginateOptions;
//     onPageChange(0, size);
//   };
//
//   if (componentForMobileView && width < mobileBreakpoint) {
//     const Component = componentForMobileView;
//     return data.map((item, index) => {
//       return (
//         <Fragment key={item[keyProperty] || index}>
//           <Component headers={headers} data={item} />
//           <Spinner active={loading} />
//         </Fragment>
//       );
//     });
//   }
//
//   const getRowVariants = (variants = []) => variants.join(' ');
//
//   const renderHeader = (headers = []) => {
//     return headers.map(({ id, property, name, className = '', variant, width, tooltipMessage }) => (
//       <HeaderCell
//         key={id || property}
//         className={`${getRowVariants(variant)} ${className}`}
//         style={{ width }}
//         thinRow={thinRow}
//         whiteRow={whiteRow}
//       >
//         {!tooltipMessage && name}
//         {tooltipMessage && (
//           <HeadingPopup
//             id="-"
//             defaultMessage={tooltipMessage.defaultMessage}
//             tooltipMessage={tooltipMessage}
//           />
//         )}
//       </HeaderCell>
//     ));
//   };
//
//   const renderDropTableBody = (headers, data) => {
//     if (data && data.length === 0 && !loading) {
//       return (
//         <Placeholder colSpan={headers.length}>
//           <FormattedMessage id="dashboard.productMix.noData" defaultMessage="No Data Available" />
//         </Placeholder>
//       );
//     }
//
//     return (
//       Array.isArray(data) &&
//       data.map((item, index) => {
//         return (
//           <TableRow
//             className={item.rowClassName || ''}
//             key={item}
//             color={item.color}
//             whiteRow
//             thinRow={thinRow}
//             noPadding
//           >
//             {headers.map((i, ind) => {
//               return (
//                 <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
//                   {i.ignoreTd ? (
//                     item[i.property]
//                   ) : (
//                     <TableData
//                       style={item.style || {}}
//                       selectable={selectable}
//                       className={`${getRowVariants(i.variant)}`}
//                       onClick={value =>
//                         get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
//                       }
//                     >
//                       {ind === 0 &&
//                         dropdown &&
//                         (!item.dropdown ? (
//                           <span style={{ paddingLeft: 16 }}>{item[i.property]}</span>
//                         ) : (
//                           <span>{item[i.property]}</span>
//                         ))}
//                       {(ind !== 0 || !dropdown) && item[i.property]}
//                     </TableData>
//                   )}
//                 </Fragment>
//               );
//             })}
//           </TableRow>
//         );
//       })
//     );
//   };
//
//   const renderBody = (headers, data) => {
//     if (data && data.length === 0 && !loading) {
//       return (
//         <Placeholder colSpan={headers.length}>
//           <FormattedMessage id="dashboard.productMix.noData" defaultMessage="No Data Available" />
//         </Placeholder>
//       );
//     }
//
//     return (
//       Array.isArray(data) &&
//       data.map((item, index) => {
//         if (item.fullInformation) {
//           return renderDropTableBody(headers, item.fullInformation);
//         } else {
//           return (
//             <TableRow
//               className={item.rowClassName || ''}
//               key={item}
//               color={item.color}
//               thinRow={thinRow}
//             >
//               {headers.map((i, ind) => {
//                 return (
//                   <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
//                     {i.ignoreTd ? (
//                       item[i.property]
//                     ) : (
//                       <TableData
//                         style={item.style || {}}
//                         selectable={selectable}
//                         className={`${getRowVariants(i.variant)}`}
//                         onClick={value =>
//                           get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
//                         }
//                       >
//                         {ind === 0 &&
//                           dropdown &&
//                           (!item.dropdown ? (
//                             <ButtonTriangleActive dropdownIconPosition={dropdownIconPosition} />
//                           ) : (
//                             <ButtonTriangle />
//                           ))}
//                         {item[i.property]}
//                       </TableData>
//                     )}
//                   </Fragment>
//                 );
//               })}
//             </TableRow>
//           );
//         }
//       })
//     );
//   };
//
//   return (
//     <>
//       <Wrapper>
//         <ErrorBoundary>
//           <Table bordered={bordered}>
//             <thead>
//               <tr>{renderHeader(headers)}</tr>
//             </thead>
//             <tbody>{renderBody(headers, data)}</tbody>
//           </Table>
//         </ErrorBoundary>
//
//         {children}
//
//         <Spinner active={loading} />
//       </Wrapper>
//
//       <ErrorBoundary>
//         {sizeable && (
//           <PaginationSizeWrapper>
//             <PaginationSize
//               key={newSize}
//               currentSize={newSize}
//               changePaginationSize={fetchTableData}
//             />
//           </PaginationSizeWrapper>
//         )}
//       </ErrorBoundary>
//
//       <ErrorBoundary>
//         {showPagination && totalElements >= newSize && (
//           <PaginateWrapper>
//             <Pagination
//               key={newPage}
//               activePage={newPage + 1}
//               boundaryRange={0}
//               defaultActivePage={newPage + 1}
//               ellipsisItem={null}
//               firstItem={null}
//               siblingRange={1}
//               lastItem={last}
//               onPageChange={handleChangePage}
//               totalPages={totalPages}
//             />
//           </PaginateWrapper>
//         )}
//       </ErrorBoundary>
//     </>
//   );
// };
//
// // TableWrapper.defaultProps = {
// //   children: null,
// //   bordered: true,
// //   sizeable: true,
// //   keyProperty: 'id',
// //   mobileBreakpoint: '',
// //   showPagination: true,
// //   selectable: false,
// //   componentForMobileView: null,
// //   paginateOptions: {
// //     last: false,
// //     size: 10,
// //     totalPages: 10,
// //     totalElements: 10,
// //     onPageChange: () => {},
// //   },
// // };
//
// TableWrapper.propTypes = {
//   keyProperty: PropTypes.string,
//   bordered: PropTypes.bool,
//   mobileBreakpoint: PropTypes.number,
//   loading: PropTypes.bool.isRequired,
//   children: PropTypes.oneOfType([PropTypes.object]),
//   paginateData: PropTypes.object,
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   headers: PropTypes.arrayOf(
//     PropTypes.shape({
//       property: PropTypes.string.isRequired,
//       variant: PropTypes.arrayOf(PropTypes.string),
//       name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
//     }),
//   ).isRequired,
//   sizeable: PropTypes.bool,
//   selectable: PropTypes.bool,
//   showPagination: PropTypes.bool,
//   paginateOptions: PropTypes.shape({
//     last: PropTypes.bool,
//     size: PropTypes.number,
//     totalPages: PropTypes.number,
//     totalElements: PropTypes.number,
//     onPageChange: PropTypes.func,
//   }),
//   componentForMobileView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
//   thinRow: PropTypes.bool,
//   whiteRow: PropTypes.bool,
//   dropdown: PropTypes.bool,
//   dropdownIconPosition: PropTypes.number,
// };
//
// export default TableWrapper;
//
// //
// //
// import React, { Fragment, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
// import get from 'lodash.get';
// import Spinner from 'components/common/Spinner';
// import { Pagination } from 'semantic-ui-react';
// import ErrorBoundary from 'components/common/error-boundary';
// import PaginationSize from 'components/common/DataWidget/Table/Filters/PaginationSize';
// import HeadingPopup from 'components/common/DataWidget/Table/heading-popup';
// import useWindowWidthHook from 'utils/hooks/useWindowWidth';
// import { DEFAULT_PAGINATE_SIZES } from 'utils/constants';
//
// import {
//   Wrapper,
//   Table,
//   Placeholder,
//   HeaderCell,
//   TableRow,
//   TableData,
//   PaginateWrapper,
//   PaginationSizeWrapper,
//   ButtonTriangleActive,
//   ButtonTriangle,
// } from './styles';
//
// const TableWrapper = ({
//   children,
//   headers = [],
//   componentForMobileView,
//   data = [],
//   loading,
//   bordered,
//   selectable,
//   keyProperty,
//   sizeable,
//   showPagination,
//   paginateOptions,
//   mobileBreakpoint,
//   thinRow,
//   whiteRow,
//   dropdown,
//   controlPage,
//   dropdownIconPosition,
// }) => {
//   const width = useWindowWidthHook();
//   const [currentSize, setSize] = useState(DEFAULT_PAGINATE_SIZES.desktop);
//   const [currentPage, setPage] = useState(0);
//
//   useEffect(() => {
//     if (controlPage) {
//       setPage(controlPage);
//     }
//   }, [controlPage]);
//
//   const { last, totalPages, totalElements, onPageChange } = paginateOptions;
//
//   const handleChangePage = (event, { activePage = 0 }) => {
//     setPage(activePage - 1);
//     onPageChange(activePage - 1, currentSize);
//   };
//
//   const fetchTableData = size => {
//     const { onPageChange } = paginateOptions;
//     onPageChange(0, size);
//     setSize(size);
//   };
//
//   if (componentForMobileView && width < mobileBreakpoint) {
//     const Component = componentForMobileView;
//     return data.map((item, index) => {
//       return (
//         <Fragment key={item[keyProperty] || index}>
//           <Component headers={headers} data={item} />
//           <Spinner active={loading} />
//         </Fragment>
//       );
//     });
//   }
//
//   const getRowVariants = (variants = []) => variants.join(' ');
//
//   const renderHeader = (headers = []) => {
//     return headers.map(({ id, property, name, className = '', variant, width, tooltipMessage }) => (
//       <HeaderCell
//         key={id || property}
//         className={`${getRowVariants(variant)} ${className}`}
//         style={{ width }}
//         thinRow={thinRow}
//         whiteRow={whiteRow}
//       >
//         {!tooltipMessage && name}
//         {tooltipMessage && (
//           <HeadingPopup
//             id="-"
//             defaultMessage={tooltipMessage.defaultMessage}
//             tooltipMessage={tooltipMessage}
//           />
//         )}
//       </HeaderCell>
//     ));
//   };
//
//   const renderDropTableBody = (headers, data) => {
//     if (data && data.length === 0 && !loading) {
//       return (
//         <Placeholder colSpan={headers.length}>
//           <FormattedMessage id="dashboard.productMix.noData" defaultMessage="No Data Available" />
//         </Placeholder>
//       );
//     }
//
//     return (
//       Array.isArray(data) &&
//       data.map((item, index) => {
//         return (
//           <TableRow
//             className={item.rowClassName || ''}
//             key={item.id}
//             color={item.color}
//             whiteRow
//             thinRow={thinRow}
//             noPadding
//           >
//             {headers.map((i, ind) => {
//               return (
//                 <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
//                   {i.ignoreTd ? (
//                     item[i.property]
//                   ) : (
//                     <TableData
//                       style={item.style || {}}
//                       selectable={selectable}
//                       className={`${getRowVariants(i.variant)}`}
//                       onClick={value =>
//                         get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
//                       }
//                     >
//                       {ind === 0 &&
//                         dropdown &&
//                         (!item.dropdown ? (
//                           <span style={{ paddingLeft: 16 }}>{item[i.property]}</span>
//                         ) : (
//                           <span>{item[i.property]}</span>
//                         ))}
//                       {(ind !== 0 || !dropdown) && item[i.property]}
//                     </TableData>
//                   )}
//                 </Fragment>
//               );
//             })}
//           </TableRow>
//         );
//       })
//     );
//   };
//
//   const renderBody = (headers, data) => {
//     if (data && data.length === 0 && !loading) {
//       return (
//         <Placeholder colSpan={headers.length}>
//           <FormattedMessage id="dashboard.productMix.noData" defaultMessage="No Data Available" />
//         </Placeholder>
//       );
//     }
//
//     return (
//       Array.isArray(data) &&
//       data.map((item, index) => {
//         if (item.fullInformation) {
//           return renderDropTableBody(headers, item.fullInformation);
//         } else {
//           return (
//             <TableRow
//               className={item.rowClassName || ''}
//               key={item.id}
//               color={item.color}
//               thinRow={thinRow}
//             >
//               {headers.map((i, ind) => {
//                 return (
//                   <Fragment key={item[keyProperty] + i.property || `${i.property}_${index}`}>
//                     {i.ignoreTd ? (
//                       item[i.property]
//                     ) : (
//                       <TableData
//                         style={item.style || {}}
//                         selectable={selectable}
//                         className={`${getRowVariants(i.variant)}`}
//                         onClick={value =>
//                           get(item, `noClick[${i.property}]`) ? {} : item.onRowClick(value)
//                         }
//                       >
//                         {ind === 0 &&
//                           dropdown &&
//                           (!item.dropdown ? (
//                             <ButtonTriangleActive dropdownIconPosition={dropdownIconPosition} />
//                           ) : (
//                             <ButtonTriangle />
//                           ))}
//                         {item[i.property]}
//                       </TableData>
//                     )}
//                   </Fragment>
//                 );
//               })}
//             </TableRow>
//           );
//         }
//       })
//     );
//   };
//
//   return (
//     <>
//       <Wrapper>
//         <ErrorBoundary>
//           <Table bordered={bordered}>
//             <thead>
//               <tr>{renderHeader(headers)}</tr>
//             </thead>
//             <tbody>{renderBody(headers, data)}</tbody>
//           </Table>
//         </ErrorBoundary>
//
//         {children}
//
//         <Spinner active={loading} />
//       </Wrapper>
//
//       <ErrorBoundary>
//         {sizeable && (
//           <PaginationSizeWrapper>
//             <PaginationSize currentSize={currentSize} changePaginationSize={fetchTableData} />
//           </PaginationSizeWrapper>
//         )}
//       </ErrorBoundary>
//
//       <ErrorBoundary>
//         {showPagination && totalElements >= currentSize && (
//           <PaginateWrapper>
//             <Pagination
//               activePage={controlPage ? controlPage + 1 : currentPage + 1}
//               boundaryRange={0}
//               defaultActivePage={currentPage + 1}
//               ellipsisItem={null}
//               firstItem={null}
//               siblingRange={1}
//               lastItem={last}
//               onPageChange={handleChangePage}
//               totalPages={totalPages}
//             />
//           </PaginateWrapper>
//         )}
//       </ErrorBoundary>
//     </>
//   );
// };
//
// TableWrapper.defaultProps = {
//   children: null,
//   bordered: true,
//   sizeable: true,
//   keyProperty: 'id',
//   mobileBreakpoint: '',
//   showPagination: true,
//   selectable: false,
//   componentForMobileView: null,
//   paginateOptions: {
//     last: false,
//     size: 10,
//     totalPages: 10,
//     totalElements: 10,
//     onPageChange: () => {},
//   },
// };
//
// TableWrapper.propTypes = {
//   keyProperty: PropTypes.string,
//   bordered: PropTypes.bool,
//   mobileBreakpoint: PropTypes.number,
//   loading: PropTypes.bool.isRequired,
//   children: PropTypes.oneOfType([PropTypes.object]),
//   data: PropTypes.arrayOf(PropTypes.object).isRequired,
//   headers: PropTypes.arrayOf(
//     PropTypes.shape({
//       property: PropTypes.string.isRequired,
//       variant: PropTypes.arrayOf(PropTypes.string),
//       name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
//     }),
//   ).isRequired,
//   sizeable: PropTypes.bool,
//   selectable: PropTypes.bool,
//   showPagination: PropTypes.bool,
//   paginateOptions: PropTypes.shape({
//     last: PropTypes.bool,
//     size: PropTypes.number,
//     totalPages: PropTypes.number,
//     totalElements: PropTypes.number,
//     onPageChange: PropTypes.func,
//   }),
//   componentForMobileView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
//   thinRow: PropTypes.bool,
//   whiteRow: PropTypes.bool,
//   dropdown: PropTypes.bool,
//   dropdownIconPosition: PropTypes.number,
// };
//
// export default TableWrapper;
