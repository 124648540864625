import APIConnector from './utils/APIConnector';
import { extractFiltersQuery } from './utils/helpers';

const Request = APIConnector.instance;
const ENDPOINT = '/supply/stock-take-record';

class InventoryAPI {
  static list = (page = 0, size = 20, filters) => {
    const queryFilters = extractFiltersQuery(filters);
    return Request.GET(`${ENDPOINT}?page=${page}&size=${size}${queryFilters}`);
  };

  static getDraft = () => Request.GET(`${ENDPOINT}/draft`);

  static getAllDrafts = () => Request.GET(`${ENDPOINT}/draft/list`);

  static createDraft = data => Request.POST(`${ENDPOINT}/draft`, data);

  static updateDraft = data => Request.PUT(`${ENDPOINT}/draft`, data);

  static submitDraft = data => Request.POST(`${ENDPOINT}/submit`, data);

  static pickupDraft = draftId => Request.POST(`${ENDPOINT}/${draftId}/pickup`);

  static changeInventory = data => Request.PUT(`${ENDPOINT}`, data);

  static getItem = id => Request.GET(`${ENDPOINT}/${id}`);

  static create = invenroryData => Request.POST(ENDPOINT, invenroryData);

  static createBlind = invenroryData =>
    Request.POST('/supply/blind-stock-take-record', invenroryData);

  static delete = id => Request.DELETE(`${ENDPOINT}/${id}`);
}

export default InventoryAPI;
