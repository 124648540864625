import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 600px;
  ${'' /* min-height: 600px; */}

  @media screen and (max-width: 767px) {
    min-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

export const CellSpan = styled.span`
  position: absolute;
  left: 14px;
`;

export const DragAndDropWrapper = styled.span`
  //position: absolute;
  //left: 14px;
`;

export const TableWrapper = styled.div`
  position: relative;
  user-select: none;
  overflow-x: auto;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  border: none;

  ${({ useScroll }) =>
    useScroll &&
    `
     // overflow-x: auto;
     white-space: nowrap;

     @media all and (max-width: 990px) {
     }
  `}

  & .clickableRow .thin {
    //width: 100px;
  }

  .ui.table {
    border: 0;
    border-radius: 0.28571429rem 0.28571429rem 0 0;
  }

  .ui.table thead th {
    border-left: none;
    color: rgba(0, 0, 0, 0.54);
    vertical-align: inherit;
    border-bottom: 0;
    background: #f5f5f5;
    font-family: Roboto, san-serif;
    font-size: 14px;
    padding: 9px 20px 9px 16px;
    font-weight: bold;
    font-style: normal;
    line-height: 1.6;
    color: rgb(0, 0, 0);
  }

  .ui.table tbody tr {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;

    &:nth-child(even) {
      background: #f5f5f5;
    }

    &:nth-child(odd) {
      background: #eff0f1;
    }

    &:hover {
      background: #fcfcfc !important;
    }

    & > td {
      padding: 9px 20px 9px 16px;
      font-size: 0.975rem;
      text-align: left;
      font-weight: 400;
      line-height: 1.43;
      letter-spacing: 0.01071em;
      vertical-align: ${({ tdToTop }) => (tdToTop ? `top` : `inherit`)}
      border-top: 1px solid rgba(224, 224, 224, 1);

      &:first-child {
        max-width: 400px;
        padding-left: 16px;
      }
    }
  }
  ${
    '' /* .ui.checkbox input:checked ~ label:after {
    background-color:  var(--color-primary);
    border-radius: 2px;
    color: white;
  } */
  }

  .ui.celled.table tr td,
  .ui.celled.table tr th {
    border-left: 0;
    white-space: nowrap;
  }
`;

export const TablePagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
`;

export const DraggableHandlerImage = styled.img`
  display: inline-block;
  margin: -25px 0px -25px -17px;
  padding: 19px;
`;

export const PlaceholderWrapper = styled.span`
  display: block;
  text-align: center;
`;

export const PopupWrapper = styled.div`
  & .info {
    padding-left: 8px;
  }
`;
