import SupplyRecordAPI from 'api/supply-record';
import IngredientAPI from 'api/ingredient';
import { action, observable, runInAction } from 'mobx/lib/mobx';
import { getLocalizedString } from 'i18n/utils';
import moment from 'moment';
import StorageAPI from 'api/storage';
import { getUTCOffset } from 'utils/time';

const FROM_STORAGE = 'fromStorageName';
const TO_STORAGE = 'toStorageName';
const COMMENT = 'comment';
const BUSINESS_TIMESTAMP = 'businessTimestamp';
const STORAGE_RECORD_TYPE = 'storageRecordType';
const SUPPLIER = 'supplierName';
const TOTAL_AMOUNT = 'totalAmount';
const ORDER_NUMBER = 'orderNumber';

export const ACCOUNTS_TABLE_FORMAT = [
  {
    name: '№',
    property: ORDER_NUMBER,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.storage-record-type'),
    property: STORAGE_RECORD_TYPE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.business-timestamp'),
    property: BUSINESS_TIMESTAMP,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.from-storage'),
    property: FROM_STORAGE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.to-storage'),
    property: TO_STORAGE,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.total'),
    property: TOTAL_AMOUNT,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.supplier'),
    property: SUPPLIER,
    variant: ['left'],
  },
  {
    name: getLocalizedString('supply-record.data.comment'),
    property: COMMENT,
    variant: ['left'],
  },
];

class StorageStore {
  @observable tableFormat = ACCOUNTS_TABLE_FORMAT;
  @observable data = [];
  @observable totalAmount = '';
  @observable loading = true;
  @observable paginateOptions = {};
  @observable paginateData = { page: 0, size: 20 };
  @observable allFilters = {
    start: null,
    end: null,
    ingredientId: { value: 'all', label: getLocalizedString('filters.all') },
    types: [],
    storageId: 'all',
    supplierId: { value: 'all', label: getLocalizedString('filters.all') },
  };

  @action
  changeAllFilters = value => {
    runInAction(() => {
      this.allFilters = { ...value };
    });
    this.changePaginateData({ ...this.paginateData, page: 0 });
  };

  @action
  changePaginateData = value => {
    runInAction(() => {
      this.paginateData = { ...value };
    });
  };

  @action
  loadIngredientOptions = async (search, prevOptions, page = 0) => {
    let filteredOptions = [];
    let hasMore = false;
    if (!search) {
      const { items, last } = await IngredientAPI.list({ page, size: 20 });
      filteredOptions = items;
      hasMore = !last;
    } else {
      const { items } = await IngredientAPI.list({ page: 0, size: 20, name: search });
      filteredOptions = [...items];
    }
    const newFilteredOptions = filteredOptions.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const slicedOptions =
      page === 0
        ? [{ value: 'all', label: getLocalizedString('filters.all') }, ...newFilteredOptions]
        : newFilteredOptions;
    return {
      options: slicedOptions,
      hasMore,
    };
  };

  @action
  loadStoragesOptions = async () => {
    const { items } = await StorageAPI.allList();
    runInAction(() => {
      this.storagesOptions = [
        { value: 'all', text: getLocalizedString('filters.all') },
        ...items.map(item => ({
          value: item.id,
          text: item.name,
          key: item.id,
        })),
      ];
    });
  };

  @action
  fetchData = async () => {
    const { ingredientId, types, storageId, supplierId } = this.allFilters;
    const startDate = moment(this.allFilters.start)
      .startOf('day')
      .valueOf();
    const endDate = moment(this.allFilters.end)
      .endOf('day')
      .valueOf();
    const newAllFilters = {
      ...this.allFilters,
      ingredientId: ingredientId.value === 'all' ? '' : ingredientId.value,
      supplierId: !supplierId || supplierId.value === 'all' ? '' : supplierId.value,
      storageId: storageId === 'all' ? '' : storageId,
      types: types,
      start: this.allFilters.start ? startDate + getUTCOffset(startDate) : '',
      end: this.allFilters.end ? endDate + getUTCOffset(endDate) : '',
    };
    const { page, size } = this.paginateData;
    const { items, last, totalPages, totalElements, totalAmount } = await SupplyRecordAPI.list(
      page,
      size,
      newAllFilters,
    );

    const newItems = items.map(item => ({
      ...item,
      businessTimestamp: moment(item.businessTimestamp).format('HH:mm DD MMM YYYY'),
    }));

    runInAction(() => {
      this.totalAmount = totalAmount;
      this.data = newItems;
      this.paginateOptions = {
        last,
        totalElements,
        totalPages,
      };
      this.loading = false;
    });

    if (newItems.length === 0 && this.paginateData.page > 0) {
      this.changePaginateData({ ...this.paginateData, page: this.paginateData.page - 1 });
    }
  };

  @action
  deleteSupplyRecord = async id => {
    try {
      await SupplyRecordAPI.delete(id);
    } catch (error) {
      throw new Error(error.message);
    }
  };
}

export default StorageStore;
