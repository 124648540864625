import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router';
import { routes } from 'routes';
import cn from 'classnames';
import get from 'lodash/get';
import { INVENTORY_ACCESS_LEVEL } from 'utils/constants';
import dictionaryIcon from './images/dictionary.svg';
import storeIcon from './images/store.svg';
import historyIcon from './images/history.svg';
import processIcon from './images/process.svg';

import styles from './styles.scss';

const Sidebar = inject(
  'MobileMenu',
  'User',
)(
  observer(({ MobileMenu, User }) => {
    const { pathname } = useLocation();

    const canManageSupply = get(User, 'info.inventoryAccessLevel') === INVENTORY_ACCESS_LEVEL.OWNER;
    return (
      <div className={cn(styles.sidebar, { [styles.opened]: MobileMenu.visible })}>
        {canManageSupply && (
          <Link
            onClick={MobileMenu.toggle}
            className={cn(styles.link, {
              [styles.activeLink]: [routes.analytics].some(p =>
                matchPath(pathname, { path: p, exact: true }),
              ),
            })}
            to={routes.analytics}
          >
            <img src={historyIcon} className={styles.icon} alt="historyIcon" />
            <FormattedMessage id="menu.analytics" defaultMessage="Analytics" />
          </Link>
        )}
        <Link
          onClick={MobileMenu.toggle}
          className={cn(styles.link, {
            [styles.activeLink]: ['/', routes.supplyRecord, `${routes.supplyRecord}/:id`].some(p =>
              matchPath(pathname, { path: p, exact: true }),
            ),
          })}
          to={routes.supplyRecord}
        >
          <img src={processIcon} className={styles.iconProcess} alt="storeIcon" />
          <FormattedMessage id="menu.storage" defaultMessage="Storage" />
        </Link>
        <Link
          onClick={MobileMenu.toggle}
          className={cn(styles.link, {
            [styles.activeLink]: [
              // routes.stock,
              // `${routes.stock}/:id`,
              routes.storage,
              `${routes.storage}/:id`,
              routes.inventory,
              `${routes.inventory}/:id`,
            ].some(p => matchPath(pathname, { path: p, exact: true })),
          })}
          to={routes.storage}
        >
          <img src={storeIcon} className={styles.icon} alt="storeIcon" />
          <FormattedMessage id="menu.stock" defaultMessage="Stock" />
        </Link>
        <Link
          onClick={MobileMenu.toggle}
          className={cn(styles.link, {
            [styles.activeLink]: [
              routes.ingredient,
              `${routes.ingredient}/:id`,
              routes.ingredientCategory,
              `${routes.ingredientCategory}/:id`,
              routes.storageDictionary,
              `${routes.storageDictionary}/:id`,
              routes.supplier,
              `${routes.supplier}/:id`,
              routes.packing,
              `${routes.packing}/:id`,
              routes.account,
              `${routes.account}/:id`,
            ].some(p => matchPath(pathname, { path: p, exact: true })),
          })}
          to={routes.ingredient}
        >
          <img src={dictionaryIcon} className={styles.icon} alt="dictionaryIcon" />
          <FormattedMessage id="menu.ingredient" defaultMessage="Ingredient" />
        </Link>
      </div>
    );
  }),
);

export default Sidebar;
