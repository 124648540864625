import React from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Message, Transition, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ErrorBoundary from 'components/common/error-boundary';

import styles from './styles.scss';

const Notification = inject('Notification')(
  observer(({ Notification }) => {
    const { text, isError } = Notification.message;

    const hideActiveNotification = () => {
      Notification.hide();
    };

    const isSuccessMessage = text === 'Success';
    return (
      <ErrorBoundary>
        {Notification.visible && (
          <div className={styles.container} onClick={hideActiveNotification}>
            <Transition visible={Notification.visible} animation="fade down" duration={400}>
              <Message positive={!isError} negative={isError}>
                <div>
                  <Icon name={!isError ? 'check' : 'close'} />
                </div>
                <div>
                  {isSuccessMessage ? (
                    <FormattedMessage id="notifications.success" defaultMessage="Success" />
                  ) : (
                    text
                  )}
                </div>
              </Message>
            </Transition>
          </div>
        )}
      </ErrorBoundary>
    );
  }),
);

Notification.wrappedComponent.propTypes = {
  Notification: MobXPropTypes.observableObject.isRequired,
};

export default Notification;
