import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import Spinner from 'components/common/Spinner';
import cn from 'classnames';
import styles from './styles.scss';

const AddButton = ({ text, onClick, disabled, loading, className }) => (
  <div className={cn(styles.buttonWrapper, className)}>
    <div className={styles.shadowBox}>
      <Button primary onClick={onClick} disabled={disabled}>
        {text}
      </Button>
    </div>
    <Spinner active={loading} size="mini" />
  </div>
);

AddButton.defaultProps = {
  disabled: false,
  loading: false,
};

AddButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AddButton;
