import { action, computed, observable, runInAction } from 'mobx';
import validate from 'validate.js';
import moment from 'moment';
import { getStringInLocale } from 'i18n';

import AuthAPI from 'api/auth';
import MerchantAPI from 'api/merchant';

import UserStore from 'stores/User';

import history, { routes } from 'routes';
import { millisecondsToTime, convertTimeToMilliseconds } from 'utils/format-date';

import { CURRENCY_CODE_TO_SIGN, IMAGE_PLACEHOLDER } from 'utils/constants';
import {
  VALIDATION_SCHEMA,
  EMPTY_MERCHANT,
  CLOSED_SCHEDULE,
  DAYS_OF_THE_WEEK,
  RULER_SCALE_VALUES,
} from 'stores/merchants/constants';

class MerchantEditor {
  constructor(MerchantsGroup) {
    this.user = new UserStore();
    this.merchantsGroup = MerchantsGroup;
    this.onSave = this.merchantsGroup.createMerchant;

    this.onSaveMerchant = this.merchantsGroup.createMerchant;
  }

  @observable merchant = { ...EMPTY_MERCHANT };

  @observable userInfo = {};
  @observable roundingModes = [];
  @observable clearedSelfPayment = {};

  @computed get currency() {
    const { currency } = this.merchant;

    return CURRENCY_CODE_TO_SIGN[currency];
  }

  @computed get weekdays() {
    return DAYS_OF_THE_WEEK;
  }

  @computed get ruler() {
    return RULER_SCALE_VALUES;
  }

  @computed get isValid() {
    const { name } = this.merchant;
    return !!name;
  }

  @computed get defaultCountryCode() {
    return this.merchant.countryCode.toLocaleLowerCase();
  }

  @observable validationErrors = {};

  @action
  validateMerchant = () => {
    this.validationErrors = validate(this.merchant, VALIDATION_SCHEMA) || {};
  };

  @action
  clearMerchant = () => {
    this.merchant = { ...EMPTY_MERCHANT };
  };

  @action
  fetchRoundingMode = async () => {
    const { items = [] } = await MerchantAPI.fetchRoundingMode();
    const modes = items.map(({ name }) => ({
      text: getStringInLocale(`merchant.rounding.${name}`, name),
      value: name,
    }));

    runInAction(() => {
      this.roundingModes = modes;
    });
  };

  @action
  create = () => {
    this.merchant = { ...EMPTY_MERCHANT };
    this.onSave = this.merchantsGroup.createMerchant;

    history.push(routes.adminMerchantNew);
  };

  @action
  updateToCreate = () => {
    runInAction(() => {
      this.onSave = this.merchantsGroup.createMerchant;
    });
  };

  structureBusinessDayOffsetMap = data => {
    const times = {};
    const date = new Date();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        times[key] = {
          workingDay: true,
          end: moment(date).format('hh:mm'),
          start: data[key] > 0 ? millisecondsToTime(data[key]) : '00:00',
        };
      }
    }
    return {
      ...CLOSED_SCHEDULE,
      ...times,
    };
  };

  @action
  edit = async id => {
    const merchant = await MerchantAPI.fetchMerchant(id);
    const times = merchant.merchantConfiguration.businessDayOffsetMap;

    runInAction(() => {
      this.merchant = {
        ...merchant,
        merchantConfiguration: {
          ...merchant.merchantConfiguration,
          businessDayOffsetMap: { daysOfWeek: this.structureBusinessDayOffsetMap(times) },
        },
      };

      this.onSave = this.merchantsGroup.updateMerchant;
    });
  };

  @action
  getCurrentMerchant = async merchantId => {
    const fetchMerchant = merchantId ? MerchantAPI.fetchMerchant : MerchantAPI.fetchCurrentMerchant;

    return await fetchMerchant(merchantId);
  };

  @action
  editCurrent = async merchantId => {
    const merchant = await this.getCurrentMerchant(merchantId);

    const times = merchant && merchant.merchantConfiguration.businessDayOffsetMap;

    runInAction(() => {
      this.merchant = {
        ...merchant,
        merchantConfiguration: {
          ...merchant.merchantConfiguration,
          businessDayOffsetMap: { daysOfWeek: this.structureBusinessDayOffsetMap(times) },
        },
      };

      this.onSave = this.merchantsGroup.updateMerchant;
    });
  };

  @action
  updateInfo = (key, value) => {
    this.merchant[key] = value;
  };

  @action
  updateConfiguration = (key, value) => {
    this.merchant.merchantConfiguration[key] = value;
  };

  @observable imageFile = '';

  @action
  updateImage = ({ image }) => {
    this.imageFile = image;
  };

  @action
  removeImage = () => {
    this.merchant.logo = IMAGE_PLACEHOLDER;
  };

  @observable availablePaymentMethods = [];

  @action
  fetchAvailablePaymentMethods = async () => {
    const { items } = await MerchantAPI.fetchAvailablePaymentMethods();

    runInAction(() => {
      this.availablePaymentMethods = items;
    });
  };

  @action
  togglePaymentMethod = paymentMethod => {
    const { paymentMethods } = this.merchant;
    const index = paymentMethods.findIndex(method => paymentMethod.id === method.id);

    if (index > -1) {
      this.merchant.paymentMethods = [
        ...this.merchant.paymentMethods.slice(0, index),
        ...this.merchant.paymentMethods.slice(index + 1),
      ];
    } else {
      this.merchant.paymentMethods = [...this.merchant.paymentMethods, paymentMethod];
    }
  };

  getBusinessDays = timesData => {
    const times = {};
    for (const key in timesData) {
      if (timesData.hasOwnProperty(key) && timesData[key].workingDay) {
        const time = timesData[key].start;
        times[key] = convertTimeToMilliseconds(time);
      }
    }
    return times;
  };

  @action
  getDefaultPaymentMethod = ({ paymentMethods }) => {
    const CASH_PAYMENT_METHOD = 'Cash';

    if (!paymentMethods.length) {
      const cashMethod = this.availablePaymentMethods.find(
        item => item.name === CASH_PAYMENT_METHOD,
      );

      return [cashMethod];
    }

    return paymentMethods;
  };

  @action
  getStructuredMerchant = () => {
    return {
      ...this.merchant,
      paymentMethods: this.getDefaultPaymentMethod(this.merchant),
      mobile: this.merchant.mobile.replace(/\+|\(|\)|-/gi, ''),
      merchantConfiguration: {
        ...this.merchant.merchantConfiguration,
        businessDayOffsetMap: this.getBusinessDays(
          this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek,
        ),
      },
    };
  };

  @action
  save = async bool => {
    const merchant = this.getStructuredMerchant();

    await this.onSave(merchant, this.imageFile);
    const userInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.userInfo = userInfo;
    });

    bool ? history.push(routes.supplyRecord) : history.push(routes.supplyRecord);
  };

  @action
  saveOrderManagement = async bool => {
    const merchant = this.getStructuredMerchant();

    await this.onSave(merchant, this.imageFile);
    const userInfo = await AuthAPI.getUserInfo();

    runInAction(() => {
      this.userInfo = userInfo;
    });

    history.push(routes.backOfHouse);
  };

  @action
  toggleBusinessDay = day => {
    const currentState = this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[day]
      .workingDay;

    runInAction(() => {
      this.merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[
        day
      ].workingDay = !currentState;
      this.merchant = {
        ...this.merchant,
      };
    });
  };

  @action
  changeBusinessDaySchedule = (day, prop, time) => {
    const merchant = { ...this.merchant };
    merchant.merchantConfiguration.businessDayOffsetMap.daysOfWeek[day][prop] = time;

    runInAction(() => {
      this.merchant = merchant;
    });
  };

  @action
  clearValidation = () => {
    this.validationErrors = {};
  };
}

export default MerchantEditor;
