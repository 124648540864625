import React from 'react';
import { hot } from 'react-hot-loader/root'; // eslint-disable-line import/no-extraneous-dependencies
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { routes } from 'routes';
import IngredientsPage from 'pages/ingredients';
import StoragePage from 'pages/storage';
import StockPage from 'pages/stock';
import AnalyticsPage from 'pages/analytics';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import PackingEditor from 'components/packing/editor';
import IngredientEditor from '../components/ingredient/editor';
import IngredientCategoryEditor from '../components/ingredient-category/editor';
import AccountEditor from '../components/account/editor';
import SupplierEditor from '../components/supplier/editor';
import StorageDictionaryEditor from '../components/storage-dictionary/editor';
import SupplyRecordEditor from '../components/supply-record/editor';
import InventoryEditor from '../components/inventory/editor';

import styles from './styles.scss';

const DashboardScene = inject('User')(
  observer(({ User }) => {
    return User.isAuthorized ? (
      <div className={styles.general}>
        <Header />
        <div className={styles.wrapper}>
          <Sidebar />
          <div className={styles.content}>
            <Switch>
              <Route
                exact
                path={`${routes.ingredient}/new`}
                render={props => <IngredientEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.ingredient}/:id`}
                render={props => <IngredientEditor {...props} />}
              />

              <Route path={routes.ingredient} component={IngredientsPage} />

              <Route
                exact
                path={`${routes.ingredientCategory}/new`}
                render={props => <IngredientCategoryEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.ingredientCategory}/:id`}
                render={props => <IngredientCategoryEditor {...props} />}
              />

              <Route path={routes.ingredientCategory} component={IngredientsPage} />

              <Route
                exact
                path={`${routes.storageDictionary}/new`}
                render={props => <StorageDictionaryEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.storageDictionary}/:id`}
                render={props => <StorageDictionaryEditor {...props} />}
              />

              <Route path={routes.storageDictionary} component={IngredientsPage} />

              <Route
                exact
                path={`${routes.supplier}/new`}
                render={props => <SupplierEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.supplier}/:id`}
                render={props => <SupplierEditor {...props} />}
              />

              <Route path={routes.supplier} component={IngredientsPage} />

              <Route
                exact
                path={`${routes.packing}/new`}
                render={props => <PackingEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.packing}/:id`}
                render={props => <PackingEditor {...props} />}
              />

              <Route path={routes.packing} component={IngredientsPage} />

              <Route
                exact
                path={`${routes.account}/new`}
                render={props => <AccountEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.account}/:id`}
                render={props => <AccountEditor {...props} />}
              />

              <Route path={routes.account} component={IngredientsPage} />

              <Route path={routes.storage} component={StockPage} />

              <Route path={routes.analytics} component={AnalyticsPage} />

              <Route
                exact
                path={`${routes.supplyRecord}/new`}
                render={props => <SupplyRecordEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.supplyRecord}/:id`}
                render={props => <SupplyRecordEditor {...props} />}
              />

              <Route
                exact
                path={`${routes.inventory}/new`}
                render={props => <InventoryEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.inventory}/new/:id`}
                render={props => <InventoryEditor {...props} />}
              />
              <Route
                exact
                path={`${routes.inventory}/:id`}
                render={props => <InventoryEditor {...props} />}
              />

              <Route path={routes.inventory} component={StockPage} />
              <Route path={routes.supplyRecord} component={StoragePage} />

              <Route path={routes.start} component={StoragePage} />
            </Switch>
          </div>
        </div>
      </div>
    ) : (
      <Redirect to={routes.login} />
    );
  }),
);

export default hot(DashboardScene);
