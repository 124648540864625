import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Input, Checkbox } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import FormSection from 'components/common/form-section';
import styles from './styles.scss';

const General = inject('StorageDictionaryEditor')(
  observer(({ StorageDictionaryEditor }) => {
    const { storage, changeStorage } = StorageDictionaryEditor;

    const { name, allowNegativeStock } = storage;

    const change = (_event, { name, value }) => {
      changeStorage(name, value);
    };

    return (
      <FormSection>
        <Form.Group widths="2">
          <Form.Field required>
            <label>
              <FormattedMessage id="storage.data.name" defaultMessage="Name" />
            </label>
            <FormattedMessage id="storage.data.name.placeholder" defaultMessage="Enter the name">
              {placeholder => (
                <Input
                  fluid
                  type="text"
                  name="name"
                  placeholder={placeholder}
                  maxLength={35}
                  value={name}
                  onChange={change}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                toggle
                label=""
                checked={allowNegativeStock}
                onChange={() => changeStorage('allowNegativeStock', !allowNegativeStock)}
              />
              <p className={styles.text}>
                <p className={styles.notificationTitle}>
                  <FormattedMessage
                    id="storage.allowNegativeStock"
                    defaultMessage="Allow negative stock"
                  />
                </p>
              </p>
            </div>
          </Form.Field>
        </Form.Group>
      </FormSection>
    );
  }),
);

export default General;
